//====================================================================================================
.section-module {
  background-color: #fff;
  padding: 100px 0 10px;
  position: relative;
  z-index: 9;
  @media (max-width: $md4+px) {
    padding-top: 28px;
  }
  &__title {
    text-align: center;
    line-height: 36px;
    color: #273646;
    line-height: 62px !important;
  }
  &__subtitle {
    text-align: center;
    color: #4e6677;
  }
  &__text {
    max-width: 845px;
    line-height: 18;
    margin: 0 auto;
    padding-top: 20px;
    color: #394a58;
    text-align: center;
    @media (max-width: $md4+px) {
      padding-top: 3px;
    }
  }
  &__link {
    font-family: 'RobotoCondensed-Regular';
    font-size: 24px;
    color: #3067bb;
    display: block;
    margin: 20px auto 0;
    text-align: center;
    position: relative;
    transition: all 0.3s ease 0s;
    @media (max-width: $md1+px) {
      font-size: 20px;
    }
    @media (max-width: $md2+px) {
      font-size: 18px;
    }
    @media (max-width: $md3+px) {
      font-size: 16px;
    }
    @media (max-width: $md4+px) {
      margin-top: 5px;
      letter-spacing: -0.5px;
    }
    &::after {
      content: '';
      width: 8%;
      height: 1px;
      background-color: #3067bb;
      display: block;
      position: absolute;
      bottom: 3px;
      left: 50%;
      transform: translateX(-50%);
      transition: all 0.3s ease 0s;
      @media (max-width: $md1+px) {
        width: 9%;
      }
      @media (max-width: $md2+px) {
        width: 10%;
      }
      @media (max-width: $md3+px) {
        width: 11%;
      }
    }
    &:hover {
      color: #dc240a;
    }
    &:hover:after {
      width: 0;
      background-color: #fff;
    }
  }
  &__btn {
    margin: 40px auto 70px;
    @media (max-width: $md4+px) {
      margin-top: 60px;
      margin-bottom: 30px;
    }
  }
  &__tabs {
    margin: 70px auto 0;
    @media (max-width: $md4+px) {
      margin-top: 15px;
    }
  }
}
//====================================================================================================
.section-possibilities {
  background: #f1f2f5;
  position: relative;
  z-index: 8;
  padding: 70px 0;
  @media (max-width: $md4+px) {
    padding: 45px 0 65px;
  }
  &__title {
    color: #196090;
    text-align: center;
    margin-bottom: 18px;
  }
  &__row {
    display: flex;
    flex-wrap: nowrap;
    justify-content: center;
    margin-left: 90px;
    @media (max-width: 1378px) {
      margin-left: 0;
    }
    @media (max-width: $md3+px) {
      flex-wrap: wrap;
    }
  }
  // .section-possibilities__item
  &__item {
    max-width: 430px;
    width: 100%;
    margin: 35px 54px;
    @media (max-width: $md2+px) {
      max-width: 100%;
      margin-left: 20px;
      margin-right: 20px;
    }
    @media (max-width: $md3+px) {
      margin-left: 0;
      margin-right: 0;
    }
    @media (max-width: $md4+px) {
      margin-top: 25px;
    }
  }
  &__btn {
    margin: 40px auto 0px;
  }
}
.crm-item {
  // .crm-item__icon
  &__icon {
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    width: 64px;
    height: 64px;
    border-radius: 11px;
    color: #fff;
    &::before {
      content: '';
      display: inline-block;
      background-repeat: no-repeat;
      background-position: center center;
      background-size: contain;
      width: 64px;
      height: 64px;
      @media (max-width: $md4+px) {
        width: 48px;
        height: 48px;
      }
    }
    @media (max-width: $md3+px) {
      margin: 0 auto;
    }
    @media (max-width: $md4+px) {
      width: 48px;
      height: 48px;
    }
    // i {
    //   border: 2px solid #fff;
    //   padding: 11px 12px;
    //   border-radius: 12px;
    //   font-size: 30px;
    // }
  }

  // .crm-item__title
  &__title {
    margin: 25px 0 8px;
    font-family: 'RobotoCondensed-Bold';
    font-size: 28px;
    line-height: 100%;
    color: #394058;
    @media (max-width: $md1+px) {
      font-size: 26px;
    }
    @media (max-width: $md2+px) {
      font-size: 24px;
    }
    @media (max-width: $md3+px) {
      font-size: 22px;
      text-align: center;
    }
    @media (max-width: $md4+px) {
      margin-bottom: 10px;
    }
    color: #2d3a4c;
  }
  // .crm-item__text
  &__text {
    color: #4e6677;
    line-height: 28px !important;
    margin-bottom: 5px;
    @media (max-width: $md3+px) {
      text-align: center;
      line-height: 21px !important;
    }
    // @media (max-width: $md4+px) {
    //   line-height: 21px !important;
    // }
  }
  &__link-box {
    @media (max-width: $md3+px) {
      margin: 0 auto;
      text-align: center;
    }
  }
  // .crm-item__link
  &__link {
    font-size: 18px;
    line-height: 25px;
    font-weight: 700;
    color: #3067bb;
    position: relative;
    transition: all 0.3s ease 0ms;
    &:after {
      content: '';
      width: 100%;
      background-color: #3067bb;
      height: 1px;
      display: block;
      position: absolute;
      bottom: 0;
      left: 50%;
      transform: translateX(-50%);
      transition: all 0.3s ease 0ms;
    }
    &:hover {
      color: #dc240a;
      &:after {
        width: 0;
        background-color: #fff;
      }
    }
    @media (max-width: $md3+px) {
      text-align: center;
    }
    @media (max-width: $md4+px) {
      font-size: 15px;
    }
  }
}

.management-icon {
  &::before {
    background-image: url('../img/icons/quotes.svg');
  }
}
.analitycs-icon {
  &::before {
    background-image: url('../img/icons/analytics.svg');
  }
}
.services-icon {
  &::before {
    background-image: url('../img/icons/tarifs.svg');
  }
}
.reports-icon {
  &::before {
    background-image: url('../img/icons/docs.svg');
  }
}
.funds-icon {
  &::before {
    background-image: url('../img/icons/numbers.svg');
  }
}
.settings-icon {
  &::before {
    background-image: url('../img/icons/setting.svg');
  }
}


/* custom icon */

.free-icon {
  &::before {
    background-image: url("../img/icons/free.svg");
  }
 
}

.prices-icon {
  &::before{
    background-image: url("../img/icons/prices.svg");
  }
}

.friend_interface-icon {
  &::before {
    background-image: url("../img/icons/friend_interface.svg");
  }
}

.speed-icon {
  &::before {
    background-image: url("../img/icons/speed.svg");
  }
}

.credit_card-icon {
  &::before {
    background-image: url("../img/icons/credit_card.svg");
  }
}

.qr_icon-icon {
  &::before {
    background-image: url("../img/icons/qr_icon.svg");
  }
}

.cash-icon {
  &::before {
    background-image: url("../img/icons/cash.svg");
  }
}

.print_check-icon {
  &::before {
    background-image: url("../img/icons/print_check.svg");
  }
}

.adaptive-icon {
  &::before {
    background-image: url("../img/icons/adaptive.svg");
  }
}

.logic-icon {
  &::before {
    background-image: url("../img/icons/logic.svg");
  }
}

.domain-icon {
  &::before {
    background-image: url("../img/icons/domain.svg");
  }
}

.analytic-icon {
  &::before {
    background-image: url("../img/icons/analytic.svg");
  }
}

.seo-icon {
  &::before{
    background-image: url("../img/icons/seo.svg");
  }
}

.crm-icon {
  &::before{
    background-image: url("../img/icons/crm.svg");
  }
}

.host-icon {
  &::before{
    background-image: url("../img/icons/host.svg");
  }
}

.social-icon {
  &::before {
    background-image: url("../img/icons/social.svg");
  }
}

/* end custom icon  */





//====================================================================================================
.section-price {
  background-image: url('../img/new-price-bg.jpg');
  background-repeat: no-repeat;
  background-size: auto;
  padding: 70px 0 40px;
  overflow: hidden;
  position: relative;
  z-index: 0;
  // &::before {
  //   // background-color: #000;
  //   background-image: url('../img/new-price-bg.jpg');
  //   background-size: 2560px;
  //   background-repeat: no-repeat;
  //   background-position: bottom center;
  //   content: '';
  //   display: block;
  //   height: 100%;
  //   position: fixed;
  //   width: 100%;
  //   bottom: 0;
  //   transform: translateZ(999px);
  //   left: 0;
  //   z-index: 0;
  //   opacity: 1;
  // }
  // &::after {
  //   background-color: $mint;
  //   content: '';
  //   display: block;
  //   height: 100%;
  //   position: fixed;
  //   width: 2560px;
  //   top: 50%;
  //   transform: translateY(-50%) translateX(-50%) translateZ(0);
  //   left: 50%;
  //   z-index: 1;
  //   opacity: 0.9;
  // }
  // @media (max-width: 575px) {
  //   background: #126f4f !important;
  //   min-height: 670px;
  // }
  @media (max-width: $md4+px) {
    padding: 45px 0 20px;
  }
  &__content {
    position: relative;
    z-index: 3;
  }
  &__title {
    text-align: center;
    color: #fff;
    line-height: 36px !important;
  }
  &__subtitle {
    margin-top: 10px;
    text-align: center;
    color: #fff;
    line-height: 36px !important;
    @media (max-width: $md4+px) {
      margin-top: 4px;
      line-height: 20px !important;
    }
  }
  &__tab {
    margin: 48px auto 0;
    @media (max-width: $md4+px) {
      margin: 38px auto 0;
    }
  }
  &__link {
    text-align: center;
    display: block;
    margin: 30px auto 0;
    font-size: 18px;
    line-height: 25px;
    font-weight: 700;
    color: #f4de4b;
    position: relative;
    transition: all 0.3s ease 0ms;
    &:after {
      content: '';
      width: 7%;
      background-color: #f4de4b;
      height: 1px;
      display: block;
      position: absolute;
      bottom: 0;
      left: 50%;
      transform: translateX(-50%);
      transition: all 0.3s ease 0ms;
      @media (max-width: 1378px) {
        width: 9.5%;
      }
      @media (max-width: $md2+px) {
        width: 12.5%;
      }
      @media (max-width: $md3+px) {
        width: 14.5%;
      }
      @media (max-width: 575px) {
        width: 18.5%;
      }
      @media (max-width: $md4+px) {
        width: 25.5%;
      }
    }
    &:hover {
      color: #fff;
      &:after {
        width: 0;
        background-color: #fff;
      }
    }
    @media (max-width: $md4+px) {
      font-size: 15px;
    }
  }
  .ruble {
    display: none;
    @media (max-width: $md4+px) {
      display: inline;
    }
    &__text {
      display: inline;
      @media (max-width: $md4+px) {
        display: none;
      }
    }
  }
}

.price-tab {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  max-width: 758px;
  width: 100%;
  // @media (max-width: $md3+px) {
  //   flex-direction: column;
  // }
  &__input:focus + .price-tab__label {
    z-index: 1;
  }
  &__input:checked + .price-tab__label {
    background: #fff;
    color: #199967;
    // @media (max-width: $md3+px) {
    //   border-bottom: 1px solid #199967;
    // }
  }
  &__label {
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 15px;
    text-transform: uppercase;
    color: #ffffff;
    border: 1px solid #fff;
    border-bottom: none;
    max-width: 211px;
    width: 100%;
    padding: 5px 10px 2px;
    cursor: pointer;
    letter-spacing: -0.7px;
    transition: background 0.1s, color 0.1s;
    // &:first-of-type {
    //   padding: 5px 32px 2px;
    //   @media (max-width: $md3+px) {
    //     padding: 6px 10px 3px;
    //   }
    // }
    // &:last-of-type {
    //   @media (max-width: 600px) {
    //     padding-left: 34px;
    //     padding-right: 34px;
    //   }
    // }
    // @media (min-width: $md3+px) {
    //   width: auto;
    // }
    @media (max-width: $md3+px) {
      font-size: 14px;
      white-space: pre-wrap;
      text-align: center;
      flex: 0 0 30.2%;
      padding: 5px 5px 2px;
      letter-spacing: 0.2px;
    }
    @media (max-width: 600px) {
      padding: 8px 15px 3px;
      font-size: 13px;
      line-height: 16px;
    }
    @media (max-width: 450px) {
      flex: 0 0 127px;
    }
    &:hover {
      background: #fff;
      color: #199967;
    }
    &:active {
      background: #fff;
      color: #199967;
    }
  }
  &__panel {
    display: none;
    background: #fff;
    color: #199967;
    padding: 76px 60px 18px;
    border-radius: 5px;
    width: 100%;
    order: 99;
    margin-top: -1px;
    // @media (min-width: $md3+px) {
    //   order: 99;
    // }
    @media (max-width: $md3+px) {
      border-top-left-radius: 0;
      border-top-right-radius: 0;
    }
    @media (max-width: $md4+px) {
      padding: 40px 10px 10px;
    }
    p {
      font-size: 36px;
      line-height: 120%;
      font-weight: 700;
      color: #199967;
      text-align: center;
      text-transform: uppercase;
      @media (max-width: $md1+px) {
        font-size: 32px;
      }
      @media (max-width: $md2+px) {
        font-size: 28px;
      }
      @media (max-width: $md3+px) {
        font-size: 24px;
      }
      &:first-child {
        border-bottom: 1px solid #199967;
        padding-bottom: 18px;
        @media (max-width: $md4+px) {
          padding-bottom: 0px;
        }
      }
      &:last-child {
        padding-top: 24px;
        padding-bottom: 35px;
        @media (max-width: $md1+px) {
          font-size: 32px;
        }
        @media (max-width: $md2+px) {
          font-size: 28px;
        }
        @media (max-width: $md4+px) {
          font-size: 20px;
          padding-top: 15px;
        }
      }
      span {
        font-size: 48px;
        line-height: 72px;
        @media (max-width: $md1+px) {
          font-size: 42px;
        }
        @media (max-width: $md2+px) {
          font-size: 36px;
        }
        @media (max-width: $md3+px) {
          font-size: 30px;
        }
        &:first-child {
          font-size: 100px;
          padding: 0 22px 0 22px;
          @media (max-width: $md1+px) {
            font-size: 85px;
          }
          @media (max-width: $md2+px) {
            font-size: 70px;
          }
          @media (max-width: $md3+px) {
            font-size: 55px;
          }
        }
      }
    }
  }
  &__span {
    font-size: 14px;
    line-height: 28px;
    font-style: italic;
    color: #ffffff;
    display: flex;
    align-items: center;
    margin: 0 10px;
    @media (max-width: 450px) {
      font-size: 0;
    }
    // @media (max-width: $md3+px) {
    //   justify-content: center;
    //   margin: 20px 10px;
    // }
    // @media (max-width: $md3+px) {
    //   display: none;
    // }
  }
}
.price-tab__input:checked + .price-tab__label + .price-tab__panel {
  display: block;
}
//====================================================================================================

//====================================================================================================
.section-rent {
  background-color: #fff;
  padding: 100px 0 50px;
  position: relative;
  z-index: 7;
  @media (max-width: $md4+px) {
    padding: 30px 0 45px;
  }
  &__row {
    display: flex;
    justify-content: space-between;
    @media (max-width: 1378px) {
      align-items: center;
    }
    @media (max-width: $md2+px) {
      flex-direction: column;
    }
  }
  &__column {
    &:first-of-type {
      flex: 0 0 500px;
      padding: 50px 0 0 50px;
      @media (max-width: 1400px) {
        flex: 0 0 450px;
        padding: 0;
      }
      @media (max-width: $md2+px) {
        flex: 0 0 auto;
      }
    }
    &:last-of-type {
      padding-left: 4px;
      @media (max-width: 1400px) {
        padding-left: 0;
      }
    }
  }
  &__title {
    font-family: 'YanoneKaffeesatz-Bold';
    font-size: 72px;
    line-height: 72px;
    text-align: center;
    color: #273646;
    @media (max-width: $md1+px) {
      font-size: 62px;
    }
    @media (max-width: $md2+px) {
      font-size: 52px;
    }
    @media (max-width: $md3+px) {
      font-size: 42px;
    }
    span {
      font-family: 'PTSansNarrow-Bold';
      font-size: 60px;
      @media (max-width: $md1+px) {
        font-size: 52px;
      }
      @media (max-width: $md2+px) {
        font-size: 44px;
      }
      @media (max-width: $md3+px) {
        font-size: 36px;
      }
    }
  }
  &__text {
    padding-top: 12px;
    font-size: 22px;
    line-height: 28px;
    font-weight: 400;
    color: #646c77;
    text-align: center;
    @media (max-width: $md1+px) {
      font-size: 20px;
    }
    @media (max-width: $md2+px) {
      font-size: 18px;
    }
    @media (max-width: $md3+px) {
      font-size: 16px;
    }
    @media (max-width: $md4+px) {
      padding-top: 0;
      line-height: 24px;
    }
  }
  &__link {
    font-size: 18px;
    margin: 13px auto;
    display: block;
    text-align: center;
    line-height: 25px;
    font-weight: 700;
    color: #3067bb;
    position: relative;
    transition: all 0.3s ease 0ms;
    @media (max-width: $md2+px) {
      margin-bottom: 50px;
    }
    @media (max-width: $md4+px) {
      font-size: 15px;
    }
    &:after {
      content: '';
      width: 18.5%;
      background-color: #3067bb;
      height: 1px;
      display: block;
      position: absolute;
      bottom: 0;
      left: 50%;
      transform: translateX(-50%);
      transition: all 0.3s ease 0ms;
      @media (max-width: 1378px) {
        width: 19%;
      }
      @media (max-width: $md2+px) {
        width: 19.5%;
      }
      @media (max-width: $md3+px) {
        width: 20%;
      }
      @media (max-width: 575px) {
        width: 20.5%;
      }
    }
    &:hover {
      color: #dc240a;
      &:after {
        width: 0;
        background-color: #fff;
      }
    }
  }
  &__image {
    max-width: 100%;
    width: 100%;
    object-fit: cover;
  }
}
//====================================================================================================

//====================================================================================================
.section-answers {
  min-height: 730px;
  background-color: #80379b;
  box-shadow: inset 0px 0px 250px 0px rgba(0, 0, 0, 0.84);
  padding: 60px 0 60px;
  color: #fff;
  position: relative;
  overflow: hidden;
  @media (max-width: $md4+px) {
    padding: 55px 0 90px;
  }
  &__parallax {
    position: absolute;
    bottom: 0;
    top: 0;
    left: 0;
    right: 0;
  }
  &__content {
    max-width: 937px;
    width: 100%;
    margin: 0 auto;
    position: relative;
    z-index: 2;
  }
  &__title {
    text-align: center;
  }
  &__subtitle {
    margin-top: -15px;
    font-family: 'RobotoCondensed-Regular';
    font-size: 22px;
    line-height: 134%;
    font-weight: 400;
    text-align: center;
    letter-spacing: -0.3px;
    @media (max-width: $md1+px) {
      font-size: 20px;
      margin-top: -5px;
    }
    @media (max-width: $md2+px) {
      font-size: 16px;
    }
    @media (max-width: $md3+px) {
      font-size: 15px;
      margin-top: 5px;
    }
  }
  &__tab {
    padding-top: 55px;
    padding-left: 20px;
  }
  &__link {
    margin: 60px auto 0;
    max-width: 85px;
    width: 100%;
  }
  &__link-button {
    font-family: 'RobotoCondensed-Regular';
    font-size: 18px;
    color: #fff;
    display: block;
    margin: 20px auto 0;
    text-align: center;
    position: relative;
    transition: all 0.3s ease 0s;
    @media (max-width: $md3+px) {
      font-size: 16px;
    }
    @media (max-width: $md4+px) {
      margin-top: 5px;
      letter-spacing: -0.5px;
    }
    &::after {
      content: '';
      width: 100%;
      height: 1px;
      background-color: #fff;
      display: block;
      position: absolute;
      bottom: 3px;
      left: 50%;
      transform: translateX(-50%);
      transition: all 0.3s ease 0s;
    }
    &:hover {
      color: #f4de4b;
    }
    &:hover:after {
      width: 0;
      background-color: #fff;
    }
  }
  .question-icon {
    position: absolute;
    z-index: 1;
    transition: all 0.5s;
    left: 50%;
    transform: translateX(-50%);
  }
}

// .section-contacts__image.zoomMap {
//   img {
//     transition: all 0.3s ease 0s;
//     // transform: scale(1) translateX(0%);
//     opacity: 1;
//   }
// }

.answers-tab {
  width: 100%;
  &__input:focus + .answers-tab__label {
    z-index: 1;
  }
  &__input:checked + .answers-tab__label {
    color: #fff;
    display: block;
  }
  &__label {
    display: inline-block;
    font-family: 'RobotoCondensed-Regular';
    font-size: 22px;
    line-height: 33px;
    color: #ffffff;
    padding: 5px 0 10px 20px;
    margin-left: -20px;
    cursor: pointer;
    letter-spacing: -0.2px;
    text-decoration: underline dotted;
    text-underline-offset: 5px;
    transition: all 0.3s ease 0s;
    @media (max-width: $md1+px) {
      font-size: 20px;
    }
    @media (max-width: $md2+px) {
      font-size: 18px;
    }
    @media (max-width: $md3+px) {
      font-size: 16px;
    }
    &:hover {
      text-decoration: none;
    }
    &:active {
      color: none;
    }
  }
  &__panel {
    height: 0;
    padding: 0 20px;
    opacity: 0;
    visibility: hidden;
    width: 100%;
    background: #fff;
    color: #80379b;
    border-radius: 10px;
    position: relative;
    transition: all 0.2s ease 0s;
    &::before {
      content: '';
      display: block;
      width: 19px;
      height: 40px;
      background-image: url('../img/icons/bubble.svg');
      background-position: 0 0;
      background-repeat: no-repeat;
      position: absolute;
      top: -10px;
      left: -18px;
    }
    p {
      font-family: 'RobotoCondensed-Regular';
      font-size: 22px;
      line-height: 30px;
      color: #80379b;
      span {
        display: block;
      }
      @media (max-width: $md1+px) {
        font-size: 20px;
      }
      @media (max-width: $md2+px) {
        font-size: 18px;
      }
      @media (max-width: $md3+px) {
        font-size: 16px;
      }
    }
  }
}
.answers-tab__input:checked + .answers-tab__label + .answers-tab__panel {
  height: auto;
  padding: 20px 100px 20px 40px;
  opacity: 1;
  visibility: visible;
  @media (max-width: $md2+px) {
    padding-right: 20px;
    padding-left: 29px;
  }
}
//====================================================================================================

//====================================================================================================
.section-contacts {
  background-color: #f1f2f5;
  padding: 90px 0;
  position: relative;
  z-index: 6;
  &__row {
    display: flex;
    justify-content: center;
    @media (max-width: $md2+px) {
      flex-direction: column-reverse;
    }
  }
  &__column {
    flex: 0 0 39.5%;
    &:first-of-type {
      flex: 0 1 844px;
      max-width: 844px;
      padding-top: 55px;
      position: relative;
      overflow: hidden;
      @media (max-width: $md2+px) {
        flex: 0 0 644px;
      }
      @media (max-width: $md3+px) {
        flex: 0 0 544px;
      }
      @media (max-width: 600px) {
        flex: 0 0 444px;
      }
      @media (max-width: $md4+px) {
        flex: 0 0 285px;
      }
      // &::after {
      //   content: '';
      //   width: 500px;
      //   height: 500px;
      //   background-color: #f1f2f5;
      //   display: block;
      //   border-radius: 50%;
      //   position: absolute;
      //   top: 0;
      //   right: -410px;
      //   filter: blur(50px);
      //   opacity: 0.8;
      //   z-index: 10;
      // }
    }
    &:last-of-type {
      padding-left: 100px;
      @media (max-width: 1440px) {
        padding-left: 40px;
      }
      @media (max-width: $md2+px) {
        padding-left: 0;
      }
    }
  }
  &__image {
    max-width: 844px;
    width: 100%;
    position: relative;
    // &::after {
    //   content: '';
    //   width: 150px;
    //   height: 150px;
    //   background-color: rgba(#fff, 0.5);
    //   display: block;
    //   border-radius: 50%;
    //   position: absolute;
    //   top: 70px;
    //   right: -50px;
    //   filter: blur(20px);
    // }
    img {
      width: 100%;
      object-fit: cover;
      transition: all 0.3s ease 0s;
      // transform: scale(1.3) translateX(30%);
      position: relative;
      z-index: 10;
    }
  }
  &__item-row {
    @media (max-width: $md2+px) {
      display: flex;
      flex-wrap: wrap;
    }
    @media (max-width: 575px) {
      flex-direction: column;
    }
  }
  &__title {
    font-family: 'YanoneKaffeesatz-Bold';
    font-size: 72px;
    color: #273646;
    @media (max-width: $md1+px) {
      font-size: 62px;
    }
    @media (max-width: $md2+px) {
      font-size: 52px;
      text-align: center;
    }
    @media (max-width: $md3+px) {
      font-size: 42px;
    }
  }
  &__subtitle {
    padding-top: 15px;
    padding-bottom: 35px;
    font-family: 'RobotoCondensed-Regular';
    font-size: 20px;
    line-height: 21px;
    color: #4e6677;
    @media (max-width: $md1+px) {
      font-size: 18px;
    }
    @media (max-width: $md2+px) {
      font-size: 17px;
      text-align: center;
    }
    @media (max-width: $md3+px) {
      font-size: 16px;
    }
  }
  &__item {
    @media (max-width: $md2+px) {
      flex: 0 0 50%;
    }
  }
}

.contacts-item {
  padding-top: 20px;
  margin-left: -28px;
  @media (max-width: $md2+px) {
    margin: 0;
  }
  span {
    display: block;
    @media (max-width: $md2+px) {
      display: flex;
      align-items: center;
    }
  }
  &__city {
    font-family: 'RobotoCondensed-Bold';
    font-size: 30px;
    line-height: 110%;
    color: #394a58;
    position: relative;
    padding-left: 28px;
    &::before {
      content: '';
      display: inline-block;
      background-repeat: no-repeat;
      background-size: contain;
      background-position: center center;
      width: 17px;
      height: 24px;
      margin-right: 12px;
    }
    @media (max-width: $md1+px) {
      font-size: 26px;
    }
    @media (max-width: $md2+px) {
      font-size: 22px;
    }
    @media (max-width: $md3+px) {
      font-size: 18px;
      padding-left: 15px;
    }
  }
  &__phone {
    padding-top: 7px;
  }
  &__phone,
  &__mail {
    padding-left: 60px;
    font-size: 22px;
    line-height: 28px;
    font-weight: 400;
    color: #4e6677;
    @media (max-width: $md3+px) {
      padding-left: 45px;
    }
  }
  &__link {
    margin-left: 65px;
    font-size: 18px;
    line-height: 102%;
    font-weight: 700;
    color: #3067bb;
    white-space: nowrap;
    position: relative;
    text-overflow: ellipsis;
    letter-spacing: 0.85px;
    @media (max-width: $md1+px) {
      font-size: 17px;
    }
    @media (max-width: $md2+px) {
      font-size: 16px;
    }
    @media (max-width: $md3+px) {
      font-size: 15px;
      margin-left: 45px;
    }
    &:after {
      content: '';
      width: 100%;
      background-color: #3067bb;
      height: 1px;
      display: block;
      position: absolute;
      bottom: 0;
      left: 50%;
      transform: translateX(-50%);
      transition: all 0.3s ease 0ms;
    }
    &:hover {
      color: #dc240a;
      &:after {
        width: 0;
        background-color: #fff;
      }
    }
  }
}

.marker-moscow {
  &::before {
    background-image: url('../img/icons/pointer-red.svg');
  }
}
.marker-mineral {
  &::before {
    background-image: url('../img/icons/pointer-green.svg');
  }
}
.marker-krym {
  &::before {
    background-image: url('../img/icons/pointer-orange.svg');
  }
}
.marker-new {
  &::before {
    background-image: url('../img/icons/pointer-gray.svg');
  }
}
//====================================================================================================

//====================================================================================================
.question {
  max-width: 969px;
  width: 100%;
  background-color: #fff;
  padding: 35px 75px;
  border-radius: 10px;
  position: absolute;
  bottom: -60px;
  left: 50%;
  transform: translateX(-50%);
  letter-spacing: -0.3px;
  z-index: 8;
  @media (max-width: $md2 + px) {
    width: 90%;
  }
  @media (max-width: $md3+px) {
    padding-left: 35px;
    padding-right: 35px;
  }
  @media (max-width: $md4+px) {
    padding-left: 20px;
    padding-right: 20px;
  }
  &__content {
    display: flex;
    justify-content: space-between;
    align-items: center;
    @media (max-width: $md2+px) {
      flex-direction: column;
    }
  }
  &__text {
    font-family: 'Roboto';
    font-size: 36px;
    line-height: 120%;
    font-weight: 700;
    color: #273646;
    @media (max-width: $md1+px) {
      font-size: 32px;
    }
    @media (max-width: $md2+px) {
      font-size: 26px;
    }
    @media (max-width: $md3+px) {
      font-size: 24px;
      text-align: center;
    }
  }
  &__btn {
    display: flex;
    align-items: center;
    justify-content: center;
    max-width: 239px;
    width: 100%;
    height: 60px;
    padding: 18px 24px;
    border-radius: 5px;
    background-color: #f4de4b;
    border: 1px solid #ccbc4c;
    box-shadow: 0 4px 2px -1px rgba(0, 0, 0, 0.5);
    font-family: 'RobotoCondensed-Bold';
    font-size: 24px;
    text-shadow: -1px -1px 0px rgba(255, 255, 255, 0.25);
    color: #2c3e50;
    transition: all 0.3s ease 0s;
    // @media (max-width: $md1+px) {
    //   font-size: 22px;
    // }
    @media (max-width: $md2+px) {
      // font-size: 20px;
      margin-top: 22px;
    }
    @media (max-width: $md4+px) {
      // font-size: 18px;
      padding: 18px 14px;
    }
    &:hover {
      background-color: #e3cd39;
    }
  }
}
//====================================================================================================
.hideButton {
  display: none !important;
}

.desktop-box__image {
  position: relative;
  img {
    opacity: 0;
    visibility: hidden;
  }
}
.device-image__item {
  position: absolute !important;
  max-width: 1189.14px;
  width: 100%;
  top: 0;
}

.device {
  &__gray {
    .desktop-box__image {
      .d-gray {
        opacity: 1;
        visibility: visible;
      }
    }
  }
  &__jeans {
    .desktop-box__image {
      .d-jeans {
        opacity: 1;
        visibility: visible;
      }
    }
  }
  &__gold {
    .desktop-box__image {
      .d-gold {
        opacity: 1;
        visibility: visible;
      }
    }
  }
  &__grapefruit {
    .desktop-box__image {
      .d-grapefruit {
        opacity: 1;
        visibility: visible;
      }
    }
  }
  &__peper {
    .desktop-box__image {
      .d-peper {
        opacity: 1;
        visibility: visible;
      }
    }
  }
  &__leather {
    .desktop-box__image {
      .d-leather {
        opacity: 1;
        visibility: visible;
      }
    }
  }
  &__tangerin {
    .desktop-box__image {
      .d-tangerin {
        opacity: 1;
        visibility: visible;
      }
    }
  }
  &__mustard {
    .desktop-box__image {
      .d-mustard {
        opacity: 1;
        visibility: visible;
      }
    }
  }
  &__lime {
    .desktop-box__image {
      .d-lime {
        opacity: 1;
        visibility: visible;
      }
    }
  }
  &__grass {
    .desktop-box__image {
      .d-grass {
        opacity: 1;
        visibility: visible;
      }
    }
  }
  &__mint {
    .desktop-box__image {
      .d-mint {
        opacity: 1;
        visibility: visible;
      }
    }
  }
  &__laguna {
    .desktop-box__image {
      .d-laguna {
        opacity: 1;
        visibility: visible;
      }
    }
  }
  &__sky {
    .desktop-box__image {
      .d-sky {
        opacity: 1;
        visibility: visible;
      }
    }
  }
  &__sea {
    .desktop-box__image {
      .d-sea {
        opacity: 1;
        visibility: visible;
      }
    }
  }
  &__lavender {
    .desktop-box__image {
      .d-lavender {
        opacity: 1;
        visibility: visible;
      }
    }
  }
  &__clover {
    .desktop-box__image {
      .d-clover {
        opacity: 1;
        visibility: visible;
      }
    }
  }
}
