//<ОСНОВНОЙ БЛОК>====================================================================================================

//</ОСНОВНОЙ БЛОК>====================================================================================================

//<ГОТОВЫЕ КЛАССЫ>====================================================================================================
.row:after {
  display: block;
  content: '';
  clear: both;
}
.rub:after {
  content: '₽';
}
//====================================================================================================
%noselect {
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}
//====================================================================================================
ol.counter {
  list-style-type: none;
  counter-reset: item;
  li {
    position: relative;
    padding: 0px 0px 0px 45px;
    &:before {
      counter-increment: item;
      content: counter(item);
      position: absolute;
      left: 0;
      top: 0;
      color: #818181;
      font-size: 14px;
      font-weight: 700;
      text-align: center;
      line-height: 26px;
      border-radius: 50%;
      width: 28px;
      height: 28px;
      border: 1px solid #4274bb;
    }
  }
}
//====================================================================================================
.ellipsis {
  display: block;
  overflow: hidden;
  width: 100%;
  white-space: nowrap;
  text-overflow: ellipsis;
}
.es {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}
//====================================================================================================
.table {
  display: table;
  font-size: 0;
  width: 100%;
  //table-layout: fixed;
}
.trow {
  display: table-row;
}
.cell {
  display: table-cell;
  &.full {
    width: 100%;
  }
}
//====================================================================================================
._ibg {
  position: relative;
  img {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    object-fit: cover;
  }
}
body.ie {
  ._ibg {
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
  }
  ._ibg img {
    width: 0;
    height: 0;
    opacity: 0;
    visibility: hidden;
  }
}
//====================================================================================================
.video {
  position: relative;
  overflow: hidden;
  height: 0;
  padding-bottom: 56.25%;
  video,
  iframe,
  object,
  embed {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
}
.videobg {
  video,
  iframe,
  object,
  embed {
    position: fixed;
    top: 50%;
    left: 50%;
    min-width: 100%;
    min-height: 100%;
    width: auto;
    height: auto;
    z-index: -100;
    transform: translateX(-50%) translateY(-50%);
    background-size: cover;
  }
}
//====================================================================================================
.moretext {
  overflow: hidden;
}
.moretext__more {
  cursor: pointer;
  span {
    font-style: normal;
    &:first-child {
      display: block;
    }
    &:last-child {
      display: none;
    }
  }
  &.active {
    span {
      font-style: normal;
      &:first-child {
        display: none;
      }
      &:last-child {
        display: block;
      }
    }
  }
}
//====================================================================================================
%graystyle {
  transition: all 0.3s ease 0s;
  filter: grayscale(1);
  -webkit-filter: grayscale(1);
  -moz-filter: grayscale(1);
  -o-filter: grayscale(1);
}
%graystyleoff {
  filter: grayscale(0);
  -webkit-filter: grayscale(0);
  -moz-filter: grayscale(0);
  -o-filter: grayscale(0);
}
//====================================================================================================
#map {
  background: url('../img/icons/loading.gif') center / 50px no-repeat;
}
//====================================================================================================
._swiper {
  overflow: hidden;
  //.swiper-wrapper
  > div {
    width: 100%;
    height: 100%;
    box-sizing: content-box;
    display: flex;
    position: relative;
  }
  &.swiper-container-autoheight {
    > div {
      align-items: flex-start;
    }
  }
}
.swiper-container-initialized {
  .swiper-slide {
    flex-shrink: 0;
    // transition-property: transform;
  }
}
.swiper-container-android {
  .swiper-slide,
  .swiper-wrapper {
    transform: translate3d(0px, 0, 0);
  }
}
.swiper-button-lock {
  display: none !important;
}
//====================================================================================================
.slick-slider {
  position: relative;
  .slick-track,
  .slick-list {
    -webkit-transform: translate3d(0, 0, 0);
    -moz-transform: translate3d(0, 0, 0);
    -ms-transform: translate3d(0, 0, 0);
    -o-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }
  .slick-list {
    position: relative;
    overflow: hidden;
    width: 100%;
  }
  .slick-track {
    position: relative;
    width: 100%;
    display: flex;
  }
  .slick-slide {
    position: relative;
  }
  .slick-arrow {
    &.slick-prev {
    }
    &.slick-next {
    }
  }
  .slick-dots {
    li {
      button {
      }
      &.slick-active {
        button {
        }
      }
    }
  }
}
//=====================================================================================================================
.tab__item {
  display: none;
  &.active {
    display: block;
  }
}
//=====================================================================================================================
._tabs {
}
._tabs-item {
}
._tabs-block {
  display: none;
  &._active {
    display: block;
  }
}
//=====================================================================================================================
.mirror {
  transform: scale(-1, 1);
}
//=====================================================================================================================
.nicescroll-rails {
  z-index: 1000 !important;
}
//=====================================================================================================================
.gm-style {
}
.gm-style-iw-t {
  opacity: 0;
}
.baloon {
  opacity: 1;
  right: -7px !important;
  bottom: 80px !important;
  button {
    display: none !important;
  }
  &:after {
    display: none !important;
  }
}
.baloon-style {
  display: none;
}
.baloon-content.gm-style-iw {
  opacity: 1;
  border-radius: 0px !important;
  max-width: 300px !important;
  padding: 0 !important;
  left: 0 !important;
  width: 100% !important;
  overflow: visible !important;
  > .gm-style-iw-d {
    overflow: hidden !important;
    max-width: none !important;
  }
  &:after {
    display: none !important;
  }
}
.baloon-close {
  top: 18px !important;
}
//========================================================================================================================================================
._custom-scroll {
  position: fixed;
  right: 0;
  top: 0;
  width: 3px;
  height: 100%;
  z-index: 1000;
  //background-color: rgba(0, 0, 0, 0.1);
  &__line {
    background-color: rgba(0, 0, 0, 0.5);
    border-radius: 3px 0 0 3px;
    position: relative;
    left: 0;
    top: 0;
    width: 100%;
  }
}
//</ГОТОВЫЕ КЛАССЫ>====================================================================================================

.m-0 {
  margin: 0 !important;
}

.btn {
  display: flex;
  justify-content: center;
  align-items: center;
  flex: 0 0 99px;
  max-width: 99px;
  padding: 5px 12px 2px;
  height: 46px;
  border-radius: 5px;
  box-shadow: 0px 4px 2px -1px rgba(0, 0, 0, 0.15);
  transition: all 0.3s ease 0s;
  font-family: 'RobotoCondensed-Bold';
  font-size: 18px;
  text-shadow: -1px -1px 0px rgba(0, 0, 0, 0.25);
  text-transform: uppercase;
  &__blue {
    background-color: #0089c4;
    border: 1px solid #20638f;
    color: #fff;
    // @media (max-width: $md4+px) {
    //   height: 40px;
    // }
    &--long {
      max-width: 195px;
      width: 100%;
    }
    @media (min-width: $md3+px) {
      &:hover,
      &:active {
        background-color: #0179ad;
      }
    }
  }
  &__yellow {
    background-color: #f4de4b;
    border: 1px solid #ccbc4c;
    color: #2c3e50;
    @media (min-width: $md3+px) {
      &:hover,
      &:active {
        background-color: #e3cd39;
      }
    }
  }
  &__red {
    background-color: #d52b1e;
    border: 1px solid #e12a1d;
    color: #fff;
    @media (min-width: $md3+px) {
      &:hover,
      &:active {
        background-color: #b42419;
      }
    }
  }
  &__green {
    background-color: #009b74;
    border: 1px solid #148f77;
    color: #ffffff;
    &--long {
      max-width: 370px;
      width: 100%;
      height: 58px;
      font-family: 'RobotoCondensed-Bold';
      font-size: 23px;
      line-height: 18px;
      text-shadow: -1px -1px 0px rgba(0, 0, 0, 0.25);
      padding-bottom: 4px;
      @media (max-width: $md4+px) {
        max-width: 260px;
      }
    }
    @media (min-width: $md3+px) {
      &:hover,
      &:active {
        background-color: #048464;
      }
    }
    @media (max-width: $md3+px) {
      font-size: 21px;
    }
    @media (max-width: $md4+px) {
      font-size: 18px;
      height: 40px;
    }
  }
}

.h2-title {
  font-family: 'YanoneKaffeesatz-Bold';
  font-size: 72px;
  line-height: 108%;
  text-transform: uppercase;
  letter-spacing: 1.5px;
  @media (max-width: $md1+px) {
    font-size: 66px;
  }
  @media (max-width: $md2+px) {
    font-size: 54px;
  }
  @media (max-width: $md3+px) {
    font-size: 48px;
  }
  @media (max-width: $md4+px) {
    font-size: 36px;
    letter-spacing: 0.65px;
  }
}
.h4-title {
  font-family: 'RobotoCondensed-Bold';
  font-size: 30px;
  line-height: 100%;
  @media (max-width: $md1+px) {
    font-size: 26px;
  }
  @media (max-width: $md2+px) {
    font-size: 22px;
  }
  @media (max-width: $md3+px) {
    font-size: 18px;
  }
}
.h5-title {
  font-family: 'RobotoCondensed-Regular';
  font-size: 22px;
  line-height: 144%;
  letter-spacing: -0.2px;
  @media (max-width: $md1+px) {
    font-size: 20px;
  }
  @media (max-width: $md2+px) {
    font-size: 18px;
  }
  @media (max-width: $md3+px) {
    font-size: 16px;
  }
}
.paragraph {
  font-size: 22px;
  line-height: 144%;
  font-weight: 400;
  @media (max-width: $md1+px) {
    font-size: 18px;
  }
  @media (max-width: $md2+px) {
    font-size: 16px;
  }
  @media (max-width: $md3+px) {
    font-size: 14px;
  }
}

.tabs {
  height: 100%;
  &__nav {
    ul {
      display: flex;
      justify-content: center;
      align-items: flex-end;
    }
    li {
      flex: 0 0 135px;
      @media (max-width: 1378px) {
        flex: 0 0 115px;
      }
      @media (max-width: $md4+px) {
        flex: 0 0 7%;
      }

      &:last-child {
        flex: 0 0 100px;
        @media (max-width: 1378px) {
          flex: 0 0 80px;
        }
        @media (max-width: $md4+px) {
          flex: 0 0 5%;
        }
      }
    }
  }
  &__nav-item {
    display: block;
    width: 100%;
    height: 100%;
  }
  &__nav-icon {
    display: flex;
    justify-content: center;
    position: relative;
    padding: 7px 5px 15px;
    text-align: center;
    cursor: pointer;
    font-size: 0;
    transition: color 0.15s ease-in, background-color 0.15s ease-in;
    overflow: hidden;

    &::after {
      content: '';
      display: block;
      width: 1px;
      height: 2px;
      background-color: #fff;
      position: absolute;
      bottom: 0px;
      left: 50%;
      transform: translateX(-50%);
      transition: all 0.3s ease 0s;
    }
    &:hover::after {
      width: 60%;
      background-color: #2d3a4c;
    }
  }
  &__inner-item {
    // transform: translate(100%);
    transition: all 0.3s ease 0s;
    position: absolute;
    opacity: 0;
    visibility: hidden;
    overflow: hidden;
    height: 0;
    &--active {
      position: static;
      opacity: 1;
      visibility: visible;
      height: auto;
      // transform: translate(0);
    }
  }
  &__inner-box {
    margin: 30px auto 0;
    // height: 910px;
    max-width: 100%;
    display: flex;
    justify-content: center;
    // @media (min-width: 1921px) {
    //   margin-left: 900px;
    // }
  }
  .desktop-box {
    padding-bottom: 5px;
    @media (max-width: $md3+px) {
      padding-bottom: 0;
    }
  }
  .tablet-box {
    margin-top: 35px;
    margin-left: auto;
    @media (max-width: $md3+px) {
      margin-top: 30px;
    }
  }
  .mobile-box {
    margin-top: 35px;
    margin-left: auto;
    @media (max-width: $md3+px) {
      margin-top: 30px;
    }
  }
  &__inner-box figure {
    // max-width: 100%;
    // height: auto;
    position: relative;
    // margin: 0 auto;
    // display: flex;
    // justify-content: center;
  }
  .device-image {
    overflow: hidden;
    object-fit: contain;
    z-index: 1;
    max-height: 712px;
    height: 100%;
    max-width: 1189.13px;
    width: 100%;
    object-fit: contain;
    @media (max-width: 1620px) {
      max-width: 1500px;
      width: 100%;
    }
    // @media (max-width: 1378px) {
    //   max-height: 578px;
    // }
    @media (max-width: $md2+px) {
      max-height: 450px;
    }
    @media (max-width: $md3+px) {
      max-height: 350px;
    }
    @media (max-width: 640px) {
      max-height: 250px;
    }
    @media (max-width: $md4+px) {
      max-height: 200px;
    }
    @media (max-width: 380px) {
      max-height: 175px;
    }
  }
  &__inner-box img {
    position: relative;
    overflow: hidden;
    left: 50%;
    transform: translateX(-50%);
  }
}
.tablet-icon:hover:after {
  width: 28%;
}
.mobile-icon:hover:after {
  width: 25%;
}
.active-tab {
  .tabs__nav-icon::after {
    background-color: #2d3a4c;
    width: 60%;
  }
  .tablet-icon:after {
    width: 28%;
  }
  .mobile-icon:after {
    width: 25%;
  }
}
.visually-hidden {
  position: absolute;
  width: 1px;
  height: 1px;
  margin: -1px;
  border: 0;
  padding: 0;
  white-space: nowrap;
  -webkit-clip-path: inset(100%);
  clip-path: inset(100%);
  clip: rect(0 0 0 0);
  overflow: hidden;
}

.desktop-icon {
  svg {
    display: block;
    width: 82px;
    height: 50px;
    fill: #d5dde5;
    transition: all 0.3s ease 0s;
  }
  &:hover {
    svg {
      fill: #2d3a4c;
    }
  }
}
.tablet-icon {
  svg {
    display: block;
    width: 86px;
    height: 50px;
    fill: #d5dde5;
    transition: all 0.3s ease 0s;
  }
  &:hover {
    svg {
      fill: #2d3a4c;
    }
  }
}
.mobile-icon {
  svg {
    display: block;
    width: 45px;
    height: 45px;
    fill: #d5dde5;
    transition: all 0.3s ease 0s;
  }
  &:hover {
    svg {
      fill: #2d3a4c;
    }
  }
}

.active-tab {
  .desktop-icon svg,
  .tablet-icon svg,
  .mobile-icon svg {
    fill: #2d3a4c;
  }
}

.searchForm {
  display: block;
  margin: 0 auto;
  width: 100%;
  &__block {
    border: 2px solid #b1b5bb;
    background-color: #f9fbfd;
    border-radius: 100px;
    padding: 3px 50px 4px 25px;
    transition: background-color 0.5 ease-in-out;
    position: relative;
    input {
      width: 100%;
      border: 0;
      background-color: transparent;
      width: 100%;
      font-family: 'Roboto';
      font-size: 18px;
      line-height: 18px;
      font-weight: 400;
      color: #2c3e50;
      white-space: nowrap;
      text-overflow: ellipsis;
      &::placeholder {
        font-family: 'Roboto';
        font-size: 16px;
        font-weight: 400;
        color: #97acc0;
      }
      &:focus {
        // Unsure how to fix this...
        outline: none;
      }
    }
    button,
    i {
      border: none;
      background: none;
      cursor: pointer;
    }
    select {
      border: none;
    }
  }
}
