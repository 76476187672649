@import 'blocks/preloader';

//<МИКСИНЫ>===============================================================================================
@import 'libs/mixins';
//</МИКСИНЫ>==============================================================================================

//<Variables>====================================================================================================
$gray: rgba(#646c77, 0.85);
$jeans: rgba(#536f8a, 0.85);
$gold: rgba(#806834, 0.85);
$grapefruit: rgba(#dd1458, 0.85);
$peper: rgba(#d52b1e, 0.85);
$leather: rgba(#c57539, 0.85);
$tangerin: rgba(#ff7900, 0.85);
$mustard: rgba(#eea900, 0.85);
$lime: rgba(#b2bf14, 0.85);
$grass: rgba(#58a11a, 0.85);
$mint: rgba(#199967, 0.85);
$laguna: rgba(#018c9e, 0.85);
$sky: rgba(#038ccd, 0.85);
$sea: rgba(#3e50b4, 0.85);
$lavender: rgba(#6e39c5, 0.85);
$clover: rgba(#c5399b, 0.85);

//</Variables>====================================================================================================

@import 'libs/animation';

//<Подключаем шрифты>=======================================================================================
//&display=swap&subset=cyrillic-ext
@import 'libs/fonts';
//</Подключаем шрифты>=======================================================================================

//<Шрифт по умолчанию>==========================================================================================
$fontFamily: 'Roboto';
//</Шрифт по умолчанию>==========================================================================================

//<ПЕРЕМЕННЫЕ>===========================================================================================================
$minWidth: 320px;
$maxWidth: 1920;
$maxWidthContainer: 1366;
$md1: $maxWidthContainer + 12;
$md2: 991.98;
$md3: 767.98;
$md4: 479.98;
//</ПЕРЕМЕННЫЕ>===========================================================================================================

//<ОБНУЛЕНИЕ, ОБЩИЕ ПАРАМЕТРЫ>===============================================================================================
@import 'libs/null';
body {
  font-size: 22px;
  line-height: 170%;
  background-color: #fff;
  font-family: 'Roboto', sans-serif;
  color: #646c77;
  overflow-x: hidden;
  &._lock {
    overflow: hidden;
  }
}
//</ОБНУЛЕНИЕ, ОБЩИЕ ПАРАМЕТРЫ>===============================================================================================

//<ОБОЛОЧКА>===========================================================================================================
.wrapper {
  width: 100%;
  min-height: 100%;
  overflow: hidden;
  display: flex;
  flex-direction: column;
}
//</ОБОЛОЧКА>===========================================================================================================

//<ОСНОВНАЯ СЕТКА>===========================================================================================================
._container {
  max-width: $maxWidthContainer + px;
  margin: 0 auto;
  @media (max-width: $md1+px) {
    max-width: 970px;
  }
  @media (max-width: $md2+px) {
    max-width: 750px;
  }
  @media (max-width: $md3+px) {
    max-width: none;
    padding: 0 20px !important;
  }
  @media (max-width: $md4+px) {
    padding: 0 15px !important;
  }
}
//</ОСНОВНАЯ СЕТКА>===========================================================================================================

//<ПОДКЛЮЧЕНИЕ ФАЙЛОВ СТИЛЕЙ СТРАНИЦ И ЭЛЕМЕНТОВ>====================================================================================================
@import 'blocks/header';
@import 'blocks/footer';
@import 'blocks/home';
@import 'blocks/sidebar';
@import 'blocks/blog';
@import 'blocks/pagging';
//</ПОДКЛЮЧЕНИЕ ФАЙЛОВ СТИЛЕЙ СТРАНИЦ И ЭЛЕМЕНТОВ>====================================================================================================

//<ПОДКЛЮЧЕНИЕ ФАЙЛОВ UI СТИЛЕЙ, ФОРМ И ГОТОВЫХ КЛАССОВ>====================================================================================================
@import 'blocks/forms';
@import 'blocks/ui';
//</ПОДКЛЮЧЕНИЕ ФАЙЛОВ UI СТИЛЕЙ, ФОРМ И ГОТОВЫХ КЛАССОВ>====================================================================================================

//<ОСНОВНОЙ БЛОК>====================================================================================================
.page {
  flex: 1 1 auto;
  // padding: 100px 0 0 0;
  display: flex;
  max-width: 2560px;
  width: 100%;
  margin-left: auto;
  margin-right: auto;
  &__content {
    display: flex;
    @media (max-width: $md2+px) {
      flex-direction: column-reverse;
    }
  }
  // .page__side
  &__side {
    flex: 0 0 408px;
    padding: 0px;
    background-color: #f1f2f5;
    @media (max-width: $md2+px) {
      flex: 0 0 100%;
    }
  }
  // .page__item
  &__item {
    background-color: #fff;
    @media (max-width: $md3+px) {
      padding-left: 20px;
      padding-right: 20px;
    }
    @media (max-width: $md4+px) {
      padding-left: 0;
      padding-right: 0;
    }
  }
  // .page__body
  &__body {
    flex: 1 1 auto;
    min-width: 0;
    position: relative;
    z-index: 8;
  }
}
//===================================================================================================================================
button {
  background-color: transparent;
}
//========================================================================================================================================================
.slider {
  margin: 0px 0px 50px 0px;
}
//========================================================================================================================================================
.text {
  transform: translate(0px, 100px);
  opacity: 0;
  transition: all 0.3s ease 0s;
  margin: 0px 0px 50px 0px;
  a {
    color: red;
  }
  &._active {
    transform: translate(0px, 0px);
    opacity: 1;
  }
}

//</ОСНОВНОЙ БЛОК>====================================================================================================
