@charset "UTF-8";
.preloader {
  /*фиксированное позиционирование*/
  position: fixed;
  /* координаты положения */
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  /* фоновый цвет элемента */
  background: #e0e0e0;
  /* размещаем блок над всеми элементами на странице (это значение должно быть больше, чем у любого другого позиционированного элемента на странице) */
  z-index: 1001;
}

.preloader__row {
  position: relative;
  top: 50%;
  left: 50%;
  width: 70px;
  height: 70px;
  margin-top: -35px;
  margin-left: -35px;
  text-align: center;
  animation: preloader-rotate 2s infinite linear;
}

.preloader__item {
  position: absolute;
  display: inline-block;
  top: 0;
  background-color: #d52b1e;
  border-radius: 100%;
  width: 35px;
  height: 35px;
  animation: preloader-bounce 2s infinite ease-in-out;
}

.preloader__item:last-child {
  top: auto;
  bottom: 0;
  animation-delay: -1s;
}

@keyframes preloader-rotate {
  100% {
    transform: rotate(360deg);
  }
}
@keyframes preloader-bounce {
  0%, 100% {
    transform: scale(0);
  }
  50% {
    transform: scale(1);
  }
}
.loaded_hiding .preloader {
  transition: 0.3s opacity;
  opacity: 0;
}

.loaded .preloader {
  display: none;
}

/* --- animation --- */
.animated {
  -webkit-animation-duration: 1s;
  animation-duration: 1s;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
}

.animated.infinite {
  -webkit-animation-iteration-count: infinite;
  animation-iteration-count: infinite;
}

.animated.delay-0-1s,
.magictime.delay-0-1s {
  -webkit-animation-delay: 0.1s;
  animation-delay: 0.1s;
}

.animated.delay-0-2s,
.magictime.delay-0-2s {
  -webkit-animation-delay: 0.2s;
  animation-delay: 0.2s;
}

.animated.delay-0-3s,
.magictime.delay-0-3s {
  -webkit-animation-delay: 0.3s;
  animation-delay: 0.3s;
}

.animated.delay-0-4s,
.magictime.delay-0-4s {
  -webkit-animation-delay: 0.4s;
  animation-delay: 0.4s;
}

.animated.delay-0-5s,
.magictime.delay-0-5s {
  -webkit-animation-delay: 0.5s;
  animation-delay: 0.5s;
}

.animated.delay-0-6s,
.magictime.delay-0-6s {
  -webkit-animation-delay: 0.6s;
  animation-delay: 0.6s;
}

.animated.delay-0-7s,
.magictime.delay-0-7s {
  -webkit-animation-delay: 0.7s;
  animation-delay: 0.7s;
}

.animated.delay-0-8s,
.magictime.delay-0-8s {
  -webkit-animation-delay: 0.8s;
  animation-delay: 0.8s;
}

.animated.delay-0-9s,
.magictime.delay-0-9s {
  -webkit-animation-delay: 0.9s;
  animation-delay: 0.9s;
}

.animated.delay-1s,
.magictime.delay-1s {
  -webkit-animation-delay: 1s;
  animation-delay: 1s;
}

.animated.delay-1-1s,
.magictime.delay-1-1s {
  -webkit-animation-delay: 1.1s;
  animation-delay: 1.1s;
}

.animated.delay-1-2s,
.magictime.delay-1-2s {
  -webkit-animation-delay: 1.2s;
  animation-delay: 1.2s;
}

.animated.delay-1-3s,
.magictime.delay-1-3s {
  -webkit-animation-delay: 1.3s;
  animation-delay: 1.3s;
}

.animated.delay-1-4s,
.magictime.delay-1-4s {
  -webkit-animation-delay: 1.4s;
  animation-delay: 1.4s;
}

.animated.delay-1-5s,
.magictime.delay-1-5s {
  -webkit-animation-delay: 1.5s;
  animation-delay: 1.5s;
}

.animated.delay-1-6s,
.magictime.delay-1-6s {
  -webkit-animation-delay: 1.6s;
  animation-delay: 1.6s;
}

.animated.delay-1-7s,
.magictime.delay-1-7s {
  -webkit-animation-delay: 1.7s;
  animation-delay: 1.7s;
}

.animated.delay-1-8s,
.magictime.delay-1-8s {
  -webkit-animation-delay: 1.8s;
  animation-delay: 1.8s;
}

.animated.delay-1-9s,
.magictime.delay-1-9s {
  -webkit-animation-delay: 1.9s;
  animation-delay: 1.9s;
}

.animated.delay-2s,
.magictime.delay-2s {
  -webkit-animation-delay: 2s;
  animation-delay: 2s;
}

.animated.delay-2-1s,
.magictime.delay-2-1s {
  -webkit-animation-delay: 2.1s;
  animation-delay: 2.1s;
}

.animated.delay-2-2s,
.magictime.delay-2-2s {
  -webkit-animation-delay: 2.2s;
  animation-delay: 2.2s;
}

.upanddownsmalls {
  -webkit-animation-name: animation_13;
  -webkit-animation-direction: normal;
  -webkit-animation-fill-mode: both;
  -webkit-animation-play-state: running;
  animation-name: animation_13;
  animation-direction: normal;
  animation-fill-mode: both;
  animation-play-state: running;
}

.upanddownsmall {
  -webkit-animation-name: animation_12;
  -webkit-animation-direction: normal;
  -webkit-animation-fill-mode: both;
  -webkit-animation-play-state: running;
  animation-name: animation_12;
  animation-direction: normal;
  animation-fill-mode: both;
  animation-play-state: running;
}

.upanddown {
  -webkit-animation-name: animation_8;
  -webkit-animation-direction: normal;
  -webkit-animation-fill-mode: both;
  -webkit-animation-play-state: running;
  animation-name: animation_8;
  animation-direction: normal;
  animation-fill-mode: both;
  animation-play-state: running;
}

.upanddowninvers {
  -webkit-animation-name: animation_9;
  -webkit-animation-direction: normal;
  -webkit-animation-fill-mode: both;
  -webkit-animation-play-state: running;
  animation-name: animation_9;
  animation-direction: normal;
  animation-fill-mode: both;
  animation-play-state: running;
}

.animated.duration-1s {
  -webkit-animation-duration: 1s;
  animation-duration: 1s;
}

.animated.duration-2s {
  -webkit-animation-duration: 2s;
  animation-duration: 2s;
}

.animated.duration-3s {
  -webkit-animation-duration: 3s;
  animation-duration: 3s;
}

.fadeInDownCustom {
  -webkit-animation-name: fadeInDownSmall;
  animation-name: fadeInDownSmall;
}

.fadeInUpCustom {
  -webkit-animation-name: fadeInUpSmall;
  animation-name: fadeInUpSmall;
}

.fadeInUpBlock {
  -webkit-animation-name: fadeInUpBlock;
  animation-name: fadeInUpBlock;
}

.fadeInLeftCustom {
  -webkit-animation-name: fadeInLeftSmall;
  animation-name: fadeInLeftSmall;
}

.fadeInRightCustom {
  -webkit-animation-name: fadeInRightSmall;
  animation-name: fadeInRightSmall;
}

@-webkit-keyframes animation_8 {
  0% {
    -webkit-transform: matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1);
    transform: matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1);
    opacity: 1;
    -webkit-animation-timing-function: ease-in-out;
    animation-timing-function: ease-in-out;
  }
  50% {
    -webkit-transform: matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, 25, 0, 1);
    transform: matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, 25, 0, 1);
    opacity: 1;
    -webkit-animation-timing-function: ease-in-out;
    animation-timing-function: ease-in-out;
  }
  100% {
    -webkit-transform: matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1);
    transform: matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1);
    opacity: 1;
  }
}
@keyframes animation_8 {
  0% {
    -webkit-transform: matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1);
    transform: matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1);
    opacity: 1;
    -webkit-animation-timing-function: ease-in-out;
    animation-timing-function: ease-in-out;
  }
  50% {
    -webkit-transform: matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, 25, 0, 1);
    transform: matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, 25, 0, 1);
    opacity: 1;
    -webkit-animation-timing-function: ease-in-out;
    animation-timing-function: ease-in-out;
  }
  100% {
    -webkit-transform: matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1);
    transform: matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1);
    opacity: 1;
  }
}
@-webkit-keyframes animation_9 {
  0% {
    -webkit-transform: matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1);
    transform: matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1);
    opacity: 1;
    -webkit-animation-timing-function: ease-in-out;
    animation-timing-function: ease-in-out;
  }
  50% {
    -webkit-transform: matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, 20, 0, 1);
    transform: matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, 20, 0, 1);
    opacity: 1;
    -webkit-animation-timing-function: ease-in-out;
    animation-timing-function: ease-in-out;
  }
  100% {
    -webkit-transform: matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1);
    transform: matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1);
    opacity: 1;
  }
}
@keyframes animation_9 {
  0% {
    -webkit-transform: matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1);
    transform: matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1);
    opacity: 1;
    -webkit-animation-timing-function: ease-in-out;
    animation-timing-function: ease-in-out;
  }
  50% {
    -webkit-transform: matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, 20, 0, 1);
    transform: matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, 20, 0, 1);
    opacity: 1;
    -webkit-animation-timing-function: ease-in-out;
    animation-timing-function: ease-in-out;
  }
  100% {
    -webkit-transform: matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1);
    transform: matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1);
    opacity: 1;
  }
}
@-webkit-keyframes animation_12 {
  0% {
    -webkit-transform: matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1);
    transform: matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1);
    opacity: 1;
    -webkit-animation-timing-function: ease-in-out;
    animation-timing-function: ease-in-out;
  }
  50% {
    -webkit-transform: matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, 20, 0, 1);
    transform: matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, 20, 0, 1);
    opacity: 1;
    -webkit-animation-timing-function: ease-in-out;
    animation-timing-function: ease-in-out;
  }
  100% {
    -webkit-transform: matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1);
    transform: matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1);
    opacity: 1;
  }
}
@keyframes animation_12 {
  0% {
    -webkit-transform: matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1);
    transform: matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1);
    opacity: 1;
    -webkit-animation-timing-function: ease-in-out;
    animation-timing-function: ease-in-out;
  }
  50% {
    -webkit-transform: matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, 20, 0, 1);
    transform: matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, 20, 0, 1);
    opacity: 1;
    -webkit-animation-timing-function: ease-in-out;
    animation-timing-function: ease-in-out;
  }
  100% {
    -webkit-transform: matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1);
    transform: matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1);
    opacity: 1;
  }
}
@-webkit-keyframes animation_13 {
  0% {
    -webkit-transform: matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1);
    transform: matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1);
    opacity: 1;
    -webkit-animation-timing-function: ease-in-out;
    animation-timing-function: ease-in-out;
  }
  50% {
    -webkit-transform: matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, 10, 0, 1);
    transform: matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, 10, 0, 1);
    opacity: 1;
    -webkit-animation-timing-function: ease-in-out;
    animation-timing-function: ease-in-out;
  }
  100% {
    -webkit-transform: matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1);
    transform: matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1);
    opacity: 1;
  }
}
@keyframes animation_13 {
  0% {
    -webkit-transform: matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1);
    transform: matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1);
    opacity: 1;
    -webkit-animation-timing-function: ease-in-out;
    animation-timing-function: ease-in-out;
  }
  50% {
    -webkit-transform: matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, 10, 0, 1);
    transform: matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, 10, 0, 1);
    opacity: 1;
    -webkit-animation-timing-function: ease-in-out;
    animation-timing-function: ease-in-out;
  }
  100% {
    -webkit-transform: matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1);
    transform: matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1);
    opacity: 1;
  }
}
@-webkit-keyframes fadeInDownSmall {
  0% {
    opacity: 0;
    -webkit-transform: translateY(-20px);
  }
  100% {
    opacity: 1;
    -webkit-transform: translateY(0);
  }
}
@keyframes fadeInDownSmall {
  0% {
    opacity: 0;
    -webkit-transform: translateY(-20px);
    transform: translateY(-20px);
  }
  100% {
    opacity: 1;
    -webkit-transform: translateY(0);
    transform: translateY(0);
  }
}
@-webkit-keyframes fadeInUpSmall {
  0% {
    opacity: 0;
    -webkit-transform: translateY(20px);
  }
  100% {
    opacity: 1;
    -webkit-transform: translateY(0);
  }
}
@keyframes fadeInUpSmall {
  0% {
    opacity: 0;
    -webkit-transform: translateY(20px);
    transform: translateY(20px);
  }
  100% {
    opacity: 1;
    -webkit-transform: translateY(0);
    transform: translateY(0);
  }
}
@-webkit-keyframes fadeInUpBlock {
  0% {
    opacity: 0;
    -webkit-transform: translate(-50%, 20px);
  }
  100% {
    opacity: 1;
    -webkit-transform: translate(-50%, 0);
  }
}
@keyframes fadeInUpBlock {
  0% {
    opacity: 0;
    -webkit-transform: translate(-50%, 20px);
    transform: translate(-50%, 20px);
  }
  100% {
    opacity: 1;
    -webkit-transform: translate(-50%, 0);
    transform: translate(-50%, 0);
  }
}
@-webkit-keyframes fadeInLeftSmall {
  0% {
    opacity: 0;
    -webkit-transform: translateX(-30px);
  }
  100% {
    opacity: 1;
    -webkit-transform: translateY(0);
  }
}
@keyframes fadeInLeftSmall {
  0% {
    opacity: 0;
    -webkit-transform: translateX(-30px);
    transform: translateX(-30px);
  }
  100% {
    opacity: 1;
    -webkit-transform: translateY(0);
    transform: translateY(0);
  }
}
@-webkit-keyframes fadeInRightSmall {
  0% {
    opacity: 0;
    -webkit-transform: translateX(50px);
  }
  100% {
    opacity: 1;
    -webkit-transform: translateY(0);
  }
}
@keyframes fadeInRightSmall {
  0% {
    opacity: 0;
    -webkit-transform: translateX(50px);
    transform: translateX(50px);
  }
  100% {
    opacity: 1;
    -webkit-transform: translateY(0);
    transform: translateY(0);
  }
}
@-webkit-keyframes slideOutLeft {
  from {
    -webkit-transform: translateX(0);
    transform: translateX(0);
  }
  to {
    visibility: hidden;
    -webkit-transform: translateX(-110%);
    transform: translateX(-110%);
  }
}
@keyframes slideOutLeft {
  from {
    -webkit-transform: translateX(0);
    transform: translateX(0);
  }
  to {
    visibility: hidden;
    -webkit-transform: translateX(-110%);
    transform: translateX(-110%);
  }
}
@font-face {
  font-family: "Frutiger-Bold";
  font-display: swap;
  src: url("../fonts/Frutiger Neue LT W1G Bold.woff") format("woff"), url("../fonts/Frutiger Neue LT W1G Bold.woff2") format("woff2");
  font-weight: 400;
  font-style: normal;
}
@font-face {
  font-family: "Lato-Bold";
  font-display: swap;
  src: url("../fonts/Lato-Bold.woff") format("woff"), url("../fonts/Lato-Bold.woff2") format("woff2");
  font-weight: 400;
  font-style: normal;
}
@font-face {
  font-family: "PTSansNarrow-Bold";
  font-display: swap;
  src: url("../fonts/PTSansNarrow-Bold.woff") format("woff"), url("../fonts/PTSansNarrow-Bold.woff2") format("woff2");
  font-weight: 400;
  font-style: normal;
}
@font-face {
  font-family: "Roboto";
  font-display: swap;
  src: url("../fonts/Roboto-Regular.woff") format("woff"), url("../fonts/Roboto-Regular.woff2") format("woff2");
  font-weight: 400;
  font-style: normal;
}
@font-face {
  font-family: "RobotoCondensed-Bold";
  font-display: swap;
  src: url("../fonts/RobotoCondensed-Bold.woff") format("woff"), url("../fonts/RobotoCondensed-Bold.woff2") format("woff2");
  font-weight: 400;
  font-style: normal;
}
@font-face {
  font-family: "Roboto-Bold";
  font-display: swap;
  src: url("../fonts/Roboto-Bold.woff") format("woff"), url("../fonts/Roboto-Bold.woff2") format("woff2");
  font-weight: 400;
  font-style: normal;
}
@font-face {
  font-family: "Roboto-Italic";
  font-display: swap;
  src: url("../fonts/Roboto-Italic.woff") format("woff"), url("../fonts/Roboto-Italic.woff2") format("woff2");
  font-weight: 400;
  font-style: normal;
}
@font-face {
  font-family: "RobotoCondensed-Regular";
  font-display: swap;
  src: url("../fonts/RobotoCondensed-Regular.woff") format("woff"), url("../fonts/RobotoCondensed-Regular.woff2") format("woff2");
  font-weight: 400;
  font-style: normal;
}
@font-face {
  font-family: "RobotoCondensed-Italic";
  font-display: swap;
  src: url("../fonts/RobotoCondensed-Italic.woff") format("woff"), url("../fonts/RobotoCondensed-Italic.woff2") format("woff2");
  font-weight: 400;
  font-style: normal;
}
@font-face {
  font-family: "YanoneKaffeesatz-Regular";
  font-display: swap;
  src: url("../fonts/YanoneKaffeesatz-Regular.woff") format("woff"), url("../fonts/YanoneKaffeesatz-Regular.woff2") format("woff2");
  font-weight: 400;
  font-style: normal;
}
@font-face {
  font-family: "YanoneKaffeesatz-Bold";
  font-display: swap;
  src: url("../fonts/YanoneKaffeesatz-Bold.woff") format("woff"), url("../fonts/YanoneKaffeesatz-Bold.woff2") format("woff2");
  font-weight: 400;
  font-style: normal;
}
* {
  padding: 0px;
  margin: 0px;
  border: 0px;
}

*,
*:before,
*:after {
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
}

:focus,
:active {
  outline: none;
}

a:focus,
a:active {
  outline: none;
}

aside,
nav,
footer,
header,
section {
  display: block;
}

html,
body {
  height: 100%;
  min-width: 320px;
}

body {
  line-height: 1;
  font-family: "Roboto";
  -ms-text-size-adjust: 100%;
  -moz-text-size-adjust: 100%;
  -webkit-text-size-adjust: 100%;
}

input,
button,
textarea {
  font-family: "Roboto";
}

input::-ms-clear {
  display: none;
}

button {
  cursor: pointer;
}

button::-moz-focus-inner {
  padding: 0;
  border: 0;
}

a,
a:visited {
  text-decoration: none;
}

a:hover {
  text-decoration: none;
}

ul li {
  list-style: none;
}

img {
  vertical-align: top;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-weight: inherit;
  font-size: inherit;
}

body {
  font-size: 22px;
  line-height: 170%;
  background-color: #fff;
  font-family: "Roboto", sans-serif;
  color: #646c77;
  overflow-x: hidden;
}
body._lock {
  overflow: hidden;
}

.wrapper {
  width: 100%;
  min-height: 100%;
  overflow: hidden;
  display: flex;
  flex-direction: column;
}

._container {
  max-width: 1366px;
  margin: 0 auto;
}
@media (max-width: 1378px) {
  ._container {
    max-width: 970px;
  }
}
@media (max-width: 991.98px) {
  ._container {
    max-width: 750px;
  }
}
@media (max-width: 767.98px) {
  ._container {
    max-width: none;
    padding: 0 20px !important;
  }
}
@media (max-width: 479.98px) {
  ._container {
    padding: 0 15px !important;
  }
}

.header._scroll {
  margin-top: -5px;
  z-index: 10;
  top: 119px;
  position: fixed;
  left: 0;
  right: 0;
  background-color: rgba(255, 255, 255, 0.95);
}
@media (max-width: 1378px) {
  .header._scroll {
    top: 105px;
  }
}
@media (max-width: 991.98px) {
  .header._scroll {
    top: 90px;
  }
}
@media (max-width: 767.98px) {
  .header._scroll {
    top: 85px;
  }
}
@media (max-width: 598px) {
  .header._scroll {
    top: 110px;
  }
}
@media (max-width: 479.98px) {
  .header._scroll {
    top: 115px;
  }
}
@media (max-width: 401px) {
  .header._scroll {
    top: 140px;
  }
}

@media (max-width: 1199px) {
  .offer-block:not(.close-block) ~ .header .menu__body {
    top: 100px;
  }
}
@media (max-width: 991.98px) {
  .offer-block:not(.close-block) ~ .header .menu__body {
    top: 85px;
    padding-top: 70px;
    padding-left: 2px;
  }
}
@media (max-width: 767.98px) {
  .offer-block:not(.close-block) ~ .header .menu__body {
    padding-top: 10px;
  }
}
@media (max-width: 599px) {
  .offer-block:not(.close-block) ~ .header .menu__body {
    top: 109px;
    padding-top: 10px;
  }
}
@media (max-width: 479.98px) {
  .offer-block:not(.close-block) ~ .header .menu__body {
    top: 100px;
    padding-left: 0px;
  }
  .offer-block:not(.close-block) ~ .header .menu__body .mobile-logo {
    margin-left: 15px;
    margin-top: 10px;
  }
}
@media (max-width: 401px) {
  .offer-block:not(.close-block) ~ .header .menu__body {
    top: 125px;
    padding-top: 10px;
  }
  .offer-block:not(.close-block) ~ .header .menu__body .mobile-logo {
    margin-left: 14px;
  }
}

.offer-block:not(.close-block) {
  padding-bottom: 10px;
}

.offer-block.close-block ~ .promo-header {
  margin-top: 50px;
}
@media (max-width: 1378px) {
  .offer-block.close-block ~ .promo-header {
    margin-top: 30px;
  }
}
@media (max-width: 1199px) {
  .offer-block.close-block ~ .promo-header {
    margin-top: -25px;
  }
}
@media (max-width: 991.98px) {
  .offer-block.close-block ~ .promo-header {
    margin-top: 50px;
  }
}
@media (max-width: 479.98px) {
  .offer-block.close-block ~ .promo-header {
    margin-top: 30px;
  }
}

.header {
  position: fixed;
  background-color: #fff;
  box-shadow: 0px -2px 5px 0px #000000;
  max-width: 2560px;
  margin-left: auto;
  margin-right: auto;
  width: 100%;
  top: 114px;
  left: 0;
  right: 0;
  z-index: 10;
  margin-bottom: -15px;
  height: 87px;
}
.header__two {
  margin-top: 0 !important;
  margin-bottom: -20px;
  top: 0 !important;
}
@media (max-width: 1378px) {
  .header {
    height: 76px;
    top: 100px;
  }
}
@media (max-width: 1199px) {
  .header {
    height: 68px;
  }
}
@media (max-width: 991.98px) {
  .header {
    top: 85px;
  }
}
@media (max-width: 767.98px) {
  .header {
    top: 80px;
  }
}
@media (max-width: 598px) {
  .header {
    top: 105px;
  }
}
@media (max-width: 479.98px) {
  .header {
    top: 110px;
  }
}
@media (max-width: 401px) {
  .header {
    top: 135px;
  }
}
@media (max-width: 479.98px) {
  .header {
    height: 48px;
  }
}

.offer-block {
  background-color: #18ae90;
  max-width: 2560px;
  margin: 0 auto;
  width: 100%;
  padding-top: 20px;
  padding-bottom: 20px;
  padding-left: 55px;
  padding-right: 55px;
  color: #fff;
  font-family: "RobotoCondensed-Regular";
  font-size: 28px;
  text-align: center;
  line-height: 150%;
  letter-spacing: 0.7px;
  z-index: 11;
  box-shadow: inset 0px 0px 20px 0px rgba(0, 0, 0, 0.3);
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  transition: all 0.5s;
}
@media (max-width: 1378px) {
  .offer-block {
    font-size: 24px;
  }
}
@media (max-width: 991.98px) {
  .offer-block {
    font-size: 20px;
  }
}
@media (max-width: 767.98px) {
  .offer-block {
    font-size: 18px;
    background-color: #009b74;
  }
}
@media (max-width: 479.98px) {
  .offer-block {
    font-size: 18px;
  }
}
.offer-block.close-block {
  display: none;
}
.offer-block a {
  color: #FFF;
}
.offer-block button {
  position: absolute;
  right: 15%;
  top: 50%;
  transform: translateY(-50%);
}
@media (max-width: 1378px) {
  .offer-block button {
    right: 40px;
  }
}
@media (max-width: 767.98px) {
  .offer-block button {
    right: 20px;
  }
}

.close-btn {
  display: block;
  width: 30px;
  height: 30px;
  cursor: pointer;
  z-index: 30;
  background: url("../img/icons/close.svg") center center no-repeat;
}

.offer-block.close-block ~ .header._scroll {
  top: 5px;
}

.offer-block.close-block ~ .header {
  top: 0px;
}

.top-header__content {
  display: flex;
  justify-content: space-between;
  position: relative;
}
.top-header__column {
  flex: 0 0 65%;
}
.top-header__column:first-child {
  flex: 0 0 362px;
}
@media (max-width: 1378px) {
  .top-header__column:first-child {
    flex: 0 0 300px;
  }
}
@media (max-width: 1199px) {
  .top-header__column:first-child {
    flex: 0 0 262px;
  }
}
.top-header__column:last-child {
  margin-right: -190px;
  transition: all 0.3s ease 0s;
}
@media (max-width: 1820px) {
  .top-header__column:last-child {
    margin-right: -100px;
  }
}
@media (max-width: 1680px) {
  .top-header__column:last-child {
    margin-right: 0px;
  }
}
@media (max-width: 1378px) {
  .top-header__column:last-child {
    margin-right: -50px;
    flex: 0 0 80%;
  }
}
@media (max-width: 1199px) {
  .top-header__column:last-child {
    flex: 0 0 100%;
  }
}
.top-header__logo {
  display: block;
  max-width: 364px;
  width: 100%;
  margin-top: 15px;
  transition: all 0.3s;
}
.top-header__logo img {
  transition: all 0.3s;
  object-fit: contain;
  width: 100%;
}
@media (max-width: 1378px) {
  .top-header__logo {
    max-width: 300px;
  }
  .top-header__logo img {
    width: 100%;
  }
}
@media (max-width: 479px) {
  .top-header__logo {
    max-width: 180px;
    margin-top: 10px;
  }
}

.mobile-logo {
  display: none;
}
@media (max-width: 768px) {
  .mobile-logo {
    display: block;
    margin-left: 17px;
    margin-top: 2px;
    width: 262px;
  }
}

@media (max-width: 479.98px) {
  .offer-block:not(.close-block) ~ .mobile-logo {
    margin-top: 10px;
  }
}

@media (max-width: 767.98px) {
  .header__two .mobile-logo {
    margin-left: 20px;
    margin-top: 15px;
  }
}
@media (max-width: 479.98px) {
  .header__two .mobile-logo {
    margin-left: 13px;
    margin-top: 10px;
  }
}

@media (max-width: 767.98px) {
  .offer-block.close-block ~ .header .mobile-logo {
    margin-top: 15px;
    margin-left: 19px;
  }
}
@media (max-width: 479.98px) {
  .offer-block.close-block ~ .header .mobile-logo {
    margin-top: 10px;
    margin-left: 15px;
  }
}

@media (max-width: 768px) {
  .header__two > .mobile-logo {
    margin-left: 20px;
  }
}

@media (max-width: 1199px) {
  .menu__body {
    transform: translateX(1000%);
    opacity: 0;
    visibility: hidden;
    position: fixed;
    height: 100%;
    width: 50%;
    top: 0;
    right: 0;
    overflow: auto;
    background-color: #fff;
    padding: 80px 0px 30px 0px;
    z-index: 98;
    -webkit-transition: all 0.3s ease 0s;
    -o-transition: all 0.3s ease 0s;
    transition: all 0.3s ease 0s;
    border-left: 1px solid #000;
    box-shadow: -2px 0px 15px 0px rgba(0, 0, 0, 0.51);
  }
  .menu__body._active {
    opacity: 1;
    visibility: visible;
    transform: translateX(0);
    transition: all 0.3s ease 0s;
  }
}
@media (max-width: 768px) {
  .menu__body {
    width: 100%;
    padding-top: 0px;
    transform: translateY(-1000%);
  }
  .menu__body._active {
    transform: translateY(0);
  }
}
.menu__list {
  display: flex;
  justify-content: flex-end;
}
@media (max-width: 1199px) {
  .menu__list {
    display: block;
  }
}
.menu__list-item {
  margin: 0;
  transition: all 0.3s ease 0s;
  position: relative;
}
@media (min-width: 1200px) {
  .menu__list-item:hover {
    background-color: #394a58;
  }
  .menu__list-item:hover .dropdown {
    color: #fff;
  }
  .menu__list-item:hover .dropdown::before {
    top: 48%;
    border-top: none;
    border-bottom: 5px solid #fff;
  }
  .menu__list-item.login-button:hover {
    background: none;
  }
}
@media (max-width: 1199px) {
  .menu__list-item {
    text-align: right;
  }
}
.menu__list-item:first-child {
  margin: 20px 20px 0 0;
  transition: all 0.3s ease 0s;
}
@media (max-width: 1600px) {
  .menu__list-item:first-child {
    margin: 20px 10px 0 0;
  }
}
@media (max-width: 1199px) {
  .menu__list-item:first-child {
    margin: 22px 0;
    padding-right: 80px;
  }
}
@media (max-width: 767.98px) {
  .menu__list-item:first-child {
    padding-right: 25px;
  }
}
.menu__list-item:first-child:hover {
  background: none;
  color: #009b74;
}
.menu__list-item.login-button {
  margin-top: 19px;
  margin-right: 0;
  margin-left: 40px;
  transition: all 0.3s ease 0s;
}
@media (max-width: 1600px) {
  .menu__list-item.login-button {
    margin-left: 30px;
  }
}
@media (max-width: 1199px) {
  .menu__list-item.login-button {
    padding-right: 80px;
    margin-left: 0;
  }
}
@media (max-width: 767.98px) {
  .menu__list-item.login-button {
    padding-right: 25px;
  }
}
.menu__link {
  display: inline-block;
  background: none;
  padding: 32px 30px 32px 25px;
  font-family: "RobotoCondensed-Bold";
  font-size: 18px;
  line-height: 113%;
  color: #273646;
  position: relative;
  text-transform: uppercase;
}
@media (max-width: 1378px) {
  .menu__link {
    padding: 29px 30px 29px 25px;
  }
}
@media (max-width: 1199px) {
  .menu__link {
    margin-left: auto;
  }
}
@media (max-width: 1378px) {
  .menu__link {
    font-size: 16px;
  }
}
.menu__btn {
  width: 99px;
  padding: 10px 12px;
}

@media (max-width: 1199px) {
  .menu__list-item:hover {
    background: none;
  }
  .menu__list-item:hover .dropdown.expanded {
    color: inherit;
    transition: all 0.3s ease 0s;
  }
}
.dropdown {
  transition: all 0.3s ease 0s;
}
@media (max-width: 1199px) {
  .dropdown {
    padding: 25px 30px 25px 25px;
    padding-right: 100px;
    display: block;
    transition: all 0.3s ease 0s;
  }
  .dropdown:hover {
    background-color: #394a58;
    color: #fff;
  }
  .dropdown:hover:before {
    border-top: 5px solid #fff;
  }
}
@media (max-width: 767.98px) {
  .dropdown {
    padding-right: 50px;
  }
}
.dropdown::before {
  content: "";
  position: absolute;
  top: 50%;
  right: 13px;
  transform: translate(0, -50%);
  border-top: 5px solid #273646;
  border-left: 5px solid transparent;
  border-right: 5px solid transparent;
  transition: all 0.1s;
}
@media (max-width: 1199px) {
  .dropdown::before {
    right: 80px;
  }
}
@media (max-width: 767.98px) {
  .dropdown::before {
    right: 28px;
  }
}

@media (max-width: 1199px) {
  .dropdown.expanded {
    transition: all 0.3s ease 0s;
  }
  .dropdown.expanded::before {
    top: 48%;
    border-top: none;
    border-bottom: 5px solid #273646;
  }
  .dropdown.expanded:hover {
    background: none;
    color: inherit;
  }
}
.submenu__list {
  background-color: #394a58;
  border-radius: 5px;
  border-top-right-radius: 0;
  box-shadow: 0px 30px 25px 0px rgba(0, 0, 0, 0.5);
  padding: 35px 0 35px 0;
  position: absolute;
  top: 82px;
  right: 0;
  transition: all 0.3s ease 0s;
  transform: translateY(-5px);
  opacity: 0;
  visibility: hidden;
}
@media (max-width: 1378px) {
  .submenu__list {
    top: 76px;
  }
}
@media (max-width: 1199px) {
  .submenu__list {
    transition: all 0.3s ease 0s;
    display: none;
    position: static;
    opacity: 1;
    visibility: visible;
    box-shadow: none;
    border-radius: 0;
    padding: 0;
  }
}
.submenu__list li:first-child a {
  padding-top: 20px;
}
.submenu__list li:last-child a {
  padding-bottom: 20px;
}
.submenu__link {
  font-size: 18px;
  padding: 0 100px 0 35px;
  line-height: 113%;
  color: #fff;
  text-transform: none;
  white-space: nowrap;
  transition: all 0.3s ease 0s;
}
.submenu__link:hover, .submenu__link:active {
  color: #f4de4b;
}
@media (max-width: 1378px) {
  .submenu__link {
    font-size: 16px;
  }
}
@media (max-width: 1199px) {
  .submenu__link {
    display: block;
    padding: 13px 80px 13px 0;
  }
}
@media (max-width: 767.98px) {
  .submenu__link {
    padding: 13px 25px 13px 0;
  }
}
.submenu__link-offers {
  position: relative;
  transition: all 0.3s ease 0s;
}
.submenu__link-offers::before {
  display: none !important;
}
.submenu__link-offers:hover .thumb-icon {
  background: url("../img/icons/thumbs-up-yellow.svg") center/contain no-repeat;
}

.thumb-icon {
  width: 20px;
  height: 20px;
  background: url("../img/icons/thumbs-up-orange.svg") center/contain no-repeat;
  display: inline-block;
  margin-right: 5px;
  position: relative;
  top: 3px;
  transition: all 0.3s ease 0s;
}

@media (min-width: 1200px) {
  .menu__list-item:hover > .submenu__list {
    transform: translateY(0);
    opacity: 1;
    visibility: visible;
    z-index: 2;
  }
}
.link {
  color: #18ad8f;
  border: 2px solid #18ad8f;
  padding: 10px 12px;
  transition: all 0.3s;
}
.link:hover, .link:active {
  background-color: #18ad8f;
  color: #ffffff !important;
}

.icon-menu {
  display: none;
}
@media (max-width: 1199px) {
  .icon-menu {
    display: block;
    position: absolute;
    top: 28px;
    right: 0;
    width: 30px;
    height: 18px;
    cursor: pointer;
    z-index: 99;
  }
  .icon-menu span {
    transition: all 0.3s ease 0s;
    top: calc(50% - 1px);
    left: 0px;
    position: absolute;
    width: 100%;
    height: 2px;
    background-color: #273646;
  }
  .icon-menu span:first-child {
    top: 0px;
  }
  .icon-menu span:last-child {
    top: auto;
    bottom: 0px;
  }
  .icon-menu._active span {
    transform: scale(0);
  }
  .icon-menu._active span:first-child {
    transform: rotate(-45deg);
    top: calc(50% - 1px);
  }
  .icon-menu._active span:last-child {
    transform: rotate(45deg);
    bottom: calc(50% - 1px);
  }
}
@media (max-width: 767.98px) {
  .icon-menu {
    right: 20px;
  }
}
@media (max-width: 479.98px) {
  .icon-menu {
    top: 16px;
  }
}

.promo-header {
  margin-top: 165px;
  background-image: url("../img/header-bg.png") !important;
  background-repeat: no-repeat;
  background-position: 14% 10%;
  max-width: 2560px;
  width: 100%;
  margin-left: auto;
  margin-right: auto;
  padding-top: 77px;
  padding-bottom: 75px;
  position: relative;
  z-index: 1;
  min-height: 725px;
}
@media (min-width: 1921px) {
  .promo-header {
    padding-bottom: 150px;
  }
}
@media (max-width: 1378px) {
  .promo-header {
    padding-top: 80px;
    margin-top: 135px;
  }
}
@media (max-width: 1199px) {
  .promo-header {
    padding-top: 130px;
    margin-top: 80px;
  }
}
@media (max-width: 991.98px) {
  .promo-header {
    padding-top: 25px;
    margin-top: 150px;
  }
}
@media (max-width: 598px) {
  .promo-header {
    margin-top: 170px;
  }
}
@media (max-width: 479.98px) {
  .promo-header {
    margin-top: 135px;
  }
}
@media (max-width: 401px) {
  .promo-header {
    margin-top: 165px;
  }
}
.promo-header__content {
  display: flex;
  justify-content: space-between;
  position: relative;
  z-index: 3;
}
@media (max-width: 991.98px) {
  .promo-header__content {
    flex-direction: column;
  }
}
.promo-header__column {
  flex: 0 0 50%;
}
@media (max-width: 479.98px) {
  .promo-header__column:last-of-type {
    display: none;
  }
}
.promo-header__title {
  font-family: "YanoneKaffeesatz-Bold";
  font-size: 80px;
  line-height: 122%;
  text-shadow: 3px 5px 0px rgba(31, 38, 46, 0.75);
  color: #ffffff;
}
@media (max-width: 1378px) {
  .promo-header__title {
    font-size: 66px;
  }
}
@media (max-width: 1200px) {
  .promo-header__title {
    font-size: 60px;
  }
}
@media (max-width: 991.98px) {
  .promo-header__title {
    font-size: 58px;
    text-align: center;
  }
}
@media (max-width: 767.98px) {
  .promo-header__title {
    font-size: 48px;
    text-shadow: 3px 4px 0px rgba(31, 38, 46, 0.75);
  }
}
@media (max-width: 479.98px) {
  .promo-header__title {
    letter-spacing: 0.95px;
    font-size: 38px;
    text-shadow: 3px 3px 0px rgba(31, 38, 46, 0.75);
  }
}
.promo-header__text {
  max-width: 533px;
  font-family: "YanoneKaffeesatz-Regular";
  font-size: 50px;
  line-height: 120%;
  text-shadow: 3px 5px 0px rgba(31, 38, 46, 0.75);
  color: #ffffff;
  letter-spacing: 0.45px;
  padding-top: 5px;
}
@media (max-width: 1378px) {
  .promo-header__text {
    padding-top: 20px;
    font-size: 38px;
  }
}
@media (max-width: 991.98px) {
  .promo-header__text {
    padding-top: 0;
    font-size: 35px;
  }
}
@media (max-width: 767.98px) {
  .promo-header__text {
    font-size: 28px;
  }
}
@media (max-width: 479.98px) {
  .promo-header__text {
    font-size: 22px;
    letter-spacing: 0.9px;
    max-width: 255px;
  }
}
@media (max-width: 991.98px) {
  .promo-header__text {
    text-align: center;
    margin: 0 auto;
    padding-right: 0;
    text-shadow: 2px 4px 0px rgba(31, 38, 46, 0.75);
  }
}
@media (max-width: 767.98px) {
  .promo-header__text {
    text-shadow: 2px 3px 0px rgba(31, 38, 46, 0.75);
  }
}
@media (max-width: 479.98px) {
  .promo-header__text {
    text-shadow: 1px 2px 0px rgba(31, 38, 46, 0.75);
  }
}
.promo-header__button {
  margin-top: 50px;
}
@media (max-width: 991.98px) {
  .promo-header__button {
    margin-top: 37px;
    display: block;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 40px;
  }
}
.promo-header__image {
  position: relative;
}
@media (max-width: 991.98px) {
  .promo-header__image {
    max-width: 480px;
    width: 100%;
    margin-left: auto;
    margin-right: auto;
  }
}
@media (max-width: 767.98px) {
  .promo-header__image {
    max-width: 380px;
    width: 100%;
    margin-left: auto;
    margin-right: auto;
  }
}
.promo-header__image:after {
  content: "";
  display: block;
  background: url("../img/icons/arrow.svg") center/contain no-repeat;
  max-width: 352px;
  width: 100%;
  height: 105px;
  position: absolute;
  bottom: 0;
  left: -5%;
  transform: translateX(-50%);
  z-index: 9;
}
@media (min-width: 1921px) {
  .promo-header__image:after {
    background: url("../img/icons/arrow.svg") center/95% no-repeat;
    bottom: 4%;
  }
}
@media (max-width: 1600px) {
  .promo-header__image:after {
    background: url("../img/icons/arrow.svg") center/90% no-repeat;
  }
}
@media (max-width: 1378px) {
  .promo-header__image:after {
    transform: rotate(20deg);
    left: -159px;
    bottom: 7%;
    background: url("../img/icons/arrow.svg") center/70% no-repeat;
  }
}
@media (max-width: 1280px) {
  .promo-header__image:after {
    bottom: 9%;
    background: url("../img/icons/arrow.svg") center/60% no-repeat;
  }
}
@media (max-width: 991.98px) {
  .promo-header__image:after {
    display: none;
  }
}

.intro-promo {
  padding-top: 20px;
  flex: 0 0 47%;
}
@media (max-width: 1378px) {
  .intro-promo {
    flex: 0 0 50%;
    padding-top: 36px;
  }
}

.promo-footer {
  padding: 0 0 500px;
  margin-top: -190px;
  position: relative;
  z-index: 2;
  max-width: 2560px;
  width: 100%;
  margin-left: auto;
  margin-right: auto;
  height: 845px;
  clip-path: polygon(0 21%, 100% 5.2%, 100% 100%, 0% 100%);
}
@media (min-width: 1921px) {
  .promo-footer {
    clip-path: polygon(0 28%, 100% 7.2%, 100% 100%, 0% 100%);
    margin-top: -315px;
  }
}
@media (max-width: 1919px) {
  .promo-footer {
    margin-top: -236px;
    clip-path: polygon(0 25.5%, 100% 10.1%, 100% 100%, 0% 100%);
  }
}
@media (max-width: 1280px) {
  .promo-footer {
    clip-path: polygon(0 19%, 100% 8%, 100% 100%, 0% 100%);
  }
}
@media (max-width: 1199px) {
  .promo-footer {
    clip-path: polygon(0 18%, 100% 9%, 100% 100%, 0% 100%);
  }
}
@media (max-width: 991.98px) {
  .promo-footer {
    clip-path: polygon(0 19%, 100% 10%, 100% 100%, 0% 100%);
    height: 860px;
    margin-top: -230px;
  }
}
@media (max-width: 859px) {
  .promo-footer {
    clip-path: polygon(0 18%, 100% 10%, 100% 100%, 0% 100%);
    height: 1200px;
    margin-top: -270px;
  }
}
@media (max-width: 767.98px) {
  .promo-footer {
    clip-path: polygon(0 17%, 100% 11%, 100% 100%, 0% 100%);
    height: 1220px;
    margin-top: -350px;
  }
}
@media (max-width: 619px) {
  .promo-footer {
    height: 1270px;
    clip-path: polygon(0 16%, 100% 11%, 100% 100%, 0% 100%);
  }
}
@media (max-width: 599px) {
  .promo-footer {
    height: 940px;
    margin-top: -310px;
  }
}
@media (max-width: 480px) {
  .promo-footer {
    height: 870px;
  }
}
@media (max-width: 450px) {
  .promo-footer {
    height: 910px;
  }
}
@media (max-width: 479.98px) {
  .promo-footer {
    clip-path: polygon(0 17%, 100% 12%, 100% 100%, 0% 100%);
    margin-top: -488px;
  }
}
.promo-footer__image {
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  top: 20px;
  height: 100%;
  object-fit: cover;
  width: 100%;
  opacity: 0;
  visibility: hidden;
}
.promo-footer__content {
  margin-top: 217px;
  margin-bottom: 420px;
}
@media (max-width: 1378px) {
  .promo-footer__content {
    margin-bottom: 395px;
    margin-top: 250px;
  }
}
@media (max-width: 1199px) {
  .promo-footer__content {
    margin-bottom: 445px;
  }
}
@media (max-width: 991.98px) {
  .promo-footer__content {
    margin-bottom: 485px;
    margin-top: 244px;
  }
}
@media (max-width: 859px) {
  .promo-footer__content {
    margin-bottom: 790px;
    margin-top: 280px;
  }
}
@media (max-width: 767.98px) {
  .promo-footer__content {
    margin-bottom: 825px;
    margin-top: 300px;
  }
}
@media (max-width: 599px) {
  .promo-footer__content {
    margin-top: 230px;
    margin-bottom: 460px;
  }
}
.promo-footer__title {
  color: #ffffff;
  text-align: center;
  z-index: 3;
  position: relative;
  letter-spacing: 1px;
  text-shadow: 3px 5px 0px rgba(31, 38, 46, 0.75);
}
@media (max-width: 991.98px) {
  .promo-footer__title {
    text-shadow: 2px 4px 0px rgba(31, 38, 46, 0.75);
  }
}
@media (max-width: 767.98px) {
  .promo-footer__title {
    text-shadow: 2px 3px 0px rgba(31, 38, 46, 0.75);
  }
}
.promo-footer__text {
  font-family: "RobotoCondensed-Regular";
  font-size: 22px;
  line-height: 122%;
  text-shadow: 3px 5px 0px rgba(31, 38, 46, 0.75);
  color: #ffffff;
  text-align: center;
  margin: 0 auto;
  z-index: 11;
  position: relative;
  padding-top: 10px;
  letter-spacing: 0px;
}
@media (max-width: 991.98px) {
  .promo-footer__text {
    font-size: 16px;
    text-shadow: 3px 4px 0px rgba(31, 38, 46, 0.75);
  }
}
@media (max-width: 767.98px) {
  .promo-footer__text {
    text-shadow: 2px 3px 0px rgba(31, 38, 46, 0.75);
  }
}
@media (max-width: 599px) {
  .promo-footer__text {
    padding-bottom: 50px;
    max-width: 250px;
  }
}
@media (max-width: 479.98px) {
  .promo-footer__text {
    letter-spacing: -0.8px;
  }
}

.booking {
  position: relative;
  z-index: 10;
  padding: 20px 0 12px;
  height: 90px;
  max-width: 2560px;
  width: 100%;
  margin-left: auto;
  margin-right: auto;
}
@media (max-width: 859px) {
  .booking {
    background-color: transparent !important;
  }
}
@media (max-width: 767.98px) {
  .booking {
    height: 65px;
  }
}
.booking__content {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  position: relative;
}
@media (max-width: 1378px) {
  .booking__content {
    justify-content: center;
  }
}
@media (max-width: 767.98px) {
  .booking__content {
    flex-direction: column;
  }
}
.booking__text {
  flex: 0 0 20%;
  font-family: "Frutiger-Bold";
  text-transform: uppercase;
  font-size: 26px;
  line-height: 26px;
  color: #ffffff;
  max-width: 300px;
  position: relative;
  padding-left: 160px;
}
@media (max-width: 1919px) {
  .booking__text {
    font-size: calc(
      18px + 8 * ((100vw - 320px) / 1600)
    );
  }
}
@media (max-width: 1378px) {
  .booking__text {
    padding-left: 60px;
  }
}
@media (max-width: 1199px) {
  .booking__text {
    display: none;
  }
}
@media (max-width: 767.98px) {
  .booking__text {
    max-width: 100%;
    margin-left: auto;
    margin-right: auto;
  }
}
.booking__text::before {
  content: "";
  background-image: url("../img/icons/check.svg");
  background-repeat: no-repeat;
  background-size: 100%;
  background-position: center center;
  display: block;
  width: 40px;
  height: 40px;
  position: absolute;
  padding: 10px;
  right: 155px;
  top: 50%;
  transform: translateY(-50%);
}
@media (max-width: 1378px) {
  .booking__text::before {
    left: 0;
  }
}
@media (max-width: 767.98px) {
  .booking__text::before {
    left: 0px;
  }
}
.booking__calendar {
  flex: 0 0 69.5%;
  align-self: flex-end;
  margin-top: -378px;
  position: relative;
  transition: all 0.2s ease 0s;
  width: 100%;
}
@media (max-width: 1378px) {
  .booking__calendar {
    padding-left: 30px;
  }
}
@media (max-width: 1199px) {
  .booking__calendar {
    padding: 0;
    flex: 0 0 100%;
  }
}
@media (max-width: 859px) {
  .booking__calendar {
    margin-left: auto;
    margin-right: auto;
    margin-top: -365px;
  }
}
@media (max-width: 767.98px) {
  .booking__calendar {
    position: absolute;
    bottom: 27px;
    padding-left: 0;
    left: 50%;
    transform: translateX(-50%);
  }
}
@media (max-width: 599px) {
  .booking__calendar {
    bottom: -8px;
    padding-left: 15px;
    padding-right: 15px;
  }
}
@media (max-width: 479.98px) {
  .booking__calendar {
    bottom: -4px;
  }
}
.booking__calendar img {
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  width: 100%;
  object-fit: contain;
}
@media (max-width: 859px) {
  .booking__calendar img {
    width: auto;
    max-width: 568px;
  }
}
@media (max-width: 599px) {
  .booking__calendar img {
    max-width: 300px;
  }
}
.booking__calendar::after {
  content: "";
  display: block;
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  bottom: -25px;
  z-index: -1;
  padding: 20px 0 12px;
  height: 95px;
  width: 1000%;
  transition: all 0.2s ease 0s;
}
@media (max-width: 859px) {
  .booking__calendar::after {
    height: 150px;
    width: 150%;
  }
}
@media (max-width: 599px) {
  .booking__calendar::after {
    height: 90px;
    width: 200%;
  }
}

.colors {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 12px 75px 10px 10px;
  background-color: #fff;
  border-top-left-radius: 10px;
  border-bottom-left-radius: 10px;
  position: fixed;
  right: 0;
  top: 65%;
  z-index: 9;
  transform: translateX(93.2%);
  box-shadow: 0px 1px 3px 0px #646c77;
  transition: all 0.8s ease 0.2s;
}
@media (max-width: 580px) {
  .colors__secondRow {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 4px 5px 6px 10px;
    background-color: #fff;
    border-bottom-left-radius: 10px;
    box-shadow: 0 2px 2px 0 rgba(100, 108, 119, 0.7);
    position: fixed;
    right: 0;
    top: 340px;
    z-index: 9;
    transform: translateX(100%);
    transition: all 0.8s ease 0.2s;
    width: 293px;
  }
}
@media (max-width: 580px) and (max-width: 380px) {
  .colors__secondRow {
    transition: all 0.8s ease 0.2s;
  }
}
@media (max-width: 580px) {
  .colors__secondRow.showColors {
    transition: all 0.8s ease 0s;
    transform: translateX(0);
  }
}
@media (max-width: 580px) and (max-width: 380px) {
  .colors__secondRow.showColors {
    transition: all 0.8s ease 0s;
  }
}
@media (max-width: 1378px) {
  .colors {
    transform: translateX(93.8%);
    padding-right: 55px;
  }
}
@media (max-width: 991.98px) {
  .colors {
    padding-top: 10px;
    padding-bottom: 8px;
    padding-right: 45px;
    transform: translateX(93.4%);
  }
}
@media (max-width: 820px) {
  .colors {
    padding-top: 8px;
    padding-bottom: 6px;
    padding-right: 30px;
    transform: translateX(93.5%);
  }
}
@media (max-width: 580px) {
  .colors {
    transform: translateX(283px);
    width: 320px;
    padding-left: 5px;
    padding-right: 36px;
    top: 300px;
  }
}
.colors__settings {
  position: relative;
  width: 58px;
  background-color: transparent;
  margin: 0 10px;
}
@media (max-width: 1366px) {
  .colors__settings {
    width: 50px;
    margin: 0 5px;
  }
}
@media (max-width: 991.98px) {
  .colors__settings {
    width: 40px;
  }
}
@media (max-width: 820px) {
  .colors__settings {
    width: 30px;
    margin-left: 0;
  }
}
@media (max-width: 580px) {
  .colors__settings {
    width: 30px;
    margin-left: 5px;
  }
}
.colors__settings i {
  position: relative;
  display: block;
  background-image: url("../img/icons/rainbow_gear.svg");
  background-repeat: no-repeat;
  background-position: center center;
  background-size: contain;
  display: block;
  width: 46px;
  height: 49px;
  cursor: pointer;
  transition: all 0.8s ease 0s;
  transform: rotate(0);
  margin: 0 auto;
}
@media (max-width: 1378px) {
  .colors__settings i {
    width: 40px;
    height: 40px;
  }
}
@media (max-width: 991.98px) {
  .colors__settings i {
    width: 34px;
    height: 34px;
  }
}
@media (max-width: 820px) {
  .colors__settings i {
    width: 22px;
    height: 22px;
  }
}
.colors__btn {
  width: 55px;
  height: 55px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0 3.5px;
  transition: all 0.2s ease 0s;
  position: relative;
}
.colors__btn span {
  position: absolute;
  top: -50px;
  padding: 4px 7px;
  background-color: #f1f2f5;
  display: block;
  border-radius: 5px;
  z-index: 5;
  font-family: "Roboto";
  font-weight: 700;
  font-size: 14px;
  opacity: 0;
  visibility: hidden;
  transition: all 0.3s ease 0s;
}
@media (max-width: 580px) {
  .colors__btn span {
    top: -28px;
  }
}
@media (max-width: 580px) {
  .colors__btn span {
    display: none;
  }
}
.colors__btn span::after {
  content: "";
  height: 10px;
  width: 10px;
  background: #f1f2f5;
  transform: rotate(45deg) translateX(-50%);
  position: absolute;
  bottom: -8px;
  left: 50%;
}
.colors__btn:hover span {
  opacity: 1;
  visibility: visible;
}
@media (max-width: 1378px) {
  .colors__btn {
    flex: 0 0 48px;
    width: 48px;
    height: 48px;
  }
  .colors__btn svg {
    width: 48px;
    height: 48px;
  }
}
@media (max-width: 991.98px) {
  .colors__btn {
    flex: 0 0 38px;
    width: 38px;
    height: 38px;
  }
  .colors__btn svg {
    width: 38px;
    height: 38px;
  }
}
@media (max-width: 820px) {
  .colors__btn {
    flex: 0 0 28px;
    width: 28px;
    height: 28px;
    margin: 0 2.5px;
  }
  .colors__btn svg {
    width: 28px;
    height: 28px;
  }
}
.colors__btn--active {
  transition: all 0.2s ease 0s;
  cursor: default;
}
.colors__btn--active svg {
  width: 46px;
  height: 46px;
}
@media (max-width: 1378px) {
  .colors__btn--active {
    flex: 0 0 48px;
    width: 40px;
    height: 40px;
  }
  .colors__btn--active svg {
    width: 40px;
    height: 40px;
  }
}
@media (max-width: 991.98px) {
  .colors__btn--active {
    flex: 0 0 38px;
    width: 34px;
    height: 34px;
  }
  .colors__btn--active svg {
    width: 34px;
    height: 34px;
  }
}
@media (max-width: 820px) {
  .colors__btn--active {
    flex: 0 0 28px;
    width: 22px;
    height: 22px;
  }
  .colors__btn--active svg {
    width: 22px;
    height: 22px;
  }
}
.colors.showColors {
  transition: all 0.8s ease 0s;
  transform: translateX(0);
}
@media (max-width: 580px) {
  .colors.showColors {
    transform: translateX(0);
  }
}

.button-group {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  flex: 0 0 50%;
  max-width: 50%;
}
@media (max-width: 580px) {
  .button-group {
    flex: 0 0 100%;
    max-width: 100%;
  }
}

.showColors .colors__settings i {
  transform: rotate(-360deg);
}

.svg-icon {
  display: flex;
  align-items: center;
  justify-content: center;
  fill: currentColor;
  position: relative;
  flex: 0 0 auto;
  width: 55px;
  height: 55px;
  transition: all 0.3s ease 0s;
}
.svg-icon:hover {
  width: 46px;
  height: 46px;
}
@media (max-width: 1378px) {
  .svg-icon:hover {
    width: 40px;
    height: 40px;
  }
  .svg-icon:hover svg {
    width: 40px;
    height: 40px;
  }
}
@media (max-width: 991.98px) {
  .svg-icon:hover {
    width: 34px;
    height: 34px;
  }
  .svg-icon:hover svg {
    width: 34px;
    height: 34px;
  }
}
@media (max-width: 820px) {
  .svg-icon:hover {
    width: 22px;
    height: 22px;
  }
  .svg-icon:hover svg {
    width: 22px;
    height: 22px;
  }
}

.gray-color {
  fill: #646c77;
}

.jeans-color {
  fill: #536f8a;
}

.gold-color {
  fill: #806834;
}

.grapefruit-color {
  fill: #dd1458;
}

.peper-color {
  fill: #d52b1e;
}

.leather-color {
  fill: #c57539;
}

.tangerin-color {
  fill: #ff7900;
}

.mustard-color {
  fill: #eea900;
}

.lime-color {
  fill: #b2bf14;
}

.grass-color {
  fill: #58a11a;
}

.mint-color {
  fill: #199967;
}

.laguna-color {
  fill: #018c9e;
}

.sky-color {
  fill: #038ccd;
}

.sea-color {
  fill: #3e50b4;
}

.lavender-color {
  fill: #6e39c5;
}

.clover-color {
  fill: #c5399b;
}

.blogPage-topheader .menu__list-item:last-child {
  margin-top: 0;
}

.blogPage-header {
  margin-top: 73px;
  padding: 42px 0 15px !important;
  height: 145px;
  min-height: 0;
  background-image: url("../img/blog-header.jpg") !important;
  background-repeat: no-repeat;
  background-size: auto;
  background-position: center center;
  max-width: 2560px;
  width: 100%;
  margin-left: auto;
  margin-right: auto;
}
@media (min-width: 1921px) {
  .blogPage-header {
    padding-bottom: 10px;
  }
}
@media (max-width: 1378px) {
  .blogPage-header {
    height: 135px;
    margin-top: 58px;
  }
}
@media (max-width: 1199px) {
  .blogPage-header {
    height: 125px;
    margin-top: 46px;
  }
}
@media (max-width: 991.98px) {
  .blogPage-header {
    margin-top: 50px;
  }
}
@media (max-width: 767.98px) {
  .blogPage-header {
    height: 115px;
  }
}
@media (max-width: 601px) {
  .blogPage-header {
    margin-top: 50px !important;
  }
}
@media (max-width: 479.98px) {
  .blogPage-header {
    height: 105px;
    margin-top: 30px !important;
  }
}
@media (max-width: 401px) {
  .blogPage-header {
    margin-top: 30px;
  }
}
.blogPage-header::before {
  background-position: center center;
}
.blogPage-header ._container {
  justify-content: center;
}
.blogPage-header .promo-header__title {
  text-transform: uppercase;
  font-family: "YanoneKaffeesatz-Bold";
  letter-spacing: -2px;
  line-height: 130%;
  font-size: 72px;
}
@media (max-width: 1378px) {
  .blogPage-header .promo-header__title {
    font-size: 66px;
  }
}
@media (max-width: 1200px) {
  .blogPage-header .promo-header__title {
    font-size: 60px;
  }
}
@media (max-width: 991.98px) {
  .blogPage-header .promo-header__title {
    font-size: 58px;
    text-align: center;
  }
}
@media (max-width: 767.98px) {
  .blogPage-header .promo-header__title {
    font-size: 48px;
    text-shadow: 3px 4px 0px rgba(31, 38, 46, 0.75);
  }
}
@media (max-width: 479.98px) {
  .blogPage-header .promo-header__title {
    letter-spacing: 0.95px;
    font-size: 38px;
    text-shadow: 3px 3px 0px rgba(31, 38, 46, 0.75);
  }
}
.blogPage-header .htmlTags {
  text-transform: none;
}

@media (max-width: 1199px) {
  .header._scroll + .blogPage-header {
    margin-top: 50px;
  }
}
@media (max-width: 991.98px) {
  .header._scroll + .blogPage-header {
    margin-top: 60px;
  }
}
@media (max-width: 479.98px) {
  .header._scroll + .blogPage-header {
    margin-top: 20px;
  }
}

.booking__calendar-box {
  position: absolute;
  bottom: -14px;
  opacity: 0;
  visibility: hidden;
}
@media (max-width: 1199px) {
  .booking__calendar-box {
    width: 80%;
    left: 50%;
    transform: translateX(-50%);
  }
}
@media (max-width: 991.98px) {
  .booking__calendar-box {
    width: 100%;
  }
}
@media (max-width: 859px) {
  .booking__calendar-box {
    width: 70%;
    margin-left: -22px;
  }
}
@media (max-width: 767.98px) {
  .booking__calendar-box {
    width: auto;
    margin-left: 0;
  }
}

.promo-footer.gray-calendar .slide-gray,
.promo-footer.gray-calendar .gray {
  opacity: 1;
  visibility: visible;
}
.promo-footer.gray-calendar .booking__calendar:after {
  background-color: rgba(100, 108, 119, 0.85);
}

.promo-footer.jeans-calendar .slide-jeans,
.promo-footer.jeans-calendar .jeans {
  opacity: 1;
  visibility: visible;
}
.promo-footer.jeans-calendar .booking__calendar:after {
  background-color: rgba(83, 111, 138, 0.85);
}

.promo-footer.gold-calendar .slide-gold,
.promo-footer.gold-calendar .gold {
  opacity: 1;
  visibility: visible;
}
.promo-footer.gold-calendar .booking__calendar:after {
  background-color: rgba(128, 104, 52, 0.85);
}

.promo-footer.grapefruit-calendar .slide-grapefruit,
.promo-footer.grapefruit-calendar .grapefruit {
  opacity: 1;
  visibility: visible;
}
.promo-footer.grapefruit-calendar .booking__calendar:after {
  background-color: rgba(221, 20, 88, 0.85);
}

.promo-footer.peper-calendar .slide-peper,
.promo-footer.peper-calendar .peper {
  opacity: 1;
  visibility: visible;
}
.promo-footer.peper-calendar .booking__calendar:after {
  background-color: rgba(213, 43, 30, 0.85);
}

.promo-footer.leather-calendar .slide-leather,
.promo-footer.leather-calendar .leather {
  opacity: 1;
  visibility: visible;
}
.promo-footer.leather-calendar .booking__calendar:after {
  background-color: rgba(197, 117, 57, 0.85);
}

.promo-footer.tangerin-calendar .slide-tangerin,
.promo-footer.tangerin-calendar .tangerin {
  opacity: 1;
  visibility: visible;
}
.promo-footer.tangerin-calendar .booking__calendar:after {
  background-color: rgba(255, 121, 0, 0.85);
}

.promo-footer.mustard-calendar .slide-mustard,
.promo-footer.mustard-calendar .mustard {
  opacity: 1;
  visibility: visible;
}
.promo-footer.mustard-calendar .booking__calendar:after {
  background-color: rgba(238, 169, 0, 0.85);
}

.promo-footer.lime-calendar .slide-lime,
.promo-footer.lime-calendar .lime {
  opacity: 1;
  visibility: visible;
}
.promo-footer.lime-calendar .booking__calendar:after {
  background-color: rgba(178, 191, 20, 0.85);
}

.promo-footer.grass-calendar .slide-grass,
.promo-footer.grass-calendar .grass {
  opacity: 1;
  visibility: visible;
}
.promo-footer.grass-calendar .booking__calendar:after {
  background-color: rgba(88, 161, 26, 0.85);
}

.promo-footer.mint-calendar .slide-mint,
.promo-footer.mint-calendar .mint {
  opacity: 1;
  visibility: visible;
}
.promo-footer.mint-calendar .booking__calendar:after {
  background-color: rgba(25, 153, 103, 0.85);
}

.promo-footer.laguna-calendar .slide-laguna,
.promo-footer.laguna-calendar .laguna {
  opacity: 1;
  visibility: visible;
}
.promo-footer.laguna-calendar .booking__calendar:after {
  background-color: rgba(1, 140, 158, 0.85);
}

.promo-footer.sky-calendar .slide-sky,
.promo-footer.sky-calendar .sky {
  opacity: 1;
  visibility: visible;
}
.promo-footer.sky-calendar .booking__calendar:after {
  background-color: rgba(3, 140, 205, 0.85);
}

.promo-footer.sea-calendar .slide-sea,
.promo-footer.sea-calendar .sea {
  opacity: 1;
  visibility: visible;
}
.promo-footer.sea-calendar .booking__calendar:after {
  background-color: rgba(62, 80, 180, 0.85);
}

.promo-footer.lavender-calendar .slide-lavender,
.promo-footer.lavender-calendar .lavender {
  opacity: 1;
  visibility: visible;
}
.promo-footer.lavender-calendar .booking__calendar:after {
  background-color: rgba(110, 57, 197, 0.85);
}

.promo-footer.clover-calendar .slide-clover,
.promo-footer.clover-calendar .clover {
  opacity: 1;
  visibility: visible;
}
.promo-footer.clover-calendar .booking__calendar:after {
  background-color: rgba(197, 57, 155, 0.85);
}

.footer {
  background-color: #273646;
  padding: 118px 0 60px;
  max-width: 2560px;
  width: 100%;
  margin-left: auto;
  margin-right: auto;
  position: relative;
  z-index: 3;
}
.footer__content {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
}
.footer__column {
  flex: 0 0 20.8%;
  padding-bottom: 50px;
}
.footer__column:first-of-type {
  flex: 0 0 37.6%;
}
@media (max-width: 1378px) {
  .footer__column:first-of-type {
    flex: 0 0 300px;
  }
}
@media (max-width: 1199px) {
  .footer__column:first-of-type {
    flex: 0 0 262px;
  }
}
.footer__menu-column {
  padding: 0 5px 50px 5px;
}
@media (max-width: 991.98px) {
  .footer__menu-column {
    flex: 0 0 50%;
  }
}
@media (max-width: 479.98px) {
  .footer__menu-column {
    flex: 0 0 100%;
  }
}
.footer__logo {
  width: 362px;
}
@media (max-width: 1378px) {
  .footer__logo {
    max-width: 300px;
  }
  .footer__logo img {
    width: 100%;
  }
}
@media (max-width: 475px) {
  .footer__logo {
    max-width: 200px;
  }
}
.footer__contacts {
  padding-top: 10px;
  padding-left: 27px;
  margin-top: 12px;
}
.footer__contact-label {
  font-size: 15px;
  line-height: 34px;
  font-weight: 400;
  color: #d5dde5;
  display: inline-block;
  min-width: 65px;
}
.footer__contact-item {
  display: inline-block;
  font-size: 15px;
  line-height: 34px;
  font-weight: 400;
  color: #9ba7b2;
}
.footer__social {
  padding-top: 25px;
  padding-left: 27px;
  width: 170px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.footer__social-item {
  height: 20px;
  flex: 0 0 20px;
  display: block;
  font-size: 20px;
  color: #9ba7b2;
  transition: all 0.3s ease 0s;
}
.footer__social-item:hover {
  color: #f4de4b;
}
.footer__social-item::before {
  content: "";
  height: 23px;
  width: auto;
  display: block;
  transition: all 0.3s ease 0s;
}
.footer__menu-title {
  font-family: "RobotoCondensed-Regular";
  font-size: 26px;
  line-height: 41px;
  font-weight: 400;
  color: #d5dde5;
  text-transform: uppercase;
  position: relative;
  padding-bottom: 10px;
  padding-left: 20px;
}
@media (max-width: 1378px) {
  .footer__menu-title {
    font-size: 22px;
  }
}
.footer__menu-title::before, .footer__menu-title::after {
  content: "";
  width: 90%;
  height: 2px;
  display: block;
  position: absolute;
  left: 0;
}
.footer__menu-title::before {
  background-color: #1f262e;
  bottom: 0;
}
.footer__menu-title::after {
  background-color: #415161;
  bottom: -2px;
}
.footer__menu-title span {
  width: 12px;
  height: 12px;
  border-radius: 50%;
  background-color: #f4de4b;
  display: block;
  position: absolute;
  left: 0;
  top: 40%;
  transform: translateY(-50%);
  opacity: 0;
  visibility: hidden;
  transition: all 0.3s ease 0s;
}
.footer__menu-list {
  padding-left: 20px;
  padding-top: 20px;
}
.footer__demo-list {
  padding-left: 0;
}
.footer__menu-link {
  font-size: 18px;
  line-height: 113%;
  font-weight: 400;
  color: #9ba7b2;
  transition: all 0.3s ease 0s;
}
@media (max-width: 1378px) {
  .footer__menu-link {
    font-size: 16px;
  }
}
.footer__menu-link:hover {
  color: #f4de4b;
}

.active-dot {
  opacity: 1 !important;
  visibility: visible !important;
}

.footer__demo-list > li {
  padding-left: 20px;
}
.footer__demo-list > li:last-child {
  padding-left: 0;
}

.footer__menu-list > li > a:hover + .footer__menu-title {
  color: red;
}

.demo-link {
  color: #18ad8f;
  border: 1px solid #18ad8f;
  text-transform: none;
  padding: 10px 10px 10px 18px;
  display: block;
  max-width: 235px;
  width: 100%;
}
.demo-link:hover {
  background-color: #18ad8f;
  color: #fff;
}

.offers-link {
  color: #ff7900;
  padding-left: 25px;
  position: relative;
  transition: all 0.3s ease 0s;
}
.offers-link:hover {
  color: #f4de4b;
}
.offers-link:hover:before {
  background: url("../img/icons/thumbs-up-yellow.svg") center/contain no-repeat;
}
.offers-link::before {
  content: "";
  width: 20px;
  height: 20px;
  background: url("../img/icons/thumbs-up-orange.svg") center/contain no-repeat;
  display: block;
  position: absolute;
  left: -5px;
  top: -2px;
  transition: all 0.3s ease 0s;
}

.instagram::before {
  background: url("../img/icons/instagram.svg") center/contain no-repeat;
}
.instagram:hover:before {
  background: url("../img/icons/instagram-yellow.svg") center/contain no-repeat;
}

.youtube::before {
  background: url("../img/icons/youtube.svg") center/contain no-repeat;
}
.youtube:hover:before {
  background: url("../img/icons/youtube-yellow.svg") center/contain no-repeat;
}

.facebook::before {
  background: url("../img/icons//facebook.svg") center/contain no-repeat;
}
.facebook:hover:before {
  background: url("../img/icons/facebook-yellow.svg") center/contain no-repeat;
}

.whatsapp::before {
  background: url("../img/icons/whatsapp.svg") center/contain no-repeat;
}
.whatsapp:hover:before {
  background: url("../img/icons/whatsapp-yellow.svg") center/contain no-repeat;
}

.copyright {
  background-image: url("../img/footer-bg.jpg");
  background-repeat: no-repeat;
  background-position: bottom center;
  width: 100%;
  height: 74px;
  max-width: 2560px;
  width: 100%;
  margin-left: auto;
  margin-right: auto;
  position: relative;
}
@media (max-width: 700px) {
  .copyright {
    background: url("../img/footer-bg.jpg") center/cover no-repeat;
    height: 100px;
  }
}
@media (max-width: 417px) {
  .copyright {
    padding: 7px 0 5px;
    height: 140px;
  }
}
.copyright__row {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-top: 20px;
  position: relative;
  z-index: 3;
}
@media (max-width: 700px) {
  .copyright__row {
    flex-direction: column;
    padding-top: 10px;
  }
}
.copyright__text {
  font-size: 14px;
  font-weight: 400;
  color: #ffffff;
}
.copyright__list {
  display: flex;
  align-items: center;
}
@media (max-width: 417px) {
  .copyright__list {
    flex-direction: column;
  }
}
.copyright__list li a {
  font-size: 14px;
  font-weight: 400;
  color: #ffffff;
  padding: 0 15px;
  transition: all 0.3s ease 0s;
  position: relative;
}
.copyright__list li a::after {
  content: "";
  width: 0;
  height: 1px;
  display: block;
  position: absolute;
  bottom: -5px;
  left: 50%;
  transform: translateX(-50%);
  transition: all 0.3s ease 0s;
}
.copyright__list li a:hover {
  color: #f4de4b;
  transition: all 0.3s ease 0s;
}
.copyright__list li a:hover:after {
  width: 88%;
  background-color: #f4de4b;
}
.copyright__list li:first-of-type a {
  border-right: 1px solid #fff;
}
@media (max-width: 417px) {
  .copyright__list li:first-of-type a {
    border: 0;
  }
}

.section-module {
  background-color: #fff;
  padding: 100px 0 10px;
  position: relative;
  z-index: 9;
}
@media (max-width: 479.98px) {
  .section-module {
    padding-top: 28px;
  }
}
.section-module__title {
  text-align: center;
  line-height: 36px;
  color: #273646;
  line-height: 62px !important;
}
.section-module__subtitle {
  text-align: center;
  color: #4e6677;
}
.section-module__text {
  max-width: 845px;
  line-height: 18;
  margin: 0 auto;
  padding-top: 20px;
  color: #394a58;
  text-align: center;
}
@media (max-width: 479.98px) {
  .section-module__text {
    padding-top: 3px;
  }
}
.section-module__link {
  font-family: "RobotoCondensed-Regular";
  font-size: 24px;
  color: #3067bb;
  display: block;
  margin: 20px auto 0;
  text-align: center;
  position: relative;
  transition: all 0.3s ease 0s;
}
@media (max-width: 1378px) {
  .section-module__link {
    font-size: 20px;
  }
}
@media (max-width: 991.98px) {
  .section-module__link {
    font-size: 18px;
  }
}
@media (max-width: 767.98px) {
  .section-module__link {
    font-size: 16px;
  }
}
@media (max-width: 479.98px) {
  .section-module__link {
    margin-top: 5px;
    letter-spacing: -0.5px;
  }
}
.section-module__link::after {
  content: "";
  width: 8%;
  height: 1px;
  background-color: #3067bb;
  display: block;
  position: absolute;
  bottom: 3px;
  left: 50%;
  transform: translateX(-50%);
  transition: all 0.3s ease 0s;
}
@media (max-width: 1378px) {
  .section-module__link::after {
    width: 9%;
  }
}
@media (max-width: 991.98px) {
  .section-module__link::after {
    width: 10%;
  }
}
@media (max-width: 767.98px) {
  .section-module__link::after {
    width: 11%;
  }
}
.section-module__link:hover {
  color: #dc240a;
}
.section-module__link:hover:after {
  width: 0;
  background-color: #fff;
}
.section-module__btn {
  margin: 40px auto 70px;
}
@media (max-width: 479.98px) {
  .section-module__btn {
    margin-top: 60px;
    margin-bottom: 30px;
  }
}
.section-module__tabs {
  margin: 70px auto 0;
}
@media (max-width: 479.98px) {
  .section-module__tabs {
    margin-top: 15px;
  }
}

.section-possibilities {
  background: #f1f2f5;
  position: relative;
  z-index: 8;
  padding: 70px 0;
}
@media (max-width: 479.98px) {
  .section-possibilities {
    padding: 45px 0 65px;
  }
}
.section-possibilities__title {
  color: #196090;
  text-align: center;
  margin-bottom: 18px;
}
.section-possibilities__row {
  display: flex;
  flex-wrap: nowrap;
  justify-content: center;
  margin-left: 90px;
}
@media (max-width: 1378px) {
  .section-possibilities__row {
    margin-left: 0;
  }
}
@media (max-width: 767.98px) {
  .section-possibilities__row {
    flex-wrap: wrap;
  }
}
.section-possibilities__item {
  max-width: 430px;
  width: 100%;
  margin: 35px 54px;
}
@media (max-width: 991.98px) {
  .section-possibilities__item {
    max-width: 100%;
    margin-left: 20px;
    margin-right: 20px;
  }
}
@media (max-width: 767.98px) {
  .section-possibilities__item {
    margin-left: 0;
    margin-right: 0;
  }
}
@media (max-width: 479.98px) {
  .section-possibilities__item {
    margin-top: 25px;
  }
}
.section-possibilities__btn {
  margin: 40px auto 0px;
}

.crm-item__icon {
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  width: 64px;
  height: 64px;
  border-radius: 11px;
  color: #fff;
}
.crm-item__icon::before {
  content: "";
  display: inline-block;
  background-repeat: no-repeat;
  background-position: center center;
  background-size: contain;
  width: 64px;
  height: 64px;
}
@media (max-width: 479.98px) {
  .crm-item__icon::before {
    width: 48px;
    height: 48px;
  }
}
@media (max-width: 767.98px) {
  .crm-item__icon {
    margin: 0 auto;
  }
}
@media (max-width: 479.98px) {
  .crm-item__icon {
    width: 48px;
    height: 48px;
  }
}
.crm-item__title {
  margin: 25px 0 8px;
  font-family: "RobotoCondensed-Bold";
  font-size: 28px;
  line-height: 100%;
  color: #394058;
  color: #2d3a4c;
}
@media (max-width: 1378px) {
  .crm-item__title {
    font-size: 26px;
  }
}
@media (max-width: 991.98px) {
  .crm-item__title {
    font-size: 24px;
  }
}
@media (max-width: 767.98px) {
  .crm-item__title {
    font-size: 22px;
    text-align: center;
  }
}
@media (max-width: 479.98px) {
  .crm-item__title {
    margin-bottom: 10px;
  }
}
.crm-item__text {
  color: #4e6677;
  line-height: 28px !important;
  margin-bottom: 5px;
}
@media (max-width: 767.98px) {
  .crm-item__text {
    text-align: center;
    line-height: 21px !important;
  }
}
@media (max-width: 767.98px) {
  .crm-item__link-box {
    margin: 0 auto;
    text-align: center;
  }
}
.crm-item__link {
  font-size: 18px;
  line-height: 25px;
  font-weight: 700;
  color: #3067bb;
  position: relative;
  transition: all 0.3s ease 0ms;
}
.crm-item__link:after {
  content: "";
  width: 100%;
  background-color: #3067bb;
  height: 1px;
  display: block;
  position: absolute;
  bottom: 0;
  left: 50%;
  transform: translateX(-50%);
  transition: all 0.3s ease 0ms;
}
.crm-item__link:hover {
  color: #dc240a;
}
.crm-item__link:hover:after {
  width: 0;
  background-color: #fff;
}
@media (max-width: 767.98px) {
  .crm-item__link {
    text-align: center;
  }
}
@media (max-width: 479.98px) {
  .crm-item__link {
    font-size: 15px;
  }
}

.management-icon::before {
  background-image: url("../img/icons/quotes.svg");
}

.analitycs-icon::before {
  background-image: url("../img/icons/analytics.svg");
}

.services-icon::before {
  background-image: url("../img/icons/tarifs.svg");
}

.reports-icon::before {
  background-image: url("../img/icons/docs.svg");
}

.funds-icon::before {
  background-image: url("../img/icons/numbers.svg");
}

.settings-icon::before {
  background-image: url("../img/icons/setting.svg");
}

/* custom icon */
.free-icon::before {
  background-image: url("../img/icons/free.svg");
}

.prices-icon::before {
  background-image: url("../img/icons/prices.svg");
}

.friend_interface-icon::before {
  background-image: url("../img/icons/friend_interface.svg");
}

.speed-icon::before {
  background-image: url("../img/icons/speed.svg");
}

.credit_card-icon::before {
  background-image: url("../img/icons/credit_card.svg");
}

.qr_icon-icon::before {
  background-image: url("../img/icons/qr_icon.svg");
}

.cash-icon::before {
  background-image: url("../img/icons/cash.svg");
}

.print_check-icon::before {
  background-image: url("../img/icons/print_check.svg");
}

.adaptive-icon::before {
  background-image: url("../img/icons/adaptive.svg");
}

.logic-icon::before {
  background-image: url("../img/icons/logic.svg");
}

.domain-icon::before {
  background-image: url("../img/icons/domain.svg");
}

.analytic-icon::before {
  background-image: url("../img/icons/analytic.svg");
}

.seo-icon::before {
  background-image: url("../img/icons/seo.svg");
}

.crm-icon::before {
  background-image: url("../img/icons/crm.svg");
}

.host-icon::before {
  background-image: url("../img/icons/host.svg");
}

.social-icon::before {
  background-image: url("../img/icons/social.svg");
}

/* end custom icon  */
.section-price {
  background-image: url("../img/new-price-bg.jpg");
  background-repeat: no-repeat;
  background-size: auto;
  padding: 70px 0 40px;
  overflow: hidden;
  position: relative;
  z-index: 0;
}
@media (max-width: 479.98px) {
  .section-price {
    padding: 45px 0 20px;
  }
}
.section-price__content {
  position: relative;
  z-index: 3;
}
.section-price__title {
  text-align: center;
  color: #fff;
  line-height: 36px !important;
}
.section-price__subtitle {
  margin-top: 10px;
  text-align: center;
  color: #fff;
  line-height: 36px !important;
}
@media (max-width: 479.98px) {
  .section-price__subtitle {
    margin-top: 4px;
    line-height: 20px !important;
  }
}
.section-price__tab {
  margin: 48px auto 0;
}
@media (max-width: 479.98px) {
  .section-price__tab {
    margin: 38px auto 0;
  }
}
.section-price__link {
  text-align: center;
  display: block;
  margin: 30px auto 0;
  font-size: 18px;
  line-height: 25px;
  font-weight: 700;
  color: #f4de4b;
  position: relative;
  transition: all 0.3s ease 0ms;
}
.section-price__link:after {
  content: "";
  width: 7%;
  background-color: #f4de4b;
  height: 1px;
  display: block;
  position: absolute;
  bottom: 0;
  left: 50%;
  transform: translateX(-50%);
  transition: all 0.3s ease 0ms;
}
@media (max-width: 1378px) {
  .section-price__link:after {
    width: 9.5%;
  }
}
@media (max-width: 991.98px) {
  .section-price__link:after {
    width: 12.5%;
  }
}
@media (max-width: 767.98px) {
  .section-price__link:after {
    width: 14.5%;
  }
}
@media (max-width: 575px) {
  .section-price__link:after {
    width: 18.5%;
  }
}
@media (max-width: 479.98px) {
  .section-price__link:after {
    width: 25.5%;
  }
}
.section-price__link:hover {
  color: #fff;
}
.section-price__link:hover:after {
  width: 0;
  background-color: #fff;
}
@media (max-width: 479.98px) {
  .section-price__link {
    font-size: 15px;
  }
}
.section-price .ruble {
  display: none;
}
@media (max-width: 479.98px) {
  .section-price .ruble {
    display: inline;
  }
}
.section-price .ruble__text {
  display: inline;
}
@media (max-width: 479.98px) {
  .section-price .ruble__text {
    display: none;
  }
}

.price-tab {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  max-width: 758px;
  width: 100%;
}
.price-tab__input:focus + .price-tab__label {
  z-index: 1;
}
.price-tab__input:checked + .price-tab__label {
  background: #fff;
  color: #199967;
}
.price-tab__label {
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 15px;
  text-transform: uppercase;
  color: #ffffff;
  border: 1px solid #fff;
  border-bottom: none;
  max-width: 211px;
  width: 100%;
  padding: 5px 10px 2px;
  cursor: pointer;
  letter-spacing: -0.7px;
  transition: background 0.1s, color 0.1s;
}
@media (max-width: 767.98px) {
  .price-tab__label {
    font-size: 14px;
    white-space: pre-wrap;
    text-align: center;
    flex: 0 0 30.2%;
    padding: 5px 5px 2px;
    letter-spacing: 0.2px;
  }
}
@media (max-width: 600px) {
  .price-tab__label {
    padding: 8px 15px 3px;
    font-size: 13px;
    line-height: 16px;
  }
}
@media (max-width: 450px) {
  .price-tab__label {
    flex: 0 0 127px;
  }
}
.price-tab__label:hover {
  background: #fff;
  color: #199967;
}
.price-tab__label:active {
  background: #fff;
  color: #199967;
}
.price-tab__panel {
  display: none;
  background: #fff;
  color: #199967;
  padding: 76px 60px 18px;
  border-radius: 5px;
  width: 100%;
  order: 99;
  margin-top: -1px;
}
@media (max-width: 767.98px) {
  .price-tab__panel {
    border-top-left-radius: 0;
    border-top-right-radius: 0;
  }
}
@media (max-width: 479.98px) {
  .price-tab__panel {
    padding: 40px 10px 10px;
  }
}
.price-tab__panel p {
  font-size: 36px;
  line-height: 120%;
  font-weight: 700;
  color: #199967;
  text-align: center;
  text-transform: uppercase;
}
@media (max-width: 1378px) {
  .price-tab__panel p {
    font-size: 32px;
  }
}
@media (max-width: 991.98px) {
  .price-tab__panel p {
    font-size: 28px;
  }
}
@media (max-width: 767.98px) {
  .price-tab__panel p {
    font-size: 24px;
  }
}
.price-tab__panel p:first-child {
  border-bottom: 1px solid #199967;
  padding-bottom: 18px;
}
@media (max-width: 479.98px) {
  .price-tab__panel p:first-child {
    padding-bottom: 0px;
  }
}
.price-tab__panel p:last-child {
  padding-top: 24px;
  padding-bottom: 35px;
}
@media (max-width: 1378px) {
  .price-tab__panel p:last-child {
    font-size: 32px;
  }
}
@media (max-width: 991.98px) {
  .price-tab__panel p:last-child {
    font-size: 28px;
  }
}
@media (max-width: 479.98px) {
  .price-tab__panel p:last-child {
    font-size: 20px;
    padding-top: 15px;
  }
}
.price-tab__panel p span {
  font-size: 48px;
  line-height: 72px;
}
@media (max-width: 1378px) {
  .price-tab__panel p span {
    font-size: 42px;
  }
}
@media (max-width: 991.98px) {
  .price-tab__panel p span {
    font-size: 36px;
  }
}
@media (max-width: 767.98px) {
  .price-tab__panel p span {
    font-size: 30px;
  }
}
.price-tab__panel p span:first-child {
  font-size: 100px;
  padding: 0 22px 0 22px;
}
@media (max-width: 1378px) {
  .price-tab__panel p span:first-child {
    font-size: 85px;
  }
}
@media (max-width: 991.98px) {
  .price-tab__panel p span:first-child {
    font-size: 70px;
  }
}
@media (max-width: 767.98px) {
  .price-tab__panel p span:first-child {
    font-size: 55px;
  }
}
.price-tab__span {
  font-size: 14px;
  line-height: 28px;
  font-style: italic;
  color: #ffffff;
  display: flex;
  align-items: center;
  margin: 0 10px;
}
@media (max-width: 450px) {
  .price-tab__span {
    font-size: 0;
  }
}

.price-tab__input:checked + .price-tab__label + .price-tab__panel {
  display: block;
}

.section-rent {
  background-color: #fff;
  padding: 100px 0 50px;
  position: relative;
  z-index: 7;
}
@media (max-width: 479.98px) {
  .section-rent {
    padding: 30px 0 45px;
  }
}
.section-rent__row {
  display: flex;
  justify-content: space-between;
}
@media (max-width: 1378px) {
  .section-rent__row {
    align-items: center;
  }
}
@media (max-width: 991.98px) {
  .section-rent__row {
    flex-direction: column;
  }
}
.section-rent__column:first-of-type {
  flex: 0 0 500px;
  padding: 50px 0 0 50px;
}
@media (max-width: 1400px) {
  .section-rent__column:first-of-type {
    flex: 0 0 450px;
    padding: 0;
  }
}
@media (max-width: 991.98px) {
  .section-rent__column:first-of-type {
    flex: 0 0 auto;
  }
}
.section-rent__column:last-of-type {
  padding-left: 4px;
}
@media (max-width: 1400px) {
  .section-rent__column:last-of-type {
    padding-left: 0;
  }
}
.section-rent__title {
  font-family: "YanoneKaffeesatz-Bold";
  font-size: 72px;
  line-height: 72px;
  text-align: center;
  color: #273646;
}
@media (max-width: 1378px) {
  .section-rent__title {
    font-size: 62px;
  }
}
@media (max-width: 991.98px) {
  .section-rent__title {
    font-size: 52px;
  }
}
@media (max-width: 767.98px) {
  .section-rent__title {
    font-size: 42px;
  }
}
.section-rent__title span {
  font-family: "PTSansNarrow-Bold";
  font-size: 60px;
}
@media (max-width: 1378px) {
  .section-rent__title span {
    font-size: 52px;
  }
}
@media (max-width: 991.98px) {
  .section-rent__title span {
    font-size: 44px;
  }
}
@media (max-width: 767.98px) {
  .section-rent__title span {
    font-size: 36px;
  }
}
.section-rent__text {
  padding-top: 12px;
  font-size: 22px;
  line-height: 28px;
  font-weight: 400;
  color: #646c77;
  text-align: center;
}
@media (max-width: 1378px) {
  .section-rent__text {
    font-size: 20px;
  }
}
@media (max-width: 991.98px) {
  .section-rent__text {
    font-size: 18px;
  }
}
@media (max-width: 767.98px) {
  .section-rent__text {
    font-size: 16px;
  }
}
@media (max-width: 479.98px) {
  .section-rent__text {
    padding-top: 0;
    line-height: 24px;
  }
}
.section-rent__link {
  font-size: 18px;
  margin: 13px auto;
  display: block;
  text-align: center;
  line-height: 25px;
  font-weight: 700;
  color: #3067bb;
  position: relative;
  transition: all 0.3s ease 0ms;
}
@media (max-width: 991.98px) {
  .section-rent__link {
    margin-bottom: 50px;
  }
}
@media (max-width: 479.98px) {
  .section-rent__link {
    font-size: 15px;
  }
}
.section-rent__link:after {
  content: "";
  width: 18.5%;
  background-color: #3067bb;
  height: 1px;
  display: block;
  position: absolute;
  bottom: 0;
  left: 50%;
  transform: translateX(-50%);
  transition: all 0.3s ease 0ms;
}
@media (max-width: 1378px) {
  .section-rent__link:after {
    width: 19%;
  }
}
@media (max-width: 991.98px) {
  .section-rent__link:after {
    width: 19.5%;
  }
}
@media (max-width: 767.98px) {
  .section-rent__link:after {
    width: 20%;
  }
}
@media (max-width: 575px) {
  .section-rent__link:after {
    width: 20.5%;
  }
}
.section-rent__link:hover {
  color: #dc240a;
}
.section-rent__link:hover:after {
  width: 0;
  background-color: #fff;
}
.section-rent__image {
  max-width: 100%;
  width: 100%;
  object-fit: cover;
}

.section-answers {
  min-height: 730px;
  background-color: #80379b;
  box-shadow: inset 0px 0px 250px 0px rgba(0, 0, 0, 0.84);
  padding: 60px 0 60px;
  color: #fff;
  position: relative;
  overflow: hidden;
}
@media (max-width: 479.98px) {
  .section-answers {
    padding: 55px 0 90px;
  }
}
.section-answers__parallax {
  position: absolute;
  bottom: 0;
  top: 0;
  left: 0;
  right: 0;
}
.section-answers__content {
  max-width: 937px;
  width: 100%;
  margin: 0 auto;
  position: relative;
  z-index: 2;
}
.section-answers__title {
  text-align: center;
}
.section-answers__subtitle {
  margin-top: -15px;
  font-family: "RobotoCondensed-Regular";
  font-size: 22px;
  line-height: 134%;
  font-weight: 400;
  text-align: center;
  letter-spacing: -0.3px;
}
@media (max-width: 1378px) {
  .section-answers__subtitle {
    font-size: 20px;
    margin-top: -5px;
  }
}
@media (max-width: 991.98px) {
  .section-answers__subtitle {
    font-size: 16px;
  }
}
@media (max-width: 767.98px) {
  .section-answers__subtitle {
    font-size: 15px;
    margin-top: 5px;
  }
}
.section-answers__tab {
  padding-top: 55px;
  padding-left: 20px;
}
.section-answers__link {
  margin: 60px auto 0;
  max-width: 85px;
  width: 100%;
}
.section-answers__link-button {
  font-family: "RobotoCondensed-Regular";
  font-size: 18px;
  color: #fff;
  display: block;
  margin: 20px auto 0;
  text-align: center;
  position: relative;
  transition: all 0.3s ease 0s;
}
@media (max-width: 767.98px) {
  .section-answers__link-button {
    font-size: 16px;
  }
}
@media (max-width: 479.98px) {
  .section-answers__link-button {
    margin-top: 5px;
    letter-spacing: -0.5px;
  }
}
.section-answers__link-button::after {
  content: "";
  width: 100%;
  height: 1px;
  background-color: #fff;
  display: block;
  position: absolute;
  bottom: 3px;
  left: 50%;
  transform: translateX(-50%);
  transition: all 0.3s ease 0s;
}
.section-answers__link-button:hover {
  color: #f4de4b;
}
.section-answers__link-button:hover:after {
  width: 0;
  background-color: #fff;
}
.section-answers .question-icon {
  position: absolute;
  z-index: 1;
  transition: all 0.5s;
  left: 50%;
  transform: translateX(-50%);
}

.answers-tab {
  width: 100%;
}
.answers-tab__input:focus + .answers-tab__label {
  z-index: 1;
}
.answers-tab__input:checked + .answers-tab__label {
  color: #fff;
  display: block;
}
.answers-tab__label {
  display: inline-block;
  font-family: "RobotoCondensed-Regular";
  font-size: 22px;
  line-height: 33px;
  color: #ffffff;
  padding: 5px 0 10px 20px;
  margin-left: -20px;
  cursor: pointer;
  letter-spacing: -0.2px;
  text-decoration: underline dotted;
  text-underline-offset: 5px;
  transition: all 0.3s ease 0s;
}
@media (max-width: 1378px) {
  .answers-tab__label {
    font-size: 20px;
  }
}
@media (max-width: 991.98px) {
  .answers-tab__label {
    font-size: 18px;
  }
}
@media (max-width: 767.98px) {
  .answers-tab__label {
    font-size: 16px;
  }
}
.answers-tab__label:hover {
  text-decoration: none;
}
.answers-tab__label:active {
  color: none;
}
.answers-tab__panel {
  height: 0;
  padding: 0 20px;
  opacity: 0;
  visibility: hidden;
  width: 100%;
  background: #fff;
  color: #80379b;
  border-radius: 10px;
  position: relative;
  transition: all 0.2s ease 0s;
}
.answers-tab__panel::before {
  content: "";
  display: block;
  width: 19px;
  height: 40px;
  background-image: url("../img/icons/bubble.svg");
  background-position: 0 0;
  background-repeat: no-repeat;
  position: absolute;
  top: -10px;
  left: -18px;
}
.answers-tab__panel p {
  font-family: "RobotoCondensed-Regular";
  font-size: 22px;
  line-height: 30px;
  color: #80379b;
}
.answers-tab__panel p span {
  display: block;
}
@media (max-width: 1378px) {
  .answers-tab__panel p {
    font-size: 20px;
  }
}
@media (max-width: 991.98px) {
  .answers-tab__panel p {
    font-size: 18px;
  }
}
@media (max-width: 767.98px) {
  .answers-tab__panel p {
    font-size: 16px;
  }
}

.answers-tab__input:checked + .answers-tab__label + .answers-tab__panel {
  height: auto;
  padding: 20px 100px 20px 40px;
  opacity: 1;
  visibility: visible;
}
@media (max-width: 991.98px) {
  .answers-tab__input:checked + .answers-tab__label + .answers-tab__panel {
    padding-right: 20px;
    padding-left: 29px;
  }
}

.section-contacts {
  background-color: #f1f2f5;
  padding: 90px 0;
  position: relative;
  z-index: 6;
}
.section-contacts__row {
  display: flex;
  justify-content: center;
}
@media (max-width: 991.98px) {
  .section-contacts__row {
    flex-direction: column-reverse;
  }
}
.section-contacts__column {
  flex: 0 0 39.5%;
}
.section-contacts__column:first-of-type {
  flex: 0 1 844px;
  max-width: 844px;
  padding-top: 55px;
  position: relative;
  overflow: hidden;
}
@media (max-width: 991.98px) {
  .section-contacts__column:first-of-type {
    flex: 0 0 644px;
  }
}
@media (max-width: 767.98px) {
  .section-contacts__column:first-of-type {
    flex: 0 0 544px;
  }
}
@media (max-width: 600px) {
  .section-contacts__column:first-of-type {
    flex: 0 0 444px;
  }
}
@media (max-width: 479.98px) {
  .section-contacts__column:first-of-type {
    flex: 0 0 285px;
  }
}
.section-contacts__column:last-of-type {
  padding-left: 100px;
}
@media (max-width: 1440px) {
  .section-contacts__column:last-of-type {
    padding-left: 40px;
  }
}
@media (max-width: 991.98px) {
  .section-contacts__column:last-of-type {
    padding-left: 0;
  }
}
.section-contacts__image {
  max-width: 844px;
  width: 100%;
  position: relative;
}
.section-contacts__image img {
  width: 100%;
  object-fit: cover;
  transition: all 0.3s ease 0s;
  position: relative;
  z-index: 10;
}
@media (max-width: 991.98px) {
  .section-contacts__item-row {
    display: flex;
    flex-wrap: wrap;
  }
}
@media (max-width: 575px) {
  .section-contacts__item-row {
    flex-direction: column;
  }
}
.section-contacts__title {
  font-family: "YanoneKaffeesatz-Bold";
  font-size: 72px;
  color: #273646;
}
@media (max-width: 1378px) {
  .section-contacts__title {
    font-size: 62px;
  }
}
@media (max-width: 991.98px) {
  .section-contacts__title {
    font-size: 52px;
    text-align: center;
  }
}
@media (max-width: 767.98px) {
  .section-contacts__title {
    font-size: 42px;
  }
}
.section-contacts__subtitle {
  padding-top: 15px;
  padding-bottom: 35px;
  font-family: "RobotoCondensed-Regular";
  font-size: 20px;
  line-height: 21px;
  color: #4e6677;
}
@media (max-width: 1378px) {
  .section-contacts__subtitle {
    font-size: 18px;
  }
}
@media (max-width: 991.98px) {
  .section-contacts__subtitle {
    font-size: 17px;
    text-align: center;
  }
}
@media (max-width: 767.98px) {
  .section-contacts__subtitle {
    font-size: 16px;
  }
}
@media (max-width: 991.98px) {
  .section-contacts__item {
    flex: 0 0 50%;
  }
}

.contacts-item {
  padding-top: 20px;
  margin-left: -28px;
}
@media (max-width: 991.98px) {
  .contacts-item {
    margin: 0;
  }
}
.contacts-item span {
  display: block;
}
@media (max-width: 991.98px) {
  .contacts-item span {
    display: flex;
    align-items: center;
  }
}
.contacts-item__city {
  font-family: "RobotoCondensed-Bold";
  font-size: 30px;
  line-height: 110%;
  color: #394a58;
  position: relative;
  padding-left: 28px;
}
.contacts-item__city::before {
  content: "";
  display: inline-block;
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center center;
  width: 17px;
  height: 24px;
  margin-right: 12px;
}
@media (max-width: 1378px) {
  .contacts-item__city {
    font-size: 26px;
  }
}
@media (max-width: 991.98px) {
  .contacts-item__city {
    font-size: 22px;
  }
}
@media (max-width: 767.98px) {
  .contacts-item__city {
    font-size: 18px;
    padding-left: 15px;
  }
}
.contacts-item__phone {
  padding-top: 7px;
}
.contacts-item__phone, .contacts-item__mail {
  padding-left: 60px;
  font-size: 22px;
  line-height: 28px;
  font-weight: 400;
  color: #4e6677;
}
@media (max-width: 767.98px) {
  .contacts-item__phone, .contacts-item__mail {
    padding-left: 45px;
  }
}
.contacts-item__link {
  margin-left: 65px;
  font-size: 18px;
  line-height: 102%;
  font-weight: 700;
  color: #3067bb;
  white-space: nowrap;
  position: relative;
  text-overflow: ellipsis;
  letter-spacing: 0.85px;
}
@media (max-width: 1378px) {
  .contacts-item__link {
    font-size: 17px;
  }
}
@media (max-width: 991.98px) {
  .contacts-item__link {
    font-size: 16px;
  }
}
@media (max-width: 767.98px) {
  .contacts-item__link {
    font-size: 15px;
    margin-left: 45px;
  }
}
.contacts-item__link:after {
  content: "";
  width: 100%;
  background-color: #3067bb;
  height: 1px;
  display: block;
  position: absolute;
  bottom: 0;
  left: 50%;
  transform: translateX(-50%);
  transition: all 0.3s ease 0ms;
}
.contacts-item__link:hover {
  color: #dc240a;
}
.contacts-item__link:hover:after {
  width: 0;
  background-color: #fff;
}

.marker-moscow::before {
  background-image: url("../img/icons/pointer-red.svg");
}

.marker-mineral::before {
  background-image: url("../img/icons/pointer-green.svg");
}

.marker-krym::before {
  background-image: url("../img/icons/pointer-orange.svg");
}

.marker-new::before {
  background-image: url("../img/icons/pointer-gray.svg");
}

.question {
  max-width: 969px;
  width: 100%;
  background-color: #fff;
  padding: 35px 75px;
  border-radius: 10px;
  position: absolute;
  bottom: -60px;
  left: 50%;
  transform: translateX(-50%);
  letter-spacing: -0.3px;
  z-index: 8;
}
@media (max-width: 991.98px) {
  .question {
    width: 90%;
  }
}
@media (max-width: 767.98px) {
  .question {
    padding-left: 35px;
    padding-right: 35px;
  }
}
@media (max-width: 479.98px) {
  .question {
    padding-left: 20px;
    padding-right: 20px;
  }
}
.question__content {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
@media (max-width: 991.98px) {
  .question__content {
    flex-direction: column;
  }
}
.question__text {
  font-family: "Roboto";
  font-size: 36px;
  line-height: 120%;
  font-weight: 700;
  color: #273646;
}
@media (max-width: 1378px) {
  .question__text {
    font-size: 32px;
  }
}
@media (max-width: 991.98px) {
  .question__text {
    font-size: 26px;
  }
}
@media (max-width: 767.98px) {
  .question__text {
    font-size: 24px;
    text-align: center;
  }
}
.question__btn {
  display: flex;
  align-items: center;
  justify-content: center;
  max-width: 239px;
  width: 100%;
  height: 60px;
  padding: 18px 24px;
  border-radius: 5px;
  background-color: #f4de4b;
  border: 1px solid #ccbc4c;
  box-shadow: 0 4px 2px -1px rgba(0, 0, 0, 0.5);
  font-family: "RobotoCondensed-Bold";
  font-size: 24px;
  text-shadow: -1px -1px 0px rgba(255, 255, 255, 0.25);
  color: #2c3e50;
  transition: all 0.3s ease 0s;
}
@media (max-width: 991.98px) {
  .question__btn {
    margin-top: 22px;
  }
}
@media (max-width: 479.98px) {
  .question__btn {
    padding: 18px 14px;
  }
}
.question__btn:hover {
  background-color: #e3cd39;
}

.hideButton {
  display: none !important;
}

.desktop-box__image {
  position: relative;
}
.desktop-box__image img {
  opacity: 0;
  visibility: hidden;
}

.device-image__item {
  position: absolute !important;
  max-width: 1189.14px;
  width: 100%;
  top: 0;
}

.device__gray .desktop-box__image .d-gray {
  opacity: 1;
  visibility: visible;
}
.device__jeans .desktop-box__image .d-jeans {
  opacity: 1;
  visibility: visible;
}
.device__gold .desktop-box__image .d-gold {
  opacity: 1;
  visibility: visible;
}
.device__grapefruit .desktop-box__image .d-grapefruit {
  opacity: 1;
  visibility: visible;
}
.device__peper .desktop-box__image .d-peper {
  opacity: 1;
  visibility: visible;
}
.device__leather .desktop-box__image .d-leather {
  opacity: 1;
  visibility: visible;
}
.device__tangerin .desktop-box__image .d-tangerin {
  opacity: 1;
  visibility: visible;
}
.device__mustard .desktop-box__image .d-mustard {
  opacity: 1;
  visibility: visible;
}
.device__lime .desktop-box__image .d-lime {
  opacity: 1;
  visibility: visible;
}
.device__grass .desktop-box__image .d-grass {
  opacity: 1;
  visibility: visible;
}
.device__mint .desktop-box__image .d-mint {
  opacity: 1;
  visibility: visible;
}
.device__laguna .desktop-box__image .d-laguna {
  opacity: 1;
  visibility: visible;
}
.device__sky .desktop-box__image .d-sky {
  opacity: 1;
  visibility: visible;
}
.device__sea .desktop-box__image .d-sea {
  opacity: 1;
  visibility: visible;
}
.device__lavender .desktop-box__image .d-lavender {
  opacity: 1;
  visibility: visible;
}
.device__clover .desktop-box__image .d-clover {
  opacity: 1;
  visibility: visible;
}

.search-btn {
  position: absolute;
  top: 50%;
  right: 10px;
  transform: translateY(10px);
}

.search {
  position: absolute;
  width: 20px;
  right: 5px;
}
.search__circle {
  position: absolute;
  top: -17px;
  width: 13px;
  height: 13px;
  border: 2px solid #2c3e50;
  border-radius: 100px;
}
.search__rectangle {
  position: absolute;
  right: 4px;
  bottom: 3px;
  width: 6px;
  transform: rotate(45deg);
  border: 1px solid #2c3e50;
}

.sidebar__header {
  padding: 12px 25px 10px;
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
  position: relative;
  overflow: hidden;
}
.sidebar__header::before {
  background-image: url("../img/sidebar-header.jpg");
  background-repeat: no-repeat;
  background-size: auto;
  content: "";
  display: block;
  height: 100%;
  position: absolute;
  width: 100%;
  top: 0;
  left: 0;
  z-index: 0;
}
@media (max-width: 991.98px) {
  .sidebar__header::before {
    background-size: cover;
  }
}
.sidebar__title {
  color: #fff;
  text-transform: uppercase;
  font-weight: 700;
  font-size: 18px;
  line-height: 28px;
  position: relative;
  z-index: 1;
}
.sidebar__content-menu {
  background-color: #f1f2f5;
  padding: 13px 27px 45px 25px;
  border-bottom: 3px solid rgba(213, 43, 30, 0.85);
  margin-bottom: 55px;
}
.sidebar__content-info {
  background-color: #f1f2f5;
  padding: 10px 30px 25px 30px;
  border-top: 3px solid #425262;
}
.sidebar__content-item {
  margin: 35px 0 55px;
}
.sidebar__content-title {
  font-size: 21px;
  line-height: 28px;
  color: #273646;
  font-weight: 400;
}
@media (max-width: 767.98px) {
  .sidebar__content-title {
    font-size: 19px;
  }
}
@media (max-width: 479.98px) {
  .sidebar__content-title {
    font-size: 17px;
  }
}
.sidebar__content-title span {
  font-weight: 700;
}
.sidebar__content-text {
  padding-top: 13px;
  padding-right: 5px;
  font-size: 16px;
  line-height: 24px;
  font-weight: 400;
  color: #2c3e50;
}
@media (max-width: 767.98px) {
  .sidebar__content-text {
    font-size: 15px;
  }
}
@media (max-width: 479.98px) {
  .sidebar__content-text {
    font-size: 14px;
  }
}
.sidebar__content-text a {
  display: inline-block;
  font-family: "Roboto";
  font-size: 16px;
  line-height: 24px;
  font-weight: 400;
  color: #0089c4;
  position: relative;
}
@media (max-width: 767.98px) {
  .sidebar__content-text a {
    font-size: 15px;
  }
}
@media (max-width: 479.98px) {
  .sidebar__content-text a {
    font-size: 14px;
  }
}
.sidebar__content-text a::after {
  content: "";
  display: block;
  max-width: 100%;
  width: 100%;
  height: 1px;
  background-color: #0089c4;
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  bottom: 3px;
  transition: all 0.3s ease 0s;
}
.sidebar__content-text a:hover:after {
  width: 0;
  background-color: #fff;
}
.sidebar__content-quote {
  box-shadow: 0px 1px 5px 0px rgba(0, 0, 0, 0.5);
  padding: 42px 45px 28px 72px;
  background-color: #009b74;
  border-radius: 5px;
  background-image: linear-gradient(165deg, #2d5ac6 11%, #009b74 50%, #0fa982 88%);
  font-size: 19px;
  line-height: 26px;
  font-weight: 400;
  font-style: italic;
  color: #ffffff;
  position: relative;
}
@media (max-width: 767.98px) {
  .sidebar__content-quote {
    font-size: 17px;
  }
}
@media (max-width: 479.98px) {
  .sidebar__content-quote {
    padding: 22px 25px 18px 62px;
    font-size: 15px;
  }
}
.sidebar__content-quote span {
  display: block;
  margin-top: 10px;
  text-align: right;
}
.sidebar__content-quote::before {
  content: '"';
  font-family: "Roboto";
  display: block;
  color: #fff;
  font-size: 110px;
  position: absolute;
  transform: rotate(180deg);
  top: -5px;
  left: 45px;
}
@media (max-width: 767.98px) {
  .sidebar__content-quote::before {
    font-size: 90px;
  }
}
@media (max-width: 479.98px) {
  .sidebar__content-quote::before {
    font-size: 70px;
  }
}
.sidebar__content-tabs {
  margin-top: 82px;
  margin-bottom: 75px;
}
.sidebar__content-image {
  margin-top: 20px;
  margin-bottom: 10px;
  max-width: 349px;
  height: 200px;
  border-radius: 5px;
  overflow: hidden;
}
.sidebar__content-image img {
  width: 100%;
  object-fit: cover;
}
@media (max-width: 991.98px) {
  .sidebar__content-image {
    max-width: 100%;
    height: auto;
  }
}
.sidebar__content-contact {
  padding-top: 40px;
}

.sidebar-list {
  padding-top: 15px;
}

.unordered-list {
  padding-top: 10px;
  padding-left: 19px;
}
.unordered-list li {
  font-size: 16px;
  line-height: 28px;
  font-weight: 400;
  color: #2c3e50;
  padding-left: 19px;
  position: relative;
}
@media (max-width: 767.98px) {
  .unordered-list li {
    font-size: 15px;
  }
}
@media (max-width: 479.98px) {
  .unordered-list li {
    font-size: 14px;
    line-height: 22px;
  }
}
.unordered-list li::before {
  content: "";
  width: 7px;
  height: 7px;
  display: inline-block;
  background-color: rgba(213, 43, 30, 0.85);
  border-radius: 50%;
  margin-right: 10px;
  margin-bottom: 2px;
  position: absolute;
  top: 10px;
  left: 3px;
}
@media (max-width: 479.98px) {
  .unordered-list li::before {
    top: 7px;
  }
}

.ordered-list {
  padding-top: 10px;
  padding-left: 30px;
  counter-reset: my-awesome-counter;
}
.ordered-list li {
  font-size: 16px;
  line-height: 28px;
  font-weight: 400;
  color: #2c3e50;
  padding-left: 10px;
  position: relative;
  counter-increment: my-awesome-counter;
}
@media (max-width: 767.98px) {
  .ordered-list li {
    font-size: 15px;
  }
}
@media (max-width: 479.98px) {
  .ordered-list li {
    font-size: 14px;
    line-height: 22px;
  }
}
.ordered-list li::marker {
  content: counter(my-awesome-counter) "";
  color: rgba(213, 43, 30, 0.85);
  font-weight: 400;
  font-family: "Arial";
  font-size: 15px;
}

.menu-sidebar {
  overflow: hidden;
  padding-bottom: 10px;
}
.menu-sidebar__list {
  position: relative;
  padding-left: 25px;
  padding-top: 12px;
  cursor: pointer;
}
.menu-sidebar__list:hover .menu-sidebar__link {
  color: rgba(213, 43, 30, 0.85);
}
.menu-sidebar__list::before {
  content: "";
  display: block;
  width: 12px;
  height: 12px;
  background-color: rgba(213, 43, 30, 0.85);
  border-radius: 50%;
  position: absolute;
  top: 26px;
  left: 3px;
  opacity: 0;
  visibility: hidden;
  transition: all 0.2s ease 0s;
}
.menu-sidebar__list::after {
  content: "";
  display: block;
  border-bottom: 2px solid #9ba7b2;
  position: absolute;
  width: 100%;
  top: 56px;
  left: 5px;
}
.menu-sidebar__list--active::before {
  opacity: 1;
  visibility: visible;
}
.menu-sidebar__link {
  color: #273646;
  text-transform: uppercase;
  font-family: "RobotoCondensed-Regular";
  font-size: 15px;
  line-height: 18px;
  transition: all 0.3s ease 0s;
}
.menu-sidebar__link:hover {
  color: rgba(213, 43, 30, 0.85);
}
.menu-sidebar__submenu {
  height: 0;
  opacity: 0;
  visibility: hidden;
  padding-top: 5px;
  margin-bottom: -5px;
  transition: height 0.2s opacity 0.2s visibility 0.2s ease 0s;
}
@media (max-width: 479.98px) {
  .menu-sidebar__submenu {
    line-height: 120%;
  }
}
.menu-sidebar__sublist {
  border-bottom: 1px solid #9ba7b2;
  position: relative;
  padding-top: 6px;
  padding-left: 23px;
  padding-bottom: 7px;
}
.menu-sidebar__sublist:hover .menu-sidebar__sublink {
  color: rgba(213, 43, 30, 0.85);
}
.menu-sidebar__sublist:first-child {
  padding-top: 10px;
  padding-bottom: 10px;
}
.menu-sidebar__sublist:first-child::before {
  top: 23px;
}
@media (max-width: 479.98px) {
  .menu-sidebar__sublist:first-child::before {
    top: 18px;
  }
}
.menu-sidebar__sublist::before {
  content: "";
  display: block;
  width: 12px;
  height: 12px;
  background-color: rgba(213, 43, 30, 0.85);
  border-radius: 50%;
  position: absolute;
  top: 20px;
  left: 0;
  opacity: 0;
  visibility: hidden;
  transition: all 0.3s;
}
@media (max-width: 479.98px) {
  .menu-sidebar__sublist::before {
    top: 14px;
  }
}
.menu-sidebar__sublist--active .menu-sidebar__sublink {
  font-family: "RobotoCondensed-Bold";
}
.menu-sidebar__sublist--active::before {
  opacity: 1;
  visibility: visible;
}
.menu-sidebar__sublink {
  color: #273646;
  text-transform: uppercase;
  font-family: "RobotoCondensed-Regular";
  font-size: 15px;
  transition: all 0.3s;
}
.menu-sidebar__sublink:hover {
  color: rgba(213, 43, 30, 0.85);
}

.menu-sidebar__list--active.sidebar-submenu:before {
  content: "";
  display: block;
  width: 12px;
  height: 12px;
  background-color: transparent;
  border: 2px solid rgba(213, 43, 30, 0.85);
  border-radius: 50%;
  position: absolute;
  top: 26px;
  left: 3px;
  opacity: 1;
  visibility: visible;
  transition: all 0.2s ease 0s;
}

.menu-sidebar__list--active > .menu-sidebar__submenu {
  height: auto;
  opacity: 1;
  visibility: visible;
  transition: height 0.3s opacity 0.3 visibility 0.3 ease 0s;
}

.tabs-sidebar__nav ul {
  display: flex;
}
@media (max-width: 479.98px) {
  .tabs-sidebar__nav li {
    flex: 0 0 33.33%;
    max-width: 33.33%;
  }
}
@media (max-width: 375px) {
  .tabs-sidebar__nav li:first-of-type .tabs-sidebar__item {
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
  }
}
.tabs-sidebar .active-tab .tabs-sidebar__item {
  box-shadow: inset 0px 3px 0px 0px rgba(213, 43, 30, 0.85);
  background-color: #fff;
  color: #425262;
  cursor: inherit;
}
.tabs-sidebar__item {
  background-color: #425262;
  width: 106px;
  padding: 10px 15px 5px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 13px;
  line-height: 28px;
  font-weight: 700;
  color: #ffffff;
  text-transform: uppercase;
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
  cursor: pointer;
  transition: all 0.3s ease 0s;
  margin: 0 0.5px;
  position: relative;
  z-index: 1;
}
.tabs-sidebar__item span {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
@media (max-width: 479.98px) {
  .tabs-sidebar__item {
    width: 99%;
    font-size: 12px;
    padding-left: 10px;
    padding-right: 10px;
  }
}
.tabs-sidebar__item:hover {
  background-color: rgba(213, 43, 30, 0.85);
}
.tabs-sidebar__inner {
  padding: 19px 15px 4px 15px;
  background-color: #fff;
  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 5px;
  border-top-right-radius: 5px;
  position: relative;
  z-index: 3;
}
@media (max-width: 479.98px) {
  .tabs-sidebar__inner {
    padding-left: 0px;
    padding-right: 0px;
  }
}
.tabs-sidebar__inner-item {
  height: 0;
  opacity: 0;
  visibility: hidden;
  transition: height 0.3s opacity 0.2s visibility 0.2s;
}
.tabs-sidebar__inner-item--active {
  max-height: 352px;
  height: auto;
  opacity: 1;
  visibility: visible;
  transition: height 0.3s opacity 0.2s visibility 0.2s;
}
@media (max-width: 479.98px) {
  .tabs-sidebar__inner-item--active {
    max-height: 100%;
  }
}
.tabs-sidebar__inner-box {
  display: flex;
  justify-content: space-between;
  padding-left: 10px;
  border-bottom: 1px dotted #9ba7b2;
  padding-top: 15px;
  padding-bottom: 15px;
  padding-right: 15px;
}
.tabs-sidebar__inner-box:last-of-type {
  border-bottom: 0;
}
@media (max-width: 991.98px) {
  .tabs-sidebar__inner-box {
    justify-content: flex-start;
    max-width: 100%;
  }
}
@media (max-width: 767.98px) {
  .tabs-sidebar__inner-box {
    padding-top: 20px;
    padding-bottom: 20px;
  }
}
@media (max-width: 479.98px) {
  .tabs-sidebar__inner-box {
    padding-top: 20px;
    padding-bottom: 20px;
  }
}
@media (max-width: 991.98px) {
  .tabs-sidebar__inner-content {
    width: 100%;
  }
}
.tabs-sidebar__inner-img {
  margin-right: 20px;
  flex: 0 0 64px;
  height: 64px;
  border-radius: 5px;
}
.tabs-sidebar__inner-img img {
  width: 100%;
  object-fit: cover;
}
.tabs-sidebar__inner-link {
  display: block;
  font-size: 16px;
  line-height: 22px;
  color: #0089c4;
}
@media (max-width: 479.98px) {
  .tabs-sidebar__inner-link {
    font-size: 14px;
  }
}
.tabs-sidebar__inner-meta {
  display: block;
  font-size: 13px;
  line-height: 22px;
  font-weight: 400;
  color: #394a58;
}
@media (max-width: 479.98px) {
  .tabs-sidebar__inner-meta {
    font-size: 12px;
  }
}

.sidebar-contact__title {
  font-size: 21px;
  line-height: 28px;
  color: #273646;
  font-weight: 400;
}
@media (max-width: 767.98px) {
  .sidebar-contact__title {
    font-size: 18px;
  }
}
@media (max-width: 479.98px) {
  .sidebar-contact__title {
    font-size: 17px;
  }
}
.sidebar-contact__title span {
  font-weight: 700;
}
.sidebar-contact__item {
  font-size: 16px;
  line-height: 28px;
  font-weight: 400;
  color: #202224;
  padding-top: 17px;
  position: relative;
  display: flex;
  transition: all 0.3s ease 0s;
}
.sidebar-contact__item::before {
  content: "";
  background-repeat: no-repeat;
  display: inline-block;
  background-position: center center;
  background-size: contain;
  width: 25px;
  height: 25px;
  margin-right: 15px;
}
.sidebar-contact__item:hover {
  color: #d52b1e;
}
.sidebar-contact__item-row {
  display: flex;
  justify-content: space-between;
  padding: 17px 0 0;
  justify-content: flex-start;
}
.sidebar-contact__item-row::before {
  display: none;
}
.sidebar-contact__item-row:last-of-type {
  padding-bottom: 50px;
}
@media (max-width: 479.98px) {
  .sidebar-contact__item-row {
    flex-direction: column;
  }
}
.sidebar-contact__item-column {
  position: relative;
  display: flex;
}
.sidebar-contact__item-column:first-of-type {
  flex: 0 0 135px;
}
@media (max-width: 479.98px) {
  .sidebar-contact__item-column:first-of-type {
    flex: 0 0 auto;
  }
}
.sidebar-contact__item-column:last-of-type {
  flex: 0 0 200px;
  text-align: left;
  padding-left: 10px;
}
@media (max-width: 479.98px) {
  .sidebar-contact__item-column:last-of-type {
    flex: 0 0 auto;
    padding-left: 40px;
  }
}
.sidebar-contact__email {
  color: #0089c4;
}

.pointer::before {
  background-image: url("../img/icons/point.svg");
}

.phone::before {
  background-image: url("../img/icons/phone.svg");
}

.email::before {
  background-image: url("../img/icons/mail.svg");
}

.clock::before {
  content: "";
  background-repeat: no-repeat;
  background-image: url("../img/icons/time_black.svg");
  display: inline-block;
  background-position: center center;
  background-size: contain;
  width: 25px;
  height: 25px;
  margin-right: 15px;
}
.clock__red::before {
  background-image: url("../img/icons/time_red.svg");
}

.blogPage-topheader {
  margin-right: 0 !important;
}

.blogPage {
  padding-top: 22px !important;
}
.blogPage__body {
  padding: 0;
}
@media (max-width: 991.98px) {
  .blogPage__body {
    justify-content: flex-end;
  }
}
@media (max-width: 767.98px) {
  .blogPage__body {
    padding: 0 !important;
  }
}
@media (max-width: 479.98px) {
  .blogPage__body {
    padding: 0 15px !important;
  }
}
.blogPage__sidebar {
  margin-top: 24px;
}
.blogPage__content {
  padding-left: 45px;
}
@media (max-width: 991.98px) {
  .blogPage__content {
    padding-left: 0;
    padding-bottom: 50px;
  }
}
@media (max-width: 767.98px) {
  .blogPage__content {
    padding-left: 20px;
    padding-right: 20px;
  }
}
@media (max-width: 479.98px) {
  .blogPage__content {
    padding-left: 0px;
    padding-right: 0px;
  }
}
.blogPage__content-title {
  padding-top: 20px;
}
.blogPage__content-text {
  padding-top: 20px;
}
.blogPage__content-image {
  margin-top: 45px;
  margin-bottom: 15px;
}
.blogPage__content-imgTitle {
  margin-top: 10px;
}
.blogPage__content-divider {
  margin-top: 55px;
  margin-bottom: 55px;
}
.blogPage__content-smallImg {
  margin-top: 30px;
  padding-right: 30px;
}
.blogPage__content-form {
  margin-top: 22px;
}
.blogPage__content-services {
  margin-top: 45px;
}
.blogPage__content-table {
  margin-bottom: 60px;
}
.blogPage__errorPage {
  padding-top: 300px;
  padding-bottom: 300px;
}

.blog-image {
  text-align: center;
  max-width: 100%;
  border-radius: 5px;
  overflow: hidden;
}
.blog-image img {
  width: 100%;
  object-fit: cover;
}

.blog-smallImg {
  float: left;
}
@media (max-width: 767.98px) {
  .blog-smallImg {
    float: none;
    padding-right: 0;
  }
}
@media (max-width: 767.98px) {
  .blog-smallImg img {
    width: 100%;
    object-fit: contain;
  }
}

.small-imgTitle {
  text-align: center;
  font-size: 15px;
  line-height: 28px;
  font-weight: 400;
  font-style: italic;
  color: #4b5159;
}

.imgTitle {
  font-size: 15px;
  line-height: 160%;
  font-weight: 400;
  font-style: italic;
  color: #394a58;
}

.breadcrumbs {
  font-family: "RobotoCondensed-Regular";
  font-size: 15px;
  color: #9ba7b2;
}
.breadcrumbs a {
  display: inline-block;
  font-family: "RobotoCondensed-Regular";
  font-size: 15px;
  color: #9ba7b2;
  position: relative;
  padding-right: 20px;
  transition: all 0.3s ease 0s;
}
.breadcrumbs a:hover {
  color: #d52b1e;
}
@media (max-width: 479.98px) {
  .breadcrumbs a {
    font-size: 14px;
  }
}
.breadcrumbs a:first-of-type {
  text-decoration: underline;
}
.breadcrumbs a:nth-of-type(2)::before, .breadcrumbs a:nth-of-type(3)::before, .breadcrumbs a:nth-of-type(4)::before, .breadcrumbs a:nth-of-type(5)::before, .breadcrumbs a:nth-of-type(6)::before {
  content: "";
  background-image: url("../img/icons/angle-right-solid.svg");
  background-repeat: no-repeat;
  background-size: 105%;
  display: block;
  width: 7px;
  height: 12px;
  position: absolute;
  top: 10px;
  left: -14px;
  color: #9ba7b2;
}
.breadcrumbs span {
  display: inline-block;
  position: relative;
}
.breadcrumbs span::before {
  content: "";
  background-image: url("../img/icons/angle-right-solid.svg");
  background-repeat: no-repeat;
  background-size: 105%;
  display: block;
  width: 7px;
  height: 12px;
  position: absolute;
  top: 10px;
  left: -14px;
  color: #9ba7b2;
}

.h2-blog-title {
  font-size: 38px;
  line-height: 120%;
  color: #273646;
  font-weight: 400;
}
.h2-blog-title span {
  font-weight: 700;
}
@media (max-width: 991.98px) {
  .h2-blog-title {
    font-size: 34px;
  }
}
@media (max-width: 767.98px) {
  .h2-blog-title {
    font-size: 30px;
  }
}
@media (max-width: 479.98px) {
  .h2-blog-title {
    font-size: 26px;
  }
}

.h3-blog-title {
  font-size: 26px;
  line-height: 120%;
  color: #273646;
  font-weight: 400;
}
.h3-blog-title span {
  font-weight: 700;
}
@media (max-width: 991.98px) {
  .h3-blog-title {
    font-size: 24px;
  }
}
@media (max-width: 767.98px) {
  .h3-blog-title {
    font-size: 22px;
  }
}
@media (max-width: 479.98px) {
  .h3-blog-title {
    font-size: 20px;
  }
}

.h4-blog-title {
  font-size: 20px;
  line-height: 110%;
  color: #273646;
  font-weight: 400;
}
.h4-blog-title span {
  font-weight: 700;
}
@media (max-width: 991.98px) {
  .h4-blog-title {
    font-size: 19px;
  }
}
@media (max-width: 767.98px) {
  .h4-blog-title {
    font-size: 18px;
  }
}
@media (max-width: 479.98px) {
  .h4-blog-title {
    font-size: 17px;
  }
}

.hotels {
  font-family: "Roboto";
  font-size: 18px;
  line-height: 170%;
  font-weight: 400;
  color: #0089c4;
  position: relative;
  transition: all 0.3s ease 0s;
}
@media (max-width: 991.98px) {
  .hotels {
    font-size: 17px;
  }
}
@media (max-width: 767.98px) {
  .hotels {
    font-size: 16px;
  }
}
@media (max-width: 479.98px) {
  .hotels {
    font-size: 15px;
    text-decoration: underline;
  }
}
.hotels:hover {
  color: rgba(213, 43, 30, 0.85);
}
.hotels::after {
  content: "";
  display: block;
  max-width: 100%;
  width: 100%;
  height: 1px;
  background-color: #0089c4;
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  bottom: 3px;
  transition: all 0.3s ease 0s;
}
@media (max-width: 479.98px) {
  .hotels::after {
    width: 0;
  }
}
.hotels:hover:after {
  width: 0;
  background-color: #fff;
}

.green {
  color: #009b74;
}
.green::after {
  background-color: #009b74;
}

.red {
  color: #af0b26;
}
.red::after {
  background-color: #af0b26;
}

.violet {
  color: #80379b;
}
.violet::after {
  background-color: #80379b;
}

.orange {
  color: #ff7900;
}
.orange::after {
  background-color: #ff7900;
}

.blue {
  color: #1e6aa7;
}
.blue::after {
  background-color: #1e6aa7;
}

.p-blog-text {
  font-size: 18px;
  line-height: 160%;
  font-weight: 400;
  color: #394a58;
}
@media (max-width: 991.98px) {
  .p-blog-text {
    font-size: 17px;
  }
}
@media (max-width: 767.98px) {
  .p-blog-text {
    font-size: 16px;
  }
}
@media (max-width: 479.98px) {
  .p-blog-text {
    font-size: 15px;
  }
}
.p-blog-text b {
  font-weight: 700;
  color: #2c3e50;
}
.p-blog-text a {
  font-family: "Roboto";
  font-size: 18px;
  line-height: 160%;
  font-weight: 400;
  color: #0089c4;
  position: relative;
  transition: all 0.3s ease 0s;
}
@media (max-width: 991.98px) {
  .p-blog-text a {
    font-size: 17px;
  }
}
@media (max-width: 767.98px) {
  .p-blog-text a {
    font-size: 16px;
  }
}
@media (max-width: 479.98px) {
  .p-blog-text a {
    font-size: 15px;
    text-decoration: underline;
  }
}
.p-blog-text a:hover {
  color: rgba(213, 43, 30, 0.85);
}
.p-blog-text a::after {
  content: "";
  display: block;
  max-width: 100%;
  width: 100%;
  height: 1px;
  background-color: #0089c4;
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  bottom: 1px;
  transition: all 0.3s ease 0s;
}
@media (max-width: 479.98px) {
  .p-blog-text a::after {
    width: 0;
  }
}
.p-blog-text a:hover:after {
  width: 0;
  background-color: #fff;
}
.p-blog-text span:nth-of-type(2) {
  padding-top: 30px;
}

.mt-10 {
  margin-top: 10px;
}

.mt-20 {
  margin-top: 20px;
}

.mt-30 {
  margin-top: 20px;
}

.blog-unordered-list {
  padding-top: 10px;
  padding-left: 25px;
}
.blog-unordered-list li {
  font-size: 18px;
  line-height: 170%;
  font-weight: 400;
  color: #394a58;
  position: relative;
  padding-left: 15px;
}
@media (max-width: 991.98px) {
  .blog-unordered-list li {
    font-size: 17px;
  }
}
@media (max-width: 767.98px) {
  .blog-unordered-list li {
    font-size: 16px;
  }
}
@media (max-width: 479.98px) {
  .blog-unordered-list li {
    font-size: 15px;
    line-height: 23px;
  }
}
.blog-unordered-list li::before {
  content: "";
  width: 7px;
  height: 7px;
  display: inline-block;
  background-color: rgba(213, 43, 30, 0.85);
  border-radius: 50%;
  margin-right: 10px;
  margin-bottom: 2px;
  position: absolute;
  top: 8px;
  left: -3px;
}

.blog-ordered-list {
  padding-top: 10px;
  padding-left: 30px;
  counter-reset: my-awesome-counter;
}
.blog-ordered-list li {
  font-size: 18px;
  line-height: 170%;
  font-weight: 400;
  color: #394a58;
  padding-left: 10px;
  position: relative;
  counter-increment: my-awesome-counter;
}
@media (max-width: 991.98px) {
  .blog-ordered-list li {
    font-size: 17px;
  }
}
@media (max-width: 767.98px) {
  .blog-ordered-list li {
    font-size: 16px;
  }
}
@media (max-width: 479.98px) {
  .blog-ordered-list li {
    font-size: 15px;
    line-height: 23px;
  }
}
.blog-ordered-list li::marker {
  content: counter(my-awesome-counter) "";
  color: rgba(213, 43, 30, 0.85);
  font-weight: 400;
  font-family: "Arial";
  font-size: 18px;
}
@media (max-width: 991.98px) {
  .blog-ordered-list li::marker {
    font-size: 17px;
  }
}
@media (max-width: 767.98px) {
  .blog-ordered-list li::marker {
    font-size: 16px;
  }
}
@media (max-width: 479.98px) {
  .blog-ordered-list li::marker {
    font-size: 15px;
  }
}

.hr-divider {
  width: 100%;
  height: 1px;
  background-color: #b1b5bb;
}

.blog-form * {
  outline: none;
}
.blog-form__row {
  display: flex;
  flex-wrap: wrap;
}
.blog-form__inputField {
  flex: 0 0 33.33%;
  margin-bottom: 30px;
}
@media (max-width: 1378px) {
  .blog-form__inputField {
    flex: 0 0 100%;
    max-width: 100%;
  }
}
.blog-form__label {
  font-size: 15px;
  line-height: 28px;
  font-weight: 700;
  color: #394a58;
  display: block;
}
.blog-form__bottomField {
  justify-content: space-between;
}
.blog-form__sendField {
  white-space: wrap;
  text-overflow: ellipsis;
  overflow: hidden;
  max-width: 500px;
  margin-bottom: 30px;
}
.blog-form__sendField:last-of-type {
  margin-right: 10px;
}
@media (max-width: 479.98px) {
  .blog-form__sendField:last-of-type {
    margin-right: 0;
  }
}
.blog-form__warningText {
  font-size: 15px;
  line-height: 150%;
  font-weight: 400;
  font-style: italic;
  color: #646c77;
}
.blog-form__warningText span {
  color: rgba(213, 43, 30, 0.85);
}
.blog-form__sendBtn {
  display: block;
  margin-left: 27px;
}
.blog-form__buttons {
  display: flex;
  justify-content: flex-end;
  margin-bottom: 50px;
}
@media (max-width: 767.98px) {
  .blog-form__buttons {
    display: block;
  }
}
@media (max-width: 767.98px) {
  .blog-form__buttons .btn {
    display: block;
    margin-left: auto;
  }
}

.g-recaptcha {
  width: 100%;
}
.g-recaptcha div {
  max-width: 100%;
  width: 100% !important;
  height: auto;
}
.g-recaptcha iframe {
  max-width: 100%;
  width: 100%;
  height: auto;
}

.base-input {
  max-width: 295px;
  width: 100%;
  background-color: #ffffff;
  border: 1px solid #9ba7b2;
  box-shadow: inset 0px 1px 1px 0px rgba(0, 0, 0, 0.14);
  border-radius: 5px;
  padding: 7px 18px;
  min-height: 46px;
  transition: all 0.3s ease 0s;
  font-family: "RobotoCondensed-Bold";
  text-transform: none;
  font-size: 18px;
  line-height: 18px;
  color: #3b4957;
  transition: all 0.3s ease 0s;
}
.base-input:focus {
  box-shadow: inset 0px 0px 0px 2px #0089c4;
}
.base-input._error {
  box-shadow: inset 0px 0px 0px 2px #d52b1e;
}
@media (max-width: 1378px) {
  .base-input {
    max-width: 100%;
  }
}
.base-input__message {
  max-width: 99%;
  resize: vertical;
  height: 179px;
}
.base-input__file-text {
  font-size: 15px;
  line-height: 28px;
  font-weight: 400;
  color: #646c77;
  padding-left: 10px;
}

.select__title {
  max-width: 295px;
  width: 100%;
  background-color: #ffffff;
  border: 1px solid #9ba7b2 !important;
  box-shadow: inset 0px 1px 1px 0px rgba(0, 0, 0, 0.14);
  border-radius: 5px !important;
  padding: 7px 18px;
  min-height: 46px;
  transition: all 0.3s ease 0s;
  font-family: "RobotoCondensed-Bold";
  font-size: 18px;
  line-height: 18px;
  color: #3b4957 !important;
  transition: all 0.3s ease 0s;
}
@media (max-width: 1378px) {
  .select__title {
    max-width: 100%;
  }
}
.select__value {
  padding: 0 !important;
  font-family: "RobotoCondensed-Bold";
  font-size: 18px !important;
  line-height: 18px;
  color: #3b4957 !important;
  text-transform: uppercase;
  position: relative;
}
@media (max-width: 991.98px) {
  .select__value {
    font-size: 17px !important;
  }
}
@media (max-width: 767.98px) {
  .select__value {
    font-size: 16px !important;
  }
}
@media (max-width: 479.98px) {
  .select__value {
    font-size: 15px !important;
  }
}
.select__value::before {
  opacity: 0 !important;
  visibility: hidden !important;
}
.select__value::after {
  content: "";
  position: absolute;
  top: 15px;
  right: 0;
  transform: translate(0, -50%);
  border-top: 5px solid #273646;
  border-left: 5px solid transparent;
  border-right: 5px solid transparent;
  transition: all 0.1s;
}
.select__options {
  min-width: 50% !important;
  max-width: 295px;
  width: 100%;
  background-color: #ffffff;
  border-top: none !important;
  border-bottom: 1px solid #9ba7b2 !important;
  border-left: 1px solid #9ba7b2 !important;
  border-right: 1px solid #9ba7b2 !important;
  box-shadow: inset 0px 1px 1px 0px rgba(0, 0, 0, 0.14);
  border-bottom-left-radius: 5px !important;
  border-bottom-right-radius: 5px !important;
  padding: 7px 18px;
  min-height: 46px;
  transition: all 0.3s ease 0s;
  font-family: "RobotoCondensed-Bold";
  font-size: 18px !important;
  line-height: 18px;
  color: #3b4957 !important;
  transition: all 0.3s ease 0s;
  top: 46px !important;
  position: absolute;
  left: 0;
  background-color: #fff;
  border-top: 0;
  border: 1px solid #d9d9d9;
  border-top: 0;
  text-transform: uppercase;
}
@media (max-width: 1378px) {
  .select__options {
    max-width: 100%;
  }
}
.select__option {
  padding: 10px 15px 10px 18px !important;
}

.select._error .select__title {
  box-shadow: inset 0px 0px 0px 2px #d52b1e !important;
}

.select._active .select__title {
  box-shadow: inset 0px 0px 0px 2px #0089c4;
  border-bottom-left-radius: 0 !important;
  border-bottom-right-radius: 0 !important;
}

#url {
  text-transform: none;
}

input[type=file] {
  display: none;
}

.custom-file-upload {
  width: 87px;
  height: 22px;
  border: 1px solid #ccc;
  display: inline-block;
  padding: 2px 6px 4px;
  cursor: pointer;
  font-family: Arial;
  font-size: 14px;
  line-height: 16px;
  color: #3b4957;
  font-weight: 600;
  border-radius: 4px;
  text-align: center;
}

.service-items {
  max-width: 100%;
  width: 100%;
}
.service-items__row {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
}
@media (max-width: 1378px) {
  .service-items__row {
    flex-direction: column;
  }
}
.service-items__item {
  display: flex;
  flex: 0 0 45%;
  max-width: 45%;
  padding-bottom: 35px;
  overflow: hidden;
}
@media (max-width: 1378px) {
  .service-items__item {
    flex: 0 0 100%;
    max-width: 100%;
  }
}
@media (max-width: 767.98px) {
  .service-items__item {
    padding-left: 0;
    padding-right: 20px;
    width: 100%;
  }
}
.service-items__box:first-child {
  flex: 0 0 60px;
}
.service-items__box:last-child {
  padding-left: 10px;
}
.service-items__icon {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 48px;
  height: 48px;
  border-radius: 11px;
  color: #fff;
  position: relative;
}
.service-items__icon::before {
  content: "";
  display: inline-block;
  background-repeat: no-repeat;
  background-position: center center;
  background-size: contain;
  width: 48px;
  height: 48px;
}
.service-items__title {
  font-family: "RobotoCondensed-Bold";
  font-size: 20px;
  line-height: 105%;
  color: #2c3e50;
  text-transform: uppercase;
}
@media (max-width: 991.98px) {
  .service-items__title {
    font-size: 19px;
  }
}
@media (max-width: 767.98px) {
  .service-items__title {
    font-size: 18px;
  }
}
@media (max-width: 479.98px) {
  .service-items__title {
    font-size: 17px;
  }
}
.service-items__text {
  padding-top: 15px;
  font-size: 16px;
  line-height: 140%;
  font-weight: 400;
  color: #4e6677;
  word-wrap: wrap;
}
@media (max-width: 479.98px) {
  .service-items__text {
    font-size: 14px;
  }
}
.service-items__link {
  display: inline-block;
  font-size: 16px;
  line-height: 24px;
  font-weight: 700;
  color: #3067bb;
  position: relative;
}
@media (max-width: 479.98px) {
  .service-items__link {
    font-size: 15px;
    text-decoration: underline;
  }
}
.service-items__link:after {
  content: "";
  width: 100%;
  background-color: #3067bb;
  height: 1px;
  display: block;
  position: absolute;
  bottom: 0;
  left: 50%;
  transform: translateX(-50%);
  transition: all 0.3s ease 0ms;
}
@media (max-width: 479.98px) {
  .service-items__link:after {
    width: 0;
  }
}
.service-items__link:hover {
  color: #dc240a;
}
.service-items__link:hover:after {
  width: 0;
  background-color: #fff;
}

.table-price .table__body {
  text-align: center;
}
.table-price .table__body td {
  padding: 5px !important;
}
.table-price ul {
  text-align: left;
  margin: 10px;
}
.table-price ul > li {
  line-height: 2;
}
.table-price__service-list a {
  text-decoration: underline;
  color: #0089c4;
  font-family: "Roboto";
}
.table-price__service-list a:hover {
  text-decoration: none;
  color: rgba(213, 43, 30, 0.85);
}
.table-price__sub-section {
  margin-top: 0 !important;
  margin-left: 10px !important;
  font-size: 12px;
}
.table-price__sub-section li {
  line-height: 2;
  list-style: disc;
  margin-left: 15px;
}

.table {
  max-width: 100%;
  width: 100%;
  border-collapse: collapse;
}
.table tr th,
.table tr td {
  border: 1px solid #9ba7b2;
}
.table tr th:first-of-type,
.table tr td:first-of-type {
  padding: 0 3px;
}
.table tr td {
  padding: 1px 5px 1px 15px;
}
.table tr td:first-of-type, .table tr td:nth-of-type(3), .table tr td:last-of-type {
  text-align: center;
}
.table tbody tr:nth-of-type(even) {
  background: #f1f2f5;
}
.table__head {
  font-family: "Roboto";
  font-size: 15px;
  font-weight: 700;
  color: #394a58;
  border-bottom: 2px solid #9ba7b2;
  padding: 12px 0;
}
.table__body {
  font-family: "Roboto";
  font-size: 15px;
  font-weight: 400;
  color: #646c77;
}

.search-result__title {
  font-size: 19px;
  line-height: 28px;
  font-weight: 400;
  color: #4e6677;
  padding-bottom: 30px;
  padding-top: 10px;
  border-bottom: 1px solid #9ba7b2;
}
@media (max-width: 991.98px) {
  .search-result__title {
    font-size: 18px;
  }
}
@media (max-width: 767.98px) {
  .search-result__title {
    font-size: 17px;
  }
}
@media (max-width: 479.98px) {
  .search-result__title {
    font-size: 16px;
  }
}
.search-result__result {
  padding-top: 20px;
  padding-bottom: 60px;
}
.search-result__item {
  border-bottom: 1px dotted #9ba7b2;
  padding: 5px 0 15px;
}
.search-result__link {
  font-family: "Roboto";
  font-size: 19px;
  line-height: 28px;
  font-weight: 400;
  color: #0089c4;
  transition: all 0.3s ease 0s;
}
@media (max-width: 991.98px) {
  .search-result__link {
    font-size: 18px;
  }
}
@media (max-width: 767.98px) {
  .search-result__link {
    font-size: 17px;
  }
}
@media (max-width: 479.98px) {
  .search-result__link {
    font-size: 16px;
  }
}
.search-result__link:hover {
  color: #d52b1e;
}
.search-result__descr {
  font-size: 12px;
  line-height: 100%;
  font-weight: 700;
  color: #394a58;
}
@media (max-width: 360px) {
  .search-result__resultTitle {
    display: block;
    padding-top: 2px;
  }
}
.search-result__date {
  font-weight: 400;
  line-height: 20px;
  color: #aeb3ba;
  padding-left: 10px;
  position: relative;
}
@media (max-width: 360px) {
  .search-result__date {
    display: block;
    padding-top: 5px;
    padding-left: 0;
  }
  .search-result__date::before {
    display: none;
  }
}
.search-result__date::before {
  content: "|";
  position: absolute;
  left: 3px;
}
.search-result__pagging {
  margin-top: 50px;
}

.errorPage__titleBox {
  margin: 0 auto;
  text-align: center;
}
.errorPage__title {
  font-family: "YanoneKaffeesatz-Bold";
  font-size: 160px;
  color: #394a58;
  text-align: center;
  position: relative;
  text-align: center;
  display: block;
  position: relative;
}
@media (max-width: 991.98px) {
  .errorPage__title {
    font-size: 140px;
  }
}
@media (max-width: 767.98px) {
  .errorPage__title {
    font-size: 120px;
  }
}
@media (max-width: 479.98px) {
  .errorPage__title {
    font-size: 100px;
  }
}
.errorPage__title:after {
  content: attr(data-title-text);
  color: #d52b1e;
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  bottom: 0;
  clip: rect(17px, 1000px, 80px, 0);
}
.errorPage__text {
  padding-top: 60px;
  font-family: "RobotoCondensed-Regular";
  font-size: 24px;
  line-height: 140%;
  color: #d52b1e;
  display: block;
  text-align: center;
}
@media (max-width: 991.98px) {
  .errorPage__text {
    font-size: 22px;
  }
}
@media (max-width: 767.98px) {
  .errorPage__text {
    font-size: 20px;
    padding-top: 50px;
  }
}
@media (max-width: 479.98px) {
  .errorPage__text {
    font-size: 18px;
    padding-top: 40px;
  }
}

.pagging {
  display: flex;
  align-items: center;
  justify-content: flex-start;
}
.pagging__arrow {
  width: 35px;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #ffffff;
  box-shadow: 0 0 0 1px #9ba7b2;
  font-size: 18px;
  line-height: 0;
  font-weight: 400;
  height: 36px;
  transition: all 0.3s ease 0s;
}
@media (max-width: 991.98px) {
  .pagging__arrow {
    font-size: 17px;
  }
}
@media (max-width: 767.98px) {
  .pagging__arrow {
    font-size: 16px;
  }
}
@media (max-width: 479.98px) {
  .pagging__arrow {
    font-size: 15px;
  }
}
.pagging__arrow:first-of-type {
  border-top-left-radius: 5px;
  border-bottom-left-radius: 5px;
}
.pagging__arrow:last-of-type {
  border-top-right-radius: 5px;
  border-bottom-right-radius: 5px;
}
.pagging__arrow:hover {
  background-color: #f3f3f3;
  color: #d52b1e;
}
.pagging__list {
  display: flex;
}
.pagging__list li {
  background-color: #ffffff;
  box-shadow: 0 0 0 1px #9ba7b2;
  height: 36px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: default;
}
.pagging__list a {
  padding: 18px 12px 16px;
  font-family: "Roboto";
  font-size: 18px;
  font-weight: 400;
  line-height: 0;
  letter-spacing: 0.01em;
  color: #394a58;
  transition: all 0.3s ease 0s;
}
@media (max-width: 991.98px) {
  .pagging__list a {
    font-size: 17px;
  }
}
@media (max-width: 767.98px) {
  .pagging__list a {
    font-size: 16px;
  }
}
@media (max-width: 479.98px) {
  .pagging__list a {
    font-size: 15px;
  }
}
.pagging__list a:hover {
  box-shadow: 0 0 0 1px #9ba7b2;
  background-color: #f3f3f3;
  color: #d52b1e;
}
.pagging__item {
  width: 35px;
}
.pagging__item._active {
  box-shadow: 0 0 0 1px #394a58;
  background-color: #394a58;
  color: #fff;
  cursor: default;
}
.pagging__item._active:hover {
  background-color: #394a58;
  color: #fff;
  pointer-events: none;
  cursor: default;
}

span.arrow-left {
  display: block;
  width: 8px;
  height: 8px;
  border-top: 2px solid #394a58;
  border-left: 2px solid #394a58;
  transform: rotate(-45deg);
  position: absolute;
  top: 14px;
  left: 17px;
  transition: all 0.3s ease 0s;
}

span.arrow-right {
  display: block;
  width: 8px;
  height: 8px;
  border-top: 2px solid #394a58;
  border-right: 2px solid #394a58;
  transform: rotate(45deg);
  position: absolute;
  top: 14px;
  left: 12px;
  transition: all 0.3s ease 0s;
}

.pagging__arrow:hover .arrow-left {
  border-top: 2px solid #d52b1e;
  border-left: 2px solid #d52b1e;
}

.pagging__arrow:hover .arrow-right {
  border-top: 2px solid #d52b1e;
  border-right: 2px solid #d52b1e;
}

.btn {
  display: inline-flex;
  padding: 0px 0px;
}
.btn._fw {
  width: 100%;
}

.select {
  position: relative;
  line-height: 1;
}
.select__item {
  position: relative;
}
.select__title {
  color: #000;
  border: 1px solid #d9d9d9;
  background-color: #fff;
  cursor: pointer;
  border-radius: 4px;
}
@media (max-width: 991.98px) {
  .select__title {
    font-size: 18px;
  }
}
.select__value {
  display: flex;
  font-size: 14px;
  height: 30px;
  padding: 0px 15px;
  align-items: center;
  justify-content: space-between;
  flex-direction: row-reverse;
}
.select__value span {
  height: 1em;
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
}
.select__value:before {
  content: "";
  display: inline-block;
  transition: all 0.3s ease 0s;
  width: 11px;
  height: 7px;
}
.select__input {
  width: 100%;
  background-color: transparent;
  height: 100%;
}
.select__options {
  color: #000;
  display: none;
  position: absolute;
  top: 29px;
  border-radius: 0 0 4px 4px;
  min-width: 100%;
  left: 0;
  background-color: #fff;
  border-top: 0;
  border: 1px solid #d9d9d9;
  border-top: 0;
  font-size: 14px;
  padding: 10px 0px 5px 0px;
}
@media (max-width: 991.98px) {
  .select__options {
    font-size: 18px;
  }
}
.select__option {
  cursor: pointer;
  padding: 5px 15px;
  margin: 0px 0px 0px 0px;
}
@media (min-width: 992px) {
  .select__option:hover {
    background: #d9d9d9;
  }
}
.select._active {
  z-index: 5;
}
.select._active .select__value:before {
  transform: rotate(-180deg);
}
.select._active .select__options {
  display: block;
}

input[type=text],
input[type=email],
input[type=tel],
textarea {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
}

.input {
  border-radius: 0 !important;
  width: 100%;
  display: block;
  padding: 0px 20px;
  font-size: 20px;
  height: 50px;
}
.input._error {
  border: 2px solid red;
}

textarea.input {
  resize: none;
  padding: 0px 0px;
}

.checkbox {
  position: relative;
}
.checkbox__input {
  position: absolute;
  width: 0;
  height: 0;
  opacity: 0;
  visibility: hidden;
}
.checkbox__input:checked + .checkbox__text:before {
  background: #fff url("../img/icons/check.svg") center no-repeat;
}
.checkbox__text {
  display: inline-flex;
  align-items: center;
  position: relative;
  font-size: 16px;
  color: #ffffff;
  letter-spacing: -0.3px;
  line-height: 1.25;
  cursor: pointer;
}
.checkbox__text:before {
  content: "";
  align-self: flex-start;
  margin: 0px 14px 0px 0px;
  flex: 0 0 20px;
  left: 0;
  top: 0;
  width: 20px;
  height: 18px;
  background: #fff;
  border: 1px solid #a7a9ac;
}
.checkbox a {
  color: #fff;
  text-decoration: underline;
}
@media (min-width: 992px) {
  .checkbox a:hover {
    text-decoration: none;
  }
}
.checkbox._error .checkbox__text:before {
  border: 1px solid #ee1212;
}

.options {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}
.options__item {
  position: relative;
  cursor: pointer;
}
.options__input {
  position: absolute;
  width: 0;
  height: 0;
  opacity: 0;
  left: 0;
  top: 0;
  visibility: hidden;
}
.options__input:checked + .options__text:after {
  transform: scale(1);
}
.options__text {
  display: inline-flex;
  align-items: center;
  position: relative;
  font-size: 16px;
  color: #ffffff;
  letter-spacing: -0.3px;
  line-height: 1.25;
}
.options__text:before {
  content: "";
  align-self: flex-start;
  margin: 0px 14px 0px 0px;
  flex: 0 0 20px;
  left: 0;
  top: 0;
  border-radius: 50%;
  width: 20px;
  height: 18px;
  background: #fff;
  border: 1px solid #a7a9ac;
}
.options__text:after {
  content: "";
  transition: all 0.3s ease 0s;
  transform: scale(0);
  width: 10px;
  height: 10px;
  border-radius: 50%;
  background-color: #77243a;
  position: absolute;
  left: 5px;
  top: 4px;
}

.rating-block {
  display: table;
  font-size: 0;
}

.rating {
  display: inline-block;
  position: relative;
  vertical-align: middle;
}
.rating.edit .star {
  cursor: pointer;
}
.rating .star {
  width: 17px;
  height: 14px;
  display: inline-block;
  position: relative;
  z-index: 3;
}

.rating__line {
  position: absolute;
  width: 85px;
  height: 14px;
  top: 0;
  left: 0;
  background: url("../img/icons/bg_rating.svg") 0 0 no-repeat;
  z-index: 1;
  background-size: 85px 100%;
}

.rating__activeline {
  position: absolute;
  width: 0px;
  height: 14px;
  top: 0;
  left: 0;
  background: url("../img/icons/bg_rating_active.svg") 0 0 no-repeat;
  z-index: 2;
  background-size: 85px 100%;
}

.rating__value {
  display: inline-block;
  color: #1b3139;
  font-size: 14px;
  line-height: 13px;
  vertical-align: middle;
}

.quantity {
  width: 88px;
  height: 40px;
  display: flex;
  border: 1px solid rgba(166, 166, 166, 0.45);
}
.quantity__button {
  flex: 0 0 30px;
  position: relative;
  cursor: pointer;
}
.quantity__button::before, .quantity__button::after {
  content: "";
  position: absolute;
  top: 50%;
  left: 50%;
  transition: all 0.3s ease 0s;
  margin: 0px 0px 0px -4.5px;
  background-color: #a6a6a6;
  width: 9px;
  height: 1px;
}
@media (min-width: 991.98px) {
  .quantity__button:hover::before, .quantity__button:hover::after {
    background-color: rgba(0, 0, 0, 0.5);
  }
}
.quantity__button_plus::before {
  transform: rotate(-90deg);
}
.quantity__input {
  flex: 1 1 auto;
}
.quantity__input input {
  height: 100%;
  color: #a6a6a6;
  font-size: 12px;
  width: 100%;
  text-align: center;
}

.row:after {
  display: block;
  content: "";
  clear: both;
}

.rub:after {
  content: "₽";
}

ol.counter {
  list-style-type: none;
  counter-reset: item;
}
ol.counter li {
  position: relative;
  padding: 0px 0px 0px 45px;
}
ol.counter li:before {
  counter-increment: item;
  content: counter(item);
  position: absolute;
  left: 0;
  top: 0;
  color: #818181;
  font-size: 14px;
  font-weight: 700;
  text-align: center;
  line-height: 26px;
  border-radius: 50%;
  width: 28px;
  height: 28px;
  border: 1px solid #4274bb;
}

.ellipsis {
  display: block;
  overflow: hidden;
  width: 100%;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.es {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.table {
  display: table;
  font-size: 0;
  width: 100%;
}

.trow {
  display: table-row;
}

.cell {
  display: table-cell;
}
.cell.full {
  width: 100%;
}

._ibg {
  position: relative;
}
._ibg img {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  object-fit: cover;
}

body.ie ._ibg {
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
}
body.ie ._ibg img {
  width: 0;
  height: 0;
  opacity: 0;
  visibility: hidden;
}

.video {
  position: relative;
  overflow: hidden;
  height: 0;
  padding-bottom: 56.25%;
}
.video video,
.video iframe,
.video object,
.video embed {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.videobg video,
.videobg iframe,
.videobg object,
.videobg embed {
  position: fixed;
  top: 50%;
  left: 50%;
  min-width: 100%;
  min-height: 100%;
  width: auto;
  height: auto;
  z-index: -100;
  transform: translateX(-50%) translateY(-50%);
  background-size: cover;
}

.moretext {
  overflow: hidden;
}

.moretext__more {
  cursor: pointer;
}
.moretext__more span {
  font-style: normal;
}
.moretext__more span:first-child {
  display: block;
}
.moretext__more span:last-child {
  display: none;
}
.moretext__more.active span {
  font-style: normal;
}
.moretext__more.active span:first-child {
  display: none;
}
.moretext__more.active span:last-child {
  display: block;
}

#map {
  background: url("../img/icons/loading.gif") center/50px no-repeat;
}

._swiper {
  overflow: hidden;
}
._swiper > div {
  width: 100%;
  height: 100%;
  box-sizing: content-box;
  display: flex;
  position: relative;
}
._swiper.swiper-container-autoheight > div {
  align-items: flex-start;
}

.swiper-container-initialized .swiper-slide {
  flex-shrink: 0;
}

.swiper-container-android .swiper-slide,
.swiper-container-android .swiper-wrapper {
  transform: translate3d(0px, 0, 0);
}

.swiper-button-lock {
  display: none !important;
}

.slick-slider {
  position: relative;
}
.slick-slider .slick-track,
.slick-slider .slick-list {
  -webkit-transform: translate3d(0, 0, 0);
  -moz-transform: translate3d(0, 0, 0);
  -ms-transform: translate3d(0, 0, 0);
  -o-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0);
}
.slick-slider .slick-list {
  position: relative;
  overflow: hidden;
  width: 100%;
}
.slick-slider .slick-track {
  position: relative;
  width: 100%;
  display: flex;
}
.slick-slider .slick-slide {
  position: relative;
}
.tab__item {
  display: none;
}
.tab__item.active {
  display: block;
}

._tabs-block {
  display: none;
}
._tabs-block._active {
  display: block;
}

.mirror {
  transform: scale(-1, 1);
}

.nicescroll-rails {
  z-index: 1000 !important;
}

.gm-style-iw-t {
  opacity: 0;
}

.baloon {
  opacity: 1;
  right: -7px !important;
  bottom: 80px !important;
}
.baloon button {
  display: none !important;
}
.baloon:after {
  display: none !important;
}

.baloon-style {
  display: none;
}

.baloon-content.gm-style-iw {
  opacity: 1;
  border-radius: 0px !important;
  max-width: 300px !important;
  padding: 0 !important;
  left: 0 !important;
  width: 100% !important;
  overflow: visible !important;
}
.baloon-content.gm-style-iw > .gm-style-iw-d {
  overflow: hidden !important;
  max-width: none !important;
}
.baloon-content.gm-style-iw:after {
  display: none !important;
}

.baloon-close {
  top: 18px !important;
}

._custom-scroll {
  position: fixed;
  right: 0;
  top: 0;
  width: 3px;
  height: 100%;
  z-index: 1000;
}
._custom-scroll__line {
  background-color: rgba(0, 0, 0, 0.5);
  border-radius: 3px 0 0 3px;
  position: relative;
  left: 0;
  top: 0;
  width: 100%;
}

.m-0 {
  margin: 0 !important;
}

.btn {
  display: flex;
  justify-content: center;
  align-items: center;
  flex: 0 0 99px;
  max-width: 99px;
  padding: 5px 12px 2px;
  height: 46px;
  border-radius: 5px;
  box-shadow: 0px 4px 2px -1px rgba(0, 0, 0, 0.15);
  transition: all 0.3s ease 0s;
  font-family: "RobotoCondensed-Bold";
  font-size: 18px;
  text-shadow: -1px -1px 0px rgba(0, 0, 0, 0.25);
  text-transform: uppercase;
}
.btn__blue {
  background-color: #0089c4;
  border: 1px solid #20638f;
  color: #fff;
}
.btn__blue--long {
  max-width: 195px;
  width: 100%;
}
@media (min-width: 767.98px) {
  .btn__blue:hover, .btn__blue:active {
    background-color: #0179ad;
  }
}
.btn__yellow {
  background-color: #f4de4b;
  border: 1px solid #ccbc4c;
  color: #2c3e50;
}
@media (min-width: 767.98px) {
  .btn__yellow:hover, .btn__yellow:active {
    background-color: #e3cd39;
  }
}
.btn__red {
  background-color: #d52b1e;
  border: 1px solid #e12a1d;
  color: #fff;
}
@media (min-width: 767.98px) {
  .btn__red:hover, .btn__red:active {
    background-color: #b42419;
  }
}
.btn__green {
  background-color: #009b74;
  border: 1px solid #148f77;
  color: #ffffff;
}
.btn__green--long {
  max-width: 370px;
  width: 100%;
  height: 58px;
  font-family: "RobotoCondensed-Bold";
  font-size: 23px;
  line-height: 18px;
  text-shadow: -1px -1px 0px rgba(0, 0, 0, 0.25);
  padding-bottom: 4px;
}
@media (max-width: 479.98px) {
  .btn__green--long {
    max-width: 260px;
  }
}
@media (min-width: 767.98px) {
  .btn__green:hover, .btn__green:active {
    background-color: #048464;
  }
}
@media (max-width: 767.98px) {
  .btn__green {
    font-size: 21px;
  }
}
@media (max-width: 479.98px) {
  .btn__green {
    font-size: 18px;
    height: 40px;
  }
}

.h2-title {
  font-family: "YanoneKaffeesatz-Bold";
  font-size: 72px;
  line-height: 108%;
  text-transform: uppercase;
  letter-spacing: 1.5px;
}
@media (max-width: 1378px) {
  .h2-title {
    font-size: 66px;
  }
}
@media (max-width: 991.98px) {
  .h2-title {
    font-size: 54px;
  }
}
@media (max-width: 767.98px) {
  .h2-title {
    font-size: 48px;
  }
}
@media (max-width: 479.98px) {
  .h2-title {
    font-size: 36px;
    letter-spacing: 0.65px;
  }
}

.h4-title {
  font-family: "RobotoCondensed-Bold";
  font-size: 30px;
  line-height: 100%;
}
@media (max-width: 1378px) {
  .h4-title {
    font-size: 26px;
  }
}
@media (max-width: 991.98px) {
  .h4-title {
    font-size: 22px;
  }
}
@media (max-width: 767.98px) {
  .h4-title {
    font-size: 18px;
  }
}

.h5-title {
  font-family: "RobotoCondensed-Regular";
  font-size: 22px;
  line-height: 144%;
  letter-spacing: -0.2px;
}
@media (max-width: 1378px) {
  .h5-title {
    font-size: 20px;
  }
}
@media (max-width: 991.98px) {
  .h5-title {
    font-size: 18px;
  }
}
@media (max-width: 767.98px) {
  .h5-title {
    font-size: 16px;
  }
}

.paragraph {
  font-size: 22px;
  line-height: 144%;
  font-weight: 400;
}
@media (max-width: 1378px) {
  .paragraph {
    font-size: 18px;
  }
}
@media (max-width: 991.98px) {
  .paragraph {
    font-size: 16px;
  }
}
@media (max-width: 767.98px) {
  .paragraph {
    font-size: 14px;
  }
}

.tabs {
  height: 100%;
}
.tabs__nav ul {
  display: flex;
  justify-content: center;
  align-items: flex-end;
}
.tabs__nav li {
  flex: 0 0 135px;
}
@media (max-width: 1378px) {
  .tabs__nav li {
    flex: 0 0 115px;
  }
}
@media (max-width: 479.98px) {
  .tabs__nav li {
    flex: 0 0 7%;
  }
}
.tabs__nav li:last-child {
  flex: 0 0 100px;
}
@media (max-width: 1378px) {
  .tabs__nav li:last-child {
    flex: 0 0 80px;
  }
}
@media (max-width: 479.98px) {
  .tabs__nav li:last-child {
    flex: 0 0 5%;
  }
}
.tabs__nav-item {
  display: block;
  width: 100%;
  height: 100%;
}
.tabs__nav-icon {
  display: flex;
  justify-content: center;
  position: relative;
  padding: 7px 5px 15px;
  text-align: center;
  cursor: pointer;
  font-size: 0;
  transition: color 0.15s ease-in, background-color 0.15s ease-in;
  overflow: hidden;
}
.tabs__nav-icon::after {
  content: "";
  display: block;
  width: 1px;
  height: 2px;
  background-color: #fff;
  position: absolute;
  bottom: 0px;
  left: 50%;
  transform: translateX(-50%);
  transition: all 0.3s ease 0s;
}
.tabs__nav-icon:hover::after {
  width: 60%;
  background-color: #2d3a4c;
}
.tabs__inner-item {
  transition: all 0.3s ease 0s;
  position: absolute;
  opacity: 0;
  visibility: hidden;
  overflow: hidden;
  height: 0;
}
.tabs__inner-item--active {
  position: static;
  opacity: 1;
  visibility: visible;
  height: auto;
}
.tabs__inner-box {
  margin: 30px auto 0;
  max-width: 100%;
  display: flex;
  justify-content: center;
}
.tabs .desktop-box {
  padding-bottom: 5px;
}
@media (max-width: 767.98px) {
  .tabs .desktop-box {
    padding-bottom: 0;
  }
}
.tabs .tablet-box {
  margin-top: 35px;
  margin-left: auto;
}
@media (max-width: 767.98px) {
  .tabs .tablet-box {
    margin-top: 30px;
  }
}
.tabs .mobile-box {
  margin-top: 35px;
  margin-left: auto;
}
@media (max-width: 767.98px) {
  .tabs .mobile-box {
    margin-top: 30px;
  }
}
.tabs__inner-box figure {
  position: relative;
}
.tabs .device-image {
  overflow: hidden;
  object-fit: contain;
  z-index: 1;
  max-height: 712px;
  height: 100%;
  max-width: 1189.13px;
  width: 100%;
  object-fit: contain;
}
@media (max-width: 1620px) {
  .tabs .device-image {
    max-width: 1500px;
    width: 100%;
  }
}
@media (max-width: 991.98px) {
  .tabs .device-image {
    max-height: 450px;
  }
}
@media (max-width: 767.98px) {
  .tabs .device-image {
    max-height: 350px;
  }
}
@media (max-width: 640px) {
  .tabs .device-image {
    max-height: 250px;
  }
}
@media (max-width: 479.98px) {
  .tabs .device-image {
    max-height: 200px;
  }
}
@media (max-width: 380px) {
  .tabs .device-image {
    max-height: 175px;
  }
}
.tabs__inner-box img {
  position: relative;
  overflow: hidden;
  left: 50%;
  transform: translateX(-50%);
}

.tablet-icon:hover:after {
  width: 28%;
}

.mobile-icon:hover:after {
  width: 25%;
}

.active-tab .tabs__nav-icon::after {
  background-color: #2d3a4c;
  width: 60%;
}
.active-tab .tablet-icon:after {
  width: 28%;
}
.active-tab .mobile-icon:after {
  width: 25%;
}

.visually-hidden {
  position: absolute;
  width: 1px;
  height: 1px;
  margin: -1px;
  border: 0;
  padding: 0;
  white-space: nowrap;
  -webkit-clip-path: inset(100%);
  clip-path: inset(100%);
  clip: rect(0 0 0 0);
  overflow: hidden;
}

.desktop-icon svg {
  display: block;
  width: 82px;
  height: 50px;
  fill: #d5dde5;
  transition: all 0.3s ease 0s;
}
.desktop-icon:hover svg {
  fill: #2d3a4c;
}

.tablet-icon svg {
  display: block;
  width: 86px;
  height: 50px;
  fill: #d5dde5;
  transition: all 0.3s ease 0s;
}
.tablet-icon:hover svg {
  fill: #2d3a4c;
}

.mobile-icon svg {
  display: block;
  width: 45px;
  height: 45px;
  fill: #d5dde5;
  transition: all 0.3s ease 0s;
}
.mobile-icon:hover svg {
  fill: #2d3a4c;
}

.active-tab .desktop-icon svg,
.active-tab .tablet-icon svg,
.active-tab .mobile-icon svg {
  fill: #2d3a4c;
}

.searchForm {
  display: block;
  margin: 0 auto;
  width: 100%;
}
.searchForm__block {
  border: 2px solid #b1b5bb;
  background-color: #f9fbfd;
  border-radius: 100px;
  padding: 3px 50px 4px 25px;
  transition: background-color 0.5 ease-in-out;
  position: relative;
}
.searchForm__block input {
  width: 100%;
  border: 0;
  background-color: transparent;
  width: 100%;
  font-family: "Roboto";
  font-size: 18px;
  line-height: 18px;
  font-weight: 400;
  color: #2c3e50;
  white-space: nowrap;
  text-overflow: ellipsis;
}
.searchForm__block input::placeholder {
  font-family: "Roboto";
  font-size: 16px;
  font-weight: 400;
  color: #97acc0;
}
.searchForm__block input:focus {
  outline: none;
}
.searchForm__block button,
.searchForm__block i {
  border: none;
  background: none;
  cursor: pointer;
}
.searchForm__block select {
  border: none;
}

.page {
  flex: 1 1 auto;
  display: flex;
  max-width: 2560px;
  width: 100%;
  margin-left: auto;
  margin-right: auto;
}
.page__content {
  display: flex;
}
@media (max-width: 991.98px) {
  .page__content {
    flex-direction: column-reverse;
  }
}
.page__side {
  flex: 0 0 408px;
  padding: 0px;
  background-color: #f1f2f5;
}
@media (max-width: 991.98px) {
  .page__side {
    flex: 0 0 100%;
  }
}
.page__item {
  background-color: #fff;
}
@media (max-width: 767.98px) {
  .page__item {
    padding-left: 20px;
    padding-right: 20px;
  }
}
@media (max-width: 479.98px) {
  .page__item {
    padding-left: 0;
    padding-right: 0;
  }
}
.page__body {
  flex: 1 1 auto;
  min-width: 0;
  position: relative;
  z-index: 8;
}

button {
  background-color: transparent;
}

.slider {
  margin: 0px 0px 50px 0px;
}

.text {
  transform: translate(0px, 100px);
  opacity: 0;
  transition: all 0.3s ease 0s;
  margin: 0px 0px 50px 0px;
}
.text a {
  color: red;
}
.text._active {
  transform: translate(0px, 0px);
  opacity: 1;
}