.header._scroll {
  margin-top: -5px;
  z-index: 10;
  top: 119px;
  position: fixed;
  left: 0;
  right: 0;
  background-color: rgba(255, 255, 255, 0.95);
  @media (max-width: 1378px) {
    top: 105px;
  }
  @media (max-width: $md2+px) {
    top: 90px;
  }
  @media (max-width: $md3+px) {
    top: 85px;
  }
  @media (max-width: 598px) {
    top: 110px;
  }
  @media (max-width: $md4+px) {
    top: 115px;
  }
  @media (max-width: 401px) {
    top: 140px;
  }
  // .menu__body._active {
  //   @media (max-width: 1199px) {
  //     padding-top: 80px !important;
  //   }
  //   @media (max-width: $md3+px) {
  //     padding-top: 0 !important;
  //     padding-right: 0;
  //   }
  // }
}

.offer-block:not(.close-block) ~ .header .menu__body {
  @media (max-width: 1199px) {
    top: 100px;
  }
  @media (max-width: $md2+px) {
    top: 85px;
    padding-top: 70px;
    padding-left: 2px;
  }
  @media (max-width: $md3+px) {
    padding-top: 10px;
  }
  @media (max-width: 599px) {
    top: 109px;
    padding-top: 10px;
  }
  @media (max-width: $md4+px) {
    top: 100px;
    padding-left: 0px;
    .mobile-logo {
      margin-left: 15px;
      margin-top: 10px;
    }
  }
  @media (max-width: 401px) {
    top: 125px;
    padding-top: 10px;
    .mobile-logo {
      margin-left: 14px;
    }
  }
}

.offer-block:not(.close-block) {
  padding-bottom: 10px;
}

// .offer-block:not(.close-block) + .header .menu__body._active {
//   @media (max-width: 1199px) {
//     padding-top: 160px;
//   }
//   @media (max-width: $md3+px) {
//     padding-top: 94px;
//     padding-right: 0;
//     padding-left: 5px;
//   }
//   @media (max-width: 598px) {
//     padding-top: 121px;
//   }
//   @media (max-width: 475px) {
//     padding-left: 0;
//   }
//   @media (max-width: 401px) {
//     padding-top: 148px;
//   }
// }
// .offer-block:not(.close-block) ~ .menu__body._active .mobile-logo {
//   @media (max-width: $md3+px) {
//     margin-left: 20px;
//   }
//   @media (max-width: 598px) {
//     margin-top: 42px;
//   }
//   @media (max-width: 475px) {
//     margin-top: 38px;
//   }
//   @media (max-width: 401px) {
//     margin-top: 64px;
//   }
// }
.offer-block.close-block ~ .promo-header {
  margin-top: 50px;
  @media (max-width: 1378px) {
    margin-top: 30px;
  }
  @media (max-width: 1199px) {
    margin-top: -25px;
  }
  @media (max-width: $md2+px) {
    margin-top: 50px;
  }
  @media (max-width: $md4+px) {
    margin-top: 30px;
  }
}
.header {
  position: fixed;
  // position: static;
  background-color: #fff;
  box-shadow: 0px -2px 5px 0px #000000;
  max-width: 2560px;
  margin-left: auto;
  margin-right: auto;
  width: 100%;
  // top: 70px;
  top: 114px;
  left: 0;
  right: 0;
  // padding: 0 15px;
  z-index: 10;
  margin-bottom: -15px;
  height: 87px;
  &__two {
    margin-top: 0 !important;
    margin-bottom: -20px;
    top: 0 !important;
  }
  @media (max-width: 1378px) {
    height: 76px;
    top: 100px;
    // top: 60px;
  }
  @media (max-width: 1199px) {
    height: 68px;
    // top: 60px;
  }
  @media (max-width: $md2+px) {
    top: 85px;
  }
  @media (max-width: $md3+px) {
    top: 80px;
  }
  @media (max-width: 598px) {
    top: 105px;
  }
  @media (max-width: $md4+px) {
    top: 110px;
  }
  @media (max-width: 401px) {
    top: 135px;
  }
  // @media (max-width: 1108px) {
  //   height: 68px;
  //   top: 88px;
  // }
  // @media (max-width: $md2+px) {
  //   top: 57px;
  // }
  @media (max-width: $md4+px) {
    height: 48px;
  }
}
.offer-block {
  background-color: #18ae90;
  max-width: 2560px;
  margin: 0 auto;
  width: 100%;
  padding-top: 20px;
  padding-bottom: 20px;
  padding-left: 55px;
  padding-right: 55px;
  color: #fff;
  font-family: 'RobotoCondensed-Regular';
  font-size: 28px;
  text-align: center;
  line-height: 150%;
  letter-spacing: 0.7px;
  // position: fixed;
  // top: 0;
  z-index: 11;
  box-shadow: inset 0px 0px 20px 0px rgba(#000000, 0.3);
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  // transform: translateY(-100%);
  transition: all 0.5s;
  @media (max-width: $md1+px) {
    font-size: 24px;
  }
  @media (max-width: $md2+px) {
    font-size: 20px;
  }
  @media (max-width: $md3+px) {
    font-size: 18px;
    background-color: #009b74;
  }
  @media (max-width: $md4+px) {
    font-size: 18px;
  }
  &.close-block {
    display: none;
  }

  a{
    color: #FFF;
  }
  button {
    position: absolute;
    right: 15%;
    top: 50%;
    transform: translateY(-50%);
    @media (max-width: 1378px) {
      right: 40px;
    }
    @media (max-width: $md3+px) {
      right: 20px;
    }
  }
}

.close-btn {
  display: block;
  width: 30px;
  height: 30px;
  cursor: pointer;
  z-index: 30;
  background: url('../img/icons/close.svg') center center no-repeat;
}
.offer-block.close-block ~ .header._scroll {
  top: 5px;
}
.offer-block.close-block ~ .header {
  top: 0px;
}
// .show-offer {
//   transform: translateY(0);
// }

.top-header {
  &__content {
    display: flex;
    justify-content: space-between;
    position: relative;
  }
  &__column {
    flex: 0 0 65%;
    &:first-child {
      flex: 0 0 362px;
      @media (max-width: 1378px) {
        flex: 0 0 300px;
      }
      @media (max-width: 1199px) {
        flex: 0 0 262px;
      }
    }
    &:last-child {
      margin-right: -190px;
      transition: all 0.3s ease 0s;
      @media (max-width: 1820px) {
        margin-right: -100px;
      }
      @media (max-width: 1680px) {
        margin-right: 0px;
      }
      @media (max-width: 1378px) {
        margin-right: -50px;
        flex: 0 0 80%;
      }
      @media (max-width: 1199px) {
        flex: 0 0 100%;
      }
    }
  }
  &__logo {
    display: block;
    max-width: 364px;
    width: 100%;
    margin-top: 15px;
    transition: all 0.3s;
    img {
      transition: all 0.3s;
      object-fit: contain;
      width: 100%;
    }
    @media (max-width: 1378px) {
      max-width: 300px;
      img {
        width: 100%;
      }
    }
    @media (max-width: 479px) {
      max-width: 180px;
      margin-top: 10px;
    }
  }
}
.mobile-logo {
  display: none;
  @media (max-width: 768px) {
    display: block;
    margin-left: 17px;
    margin-top: 2px;
    width: 262px;
  }
}
.offer-block:not(.close-block) ~ .mobile-logo {
  @media (max-width: $md4+px) {
    margin-top: 10px;
  }
}
.header__two .mobile-logo {
  @media (max-width: $md3+px) {
    margin-left: 20px;
    margin-top: 15px;
  }
  @media (max-width: $md4+px) {
    margin-left: 13px;
    margin-top: 10px;
  }
}
.offer-block.close-block ~ .header .mobile-logo {
  @media (max-width: $md3+px) {
    margin-top: 15px;
    margin-left: 19px;
  }
  @media (max-width: $md4+px) {
    margin-top: 10px;
    margin-left: 15px;
  }
}
.header__two > .mobile-logo {
  @media (max-width: 768px) {
    margin-left: 20px;
  }
}

.menu {
  &__body {
    @media (max-width: 1199px) {
      transform: translateX(1000%);
      opacity: 0;
      visibility: hidden;
      position: fixed;
      height: 100%;
      width: 50%;
      top: 0;
      right: 0;
      overflow: auto;
      background-color: #fff;
      padding: 80px 0px 30px 0px;
      z-index: 98;
      -webkit-transition: all 0.3s ease 0s;
      -o-transition: all 0.3s ease 0s;
      transition: all 0.3s ease 0s;
      border-left: 1px solid #000;
      box-shadow: -2px 0px 15px 0px rgba(0, 0, 0, 0.51);
      &._active {
        opacity: 1;
        visibility: visible;
        transform: translateX(0);
        transition: all 0.3s ease 0s;
      }
    }
    @media (max-width: 768px) {
      width: 100%;
      padding-top: 0px;
      transform: translateY(-1000%);
      &._active {
        transform: translateY(0);
      }
    }
  }
  // .menu__list
  &__list {
    display: flex;
    justify-content: flex-end;
    @media (max-width: 1199px) {
      display: block;
    }
  }
  &__list-item {
    margin: 0;
    transition: all 0.3s ease 0s;
    position: relative;
    @media (min-width: 1200px) {
      &:hover {
        background-color: #394a58;
        .dropdown {
          color: #fff;
        }
        .dropdown::before {
          top: 48%;
          border-top: none;
          border-bottom: 5px solid #fff;
        }
      }
      &.login-button:hover {
        background: none;
      }
    }
    @media (max-width: 1199px) {
      text-align: right;
    }
    &:first-child {
      margin: 20px 20px 0 0;
      transition: all 0.3s ease 0s;
      @media (max-width: 1600px) {
        margin: 20px 10px 0 0;
      }
      @media (max-width: 1199px) {
        margin: 22px 0;
        padding-right: 80px;
      }
      @media (max-width: $md3+px) {
        padding-right: 25px;
      }
      &:hover {
        background: none;
        color: #009b74;
      }
    }
    &.login-button {
      margin-top: 19px;
      margin-right: 0;
      margin-left: 40px;
      transition: all 0.3s ease 0s;
      @media (max-width: 1600px) {
        margin-left: 30px;
      }
      @media (max-width: 1199px) {
        padding-right: 80px;
        margin-left: 0;
      }
      @media (max-width: $md3+px) {
        padding-right: 25px;
      }
    }
  }
  // .menu__link
  &__link {
    display: inline-block;
    background: none;
    padding: 32px 30px 32px 25px;
    font-family: 'RobotoCondensed-Bold';
    font-size: 18px;
    line-height: 113%;
    color: #273646;
    position: relative;
    text-transform: uppercase;
    @media (max-width: 1378px) {
      padding: 29px 30px 29px 25px;
    }
    @media (max-width: 1199px) {
      // padding-right: 18px;
      margin-left: auto;
    }
    @media (max-width: 1378px) {
      font-size: 16px;
    }
    //  &._active {
    //    color: red;
    //  }
  }
  &__btn {
    width: 99px;
    padding: 10px 12px;
  }
}
@media (max-width: 1199px) {
  .menu__list-item:hover {
    background: none;
    .dropdown.expanded {
      color: inherit;
      transition: all 0.3s ease 0s;
    }
  }
}
.dropdown {
  transition: all 0.3s ease 0s;
  @media (max-width: 1199px) {
    padding: 25px 30px 25px 25px;
    padding-right: 100px;
    display: block;
    transition: all 0.3s ease 0s;
    &:hover {
      background-color: #394a58;
      color: #fff;
      &:before {
        border-top: 5px solid #fff;
      }
    }
  }
  @media (max-width: $md3+px) {
    padding-right: 50px;
  }
  &::before {
    content: '';
    position: absolute;
    top: 50%;
    right: 13px;
    transform: translate(0, -50%);
    border-top: 5px solid #273646;
    border-left: 5px solid transparent;
    border-right: 5px solid transparent;
    transition: all 0.1s;
    @media (max-width: 1199px) {
      right: 80px;
    }
    @media (max-width: $md3+px) {
      right: 28px;
    }
  }
}

@media (max-width: 1199px) {
  .dropdown.expanded {
    transition: all 0.3s ease 0s;
    &::before {
      top: 48%;
      border-top: none;
      border-bottom: 5px solid #273646;
    }
    &:hover {
      background: none;
      color: inherit;
    }
  }
}
.submenu {
  &__list {
    background-color: #394a58;
    border-radius: 5px;
    border-top-right-radius: 0;
    box-shadow: 0px 30px 25px 0px rgba(0, 0, 0, 0.5);
    padding: 35px 0 35px 0;
    position: absolute;
    top: 82px;
    right: 0;
    transition: all 0.3s ease 0s;
    transform: translateY(-5px);
    opacity: 0;
    visibility: hidden;
    @media (max-width: 1378px) {
      top: 76px;
    }
    @media (max-width: 1199px) {
      transition: all 0.3s ease 0s;
      display: none;
      position: static;
      opacity: 1;
      visibility: visible;
      box-shadow: none;
      border-radius: 0;
      padding: 0;
    }
    li:first-child a {
      padding-top: 20px;
    }
    li:last-child a {
      padding-bottom: 20px;
    }
  }

  &__link {
    font-size: 18px;
    padding: 0 100px 0 35px;
    line-height: 113%;
    color: #fff;
    text-transform: none;
    white-space: nowrap;
    transition: all 0.3s ease 0s;
    &:hover,
    &:active {
      color: #f4de4b;
    }
    @media (max-width: 1378px) {
      font-size: 16px;
    }
    @media (max-width: 1199px) {
      display: block;
      padding: 13px 80px 13px 0;
    }
    @media (max-width: $md3+px) {
      padding: 13px 25px 13px 0;
    }
    // @media (max-width: 1199px) {
    //   font-size: 14px;
    // }
  }
  &__link-offers {
    position: relative;
    transition: all 0.3s ease 0s;
    &::before {
      display: none !important;
    }
    &:hover .thumb-icon {
      background: url('../img/icons/thumbs-up-yellow.svg') center/contain
        no-repeat;
    }
  }
}
.thumb-icon {
  width: 20px;
  height: 20px;
  background: url('../img/icons/thumbs-up-orange.svg') center/contain no-repeat;
  display: inline-block;
  margin-right: 5px;
  position: relative;
  top: 3px;
  transition: all 0.3s ease 0s;
}
@media (min-width: 1200px) {
  .menu__list-item:hover > .submenu__list {
    transform: translateY(0);
    opacity: 1;
    visibility: visible;
    z-index: 2;
  }
}

.link {
  color: #18ad8f;
  border: 2px solid #18ad8f;
  padding: 10px 12px;
  transition: all 0.3s;
  &:hover,
  &:active {
    background-color: #18ad8f;
    color: #ffffff !important;
  }
}
//====================================================================================================
//Burger
.icon-menu {
  display: none;
  @media (max-width: 1199px) {
    display: block;
    position: absolute;
    top: 28px;
    right: 0;
    width: 30px;
    height: 18px;
    cursor: pointer;
    z-index: 99;
    span {
      transition: all 0.3s ease 0s;
      top: calc(50% - 1px);
      left: 0px;
      position: absolute;
      width: 100%;
      height: 2px;
      background-color: #273646;
      &:first-child {
        top: 0px;
      }
      &:last-child {
        top: auto;
        bottom: 0px;
      }
    }
    &._active {
      span {
        transform: scale(0);
        &:first-child {
          transform: rotate(-45deg);
          top: calc(50% - 1px);
        }
        &:last-child {
          transform: rotate(45deg);
          bottom: calc(50% - 1px);
        }
      }
    }
  }
  @media (max-width: $md3+px) {
    right: 20px;
  }
  @media (max-width: $md4+px) {
    top: 16px;
  }
}

.promo-header {
  margin-top: 165px;
  background-image: url('../img/header-bg.png') !important;
  background-repeat: no-repeat;
  background-position: 14% 10%;
  // clip-path: polygon(0 0, 100% 0, 100% 81%, 0 100%);
  max-width: 2560px;
  width: 100%;
  margin-left: auto;
  margin-right: auto;
  // margin-top: 87px;
  // padding-top: 150px;
  padding-top: 77px;
  padding-bottom: 75px;
  position: relative;
  z-index: 1;
  min-height: 725px;
  // &::before {
  //   background-image: url('../img/header-bg.jpg');
  //   background-repeat: no-repeat;
  //   background-position: center center;
  //   content: '';
  //   display: block;
  //   height: 100%;
  //   position: absolute;
  //   width: 100%;
  //   top: 0;
  //   z-index: 0;
  //   opacity: 0.4;
  // }
  @media (min-width: 1921px) {
    padding-bottom: 150px;
  }
  @media (max-width: 1378px) {
    // margin-top: 76px;
    padding-top: 80px;
    margin-top: 135px;
  }
  @media (max-width: 1199px) {
    // margin-top: 68px;
    padding-top: 130px;
    margin-top: 80px;
  }
  @media (max-width: $md2+px) {
    padding-top: 25px;
    margin-top: 150px;
  }
  @media (max-width: 598px) {
    margin-top: 170px;
  }
  @media (max-width: $md4+px) {
    margin-top: 135px;
  }
  @media (max-width: 401px) {
    margin-top: 165px;
  }
  // @media (min-width: 2050px) {
  //   clip-path: polygon(0 0, 100% 0, 100% 78%, 0 100%);
  // }
  // @media (max-width: $md3+px) {
  //   clip-path: polygon(0 0, 100% 0, 100% 88%, 0 94%);
  // }
  // .promo-header__content
  &__content {
    display: flex;
    justify-content: space-between;
    position: relative;
    z-index: 3;
    @media (max-width: $md2+px) {
      flex-direction: column;
    }
  }
  // .promo-header__column
  &__column {
    flex: 0 0 50%;
    &:last-of-type {
      @media (max-width: $md4+px) {
        display: none;
      }
    }
  }
  // .promo-header__title
  &__title {
    font-family: 'YanoneKaffeesatz-Bold';
    font-size: 80px;
    line-height: 122%;
    text-shadow: 3px 5px 0px rgba(31, 38, 46, 0.75);
    color: #ffffff;

    // @media (max-width: 1919px) {
    //   @include adaptiv-font(80, 30, 80);
    // }
    @media (max-width: $md1+px) {
      font-size: 66px;
    }
    @media (max-width: 1200px) {
      font-size: 60px;
    }
    @media (max-width: $md2+px) {
      font-size: 58px;
      text-align: center;
    }
    @media (max-width: $md3+px) {
      font-size: 48px;
      text-shadow: 3px 4px 0px rgba(31, 38, 46, 0.75);
    }
    @media (max-width: $md4+px) {
      letter-spacing: 0.95px;
      font-size: 38px;
      text-shadow: 3px 3px 0px rgba(31, 38, 46, 0.75);
    }
    // @media (max-width: 1400px) {
    //   font-size: 70px;
    // }
  }
  // .promo-header__text
  &__text {
    max-width: 533px;
    font-family: 'YanoneKaffeesatz-Regular';
    font-size: 50px;
    line-height: 120%;
    text-shadow: 3px 5px 0px rgba(31, 38, 46, 0.75);
    color: #ffffff;
    letter-spacing: 0.45px;
    padding-top: 5px;

    // @media (max-width: 1919px) {
    //   @include adaptiv-font(50, 22, 50);
    // }
    @media (max-width: $md1+px) {
      padding-top: 20px;
      font-size: 38px;
    }
    @media (max-width: $md2+px) {
      padding-top: 0;
      font-size: 35px;
    }
    @media (max-width: $md3+px) {
      font-size: 28px;
    }
    @media (max-width: $md4+px) {
      font-size: 22px;
      letter-spacing: 0.9px;
      max-width: 255px;
    }
    // @media (max-width: 1366px) {
    //   font-size: 40px;
    // }
    // @media (max-width: 1200px) {
    //   font-size: 35px;
    // }
    // @media (max-width: $md2+px) {
    //   font-size: 30px;
    //   text-align: center;
    // }
    // @media (max-width: $md3+px) {
    //   font-size: 25px;
    // }
    // @media (max-width: $md4+px) {
    //   font-size: 22px;
    // }
    @media (max-width: $md2+px) {
      text-align: center;
      margin: 0 auto;
      padding-right: 0;
      text-shadow: 2px 4px 0px rgba(31, 38, 46, 0.75);
    }
    @media (max-width: $md3+px) {
      text-shadow: 2px 3px 0px rgba(31, 38, 46, 0.75);
    }
    @media (max-width: $md4+px) {
      text-shadow: 1px 2px 0px rgba(31, 38, 46, 0.75);
    }
    // @media (max-width: 1400px) {
    //   font-size: 25px;
    // }
  }
  // .promo-header__button
  &__button {
    margin-top: 50px;
    // @media (min-width: 2050px) {
    //   margin-top: 40px;
    // }
    @media (max-width: $md2+px) {
      margin-top: 37px;
      display: block;
      margin-left: auto;
      margin-right: auto;
      margin-bottom: 40px;
    }
  }
  // .promo-header__image
  &__image {
    position: relative;
    @media (max-width: $md2+px) {
      max-width: 480px;
      width: 100%;
      margin-left: auto;
      margin-right: auto;
    }
    @media (max-width: $md3+px) {
      max-width: 380px;
      width: 100%;
      margin-left: auto;
      margin-right: auto;
    }
    &:after {
      content: '';
      display: block;
      background: url('../img/icons/arrow.svg') center/contain no-repeat;
      max-width: 352px;
      width: 100%;
      height: 105px;
      position: absolute;
      bottom: 0;
      left: -5%;
      transform: translateX(-50%);
      z-index: 9;
      @media (min-width: 1921px) {
        background: url('../img/icons/arrow.svg') center/95% no-repeat;
        bottom: 4%;
      }
      // @media (max-width: 1919px) {
      //   bottom: 3%;
      // }
      @media (max-width: 1600px) {
        // bottom: 4%;
        background: url('../img/icons/arrow.svg') center/90% no-repeat;
      }
      @media (max-width: 1378px) {
        transform: rotate(20deg);
        left: -159px;
        bottom: 7%;
        background: url('../img/icons/arrow.svg') center/70% no-repeat;
      }
      @media (max-width: 1280px) {
        bottom: 9%;
        background: url('../img/icons/arrow.svg') center/60% no-repeat;
      }
      @media (max-width: $md2+px) {
        display: none;
      }
      // @media (max-width: 1319px) {
      //   display: none;
      // }
    }
  }
}

.intro-promo {
  padding-top: 20px;
  flex: 0 0 47%;
  // @media (max-width: 1600px) {
  //   padding-top: 90px;
  // }
  @media (max-width: 1378px) {
    flex: 0 0 50%;
    padding-top: 36px;
  }
}

.promo-footer {
  padding: 0 0 500px;
  margin-top: -190px;
  position: relative;
  z-index: 2;
  max-width: 2560px;
  width: 100%;
  margin-left: auto;
  margin-right: auto;
  height: 845px;
  clip-path: polygon(0 21%, 100% 5.2%, 100% 100%, 0% 100%);
  @media (min-width: 1921px) {
    clip-path: polygon(0 28%, 100% 7.2%, 100% 100%, 0% 100%);
    margin-top: -315px;
    // z-index: 0;
  }
  // @media (max-width: $md3+px) {
  //   height: 738px;
  // }
  // @media (min-width: 1921px) {
  //   margin-top: -202px;
  //   clip-path: polygon(0 21%, 100% 5.5%, 100% 100%, 0% 100%);
  // }
  @media (max-width: 1919px) {
    margin-top: -236px;
    clip-path: polygon(0 25.5%, 100% 10.1%, 100% 100%, 0% 100%);
  }
  @media (max-width: 1280px) {
    clip-path: polygon(0 19%, 100% 8%, 100% 100%, 0% 100%);
  }
  @media (max-width: 1199px) {
    clip-path: polygon(0 18%, 100% 9%, 100% 100%, 0% 100%);
  }
  @media (max-width: $md2+px) {
    clip-path: polygon(0 19%, 100% 10%, 100% 100%, 0% 100%);
    height: 860px;
    margin-top: -230px;
  }
  @media (max-width: 859px) {
    clip-path: polygon(0 18%, 100% 10%, 100% 100%, 0% 100%);
    height: 1200px;
    margin-top: -270px;
  }
  @media (max-width: $md3+px) {
    clip-path: polygon(0 17%, 100% 11%, 100% 100%, 0% 100%);
    height: 1220px;
    margin-top: -350px;
  }
  @media (max-width: 619px) {
    height: 1270px;
    clip-path: polygon(0 16%, 100% 11%, 100% 100%, 0% 100%);
  }
  @media (max-width: 599px) {
    height: 940px;
    margin-top: -310px;
  }
  @media (max-width: 480px) {
    height: 870px;
  }
  @media (max-width: 450px) {
    height: 910px;
  }
  // @media (max-width: 575px) {
  //   margin-top: -245px;
  // }
  @media (max-width: $md4+px) {
    clip-path: polygon(0 17%, 100% 12%, 100% 100%, 0% 100%);
    margin-top: -488px;
  }
  &__image {
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    top: 20px;
    height: 100%;
    object-fit: cover;
    width: 100%;
    opacity: 0;
    visibility: hidden;
  }
  // .promo-footer__content
  &__content {
    margin-top: 217px;
    margin-bottom: 420px;
    @media (max-width: 1378px) {
      margin-bottom: 395px;
      margin-top: 250px;
    }
    @media (max-width: 1199px) {
      margin-bottom: 445px;
    }

    @media (max-width: $md2+px) {
      margin-bottom: 485px;
      margin-top: 244px;
    }
    @media (max-width: 859px) {
      margin-bottom: 790px;
      margin-top: 280px;
    }
    @media (max-width: $md3+px) {
      margin-bottom: 825px;
      margin-top: 300px;
    }
    // @media (max-width: 619px) {
    //   margin-bottom: 895px;
    // }
    @media (max-width: 599px) {
      margin-top: 230px;
      margin-bottom: 460px;
    }
    // @media (max-width: $md4+px) {
    //   margin-top: 234px;
    // }
    // @media (max-width: 397px) {
    //   margin-top: 215px;
    // }
    // @media (max-width: 328px) {
    //   margin-top: 185px;
    // }
  }
  // .promo-footer__title
  &__title {
    color: #ffffff;
    text-align: center;
    z-index: 3;
    position: relative;
    letter-spacing: 1px;
    text-shadow: 3px 5px 0px rgba(31, 38, 46, 0.75);
    @media (max-width: $md2+px) {
      text-shadow: 2px 4px 0px rgba(31, 38, 46, 0.75);
    }
    @media (max-width: $md3+px) {
      text-shadow: 2px 3px 0px rgba(31, 38, 46, 0.75);
    }
  }
  // .promo-footer__text
  &__text {
    font-family: 'RobotoCondensed-Regular';
    font-size: 22px;
    line-height: 122%;
    text-shadow: 3px 5px 0px rgba(31, 38, 46, 0.75);
    color: #ffffff;
    text-align: center;
    margin: 0 auto;
    z-index: 11;
    position: relative;
    padding-top: 10px;
    letter-spacing: 0px;
    @media (max-width: $md2+px) {
      font-size: 16px;
      text-shadow: 3px 4px 0px rgba(31, 38, 46, 0.75);
    }
    @media (max-width: $md3+px) {
      text-shadow: 2px 3px 0px rgba(31, 38, 46, 0.75);
    }
    @media (max-width: 599px) {
      padding-bottom: 50px;
      max-width: 250px;
    }
    @media (max-width: $md4+px) {
      letter-spacing: -0.8px;
    }
  }
}

.booking {
  // background-color: $gray;
  position: relative;
  z-index: 10;
  padding: 20px 0 12px;
  height: 90px;
  max-width: 2560px;
  width: 100%;
  margin-left: auto;
  margin-right: auto;
  @media (max-width: 859px) {
    background-color: transparent !important;
  }
  @media (max-width: $md3+px) {
    height: 65px;
  }
  &__content {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    position: relative;
    @media (max-width: 1378px) {
      justify-content: center;
    }
    @media (max-width: $md3+px) {
      flex-direction: column;
    }
  }
  &__text {
    flex: 0 0 20%;
    font-family: 'Frutiger-Bold';
    text-transform: uppercase;
    font-size: 26px;
    line-height: 26px;
    color: #ffffff;
    max-width: 300px;
    position: relative;
    padding-left: 160px;
    @media (max-width: 1919px) {
      @include adaptiv-font(26, 18, 26);
    }
    @media (max-width: 1378px) {
      padding-left: 60px;
    }
    @media (max-width: 1199px) {
      display: none;
    }
    @media (max-width: $md3+px) {
      max-width: 100%;
      margin-left: auto;
      margin-right: auto;
    }

    &::before {
      content: '';
      background-image: url('../img/icons/check.svg');
      background-repeat: no-repeat;
      background-size: 100%;
      background-position: center center;
      display: block;
      width: 40px;
      height: 40px;
      position: absolute;
      // border: 3px solid #fff;
      // border-radius: 50%;
      padding: 10px;
      right: 155px;
      top: 50%;
      transform: translateY(-50%);
      @media (max-width: 1378px) {
        left: 0;
      }
      @media (max-width: $md3+px) {
        left: 0px;
      }
    }
  }
  &__calendar {
    flex: 0 0 69.5%;
    align-self: flex-end;
    margin-top: -378px;
    position: relative;
    transition: all 0.2s ease 0s;
    width: 100%;
    @media (max-width: 1378px) {
      padding-left: 30px;
    }
    @media (max-width: 1199px) {
      padding: 0;
      flex: 0 0 100%;
    }
    @media (max-width: 859px) {
      margin-left: auto;
      margin-right: auto;
      margin-top: -365px;
    }
    @media (max-width: $md3+px) {
      position: absolute;
      bottom: 27px;
      padding-left: 0;
      left: 50%;
      transform: translateX(-50%);
    }
    @media (max-width: 599px) {
      bottom: -8px;
      padding-left: 15px;
      padding-right: 15px;
    }
    @media (max-width: $md4+px) {
      bottom: -4px;
    }
    // position: absolute;
    // right: 0;
    // bottom: -10px
    img {
      border-top-left-radius: 10px;
      border-top-right-radius: 10px;
      width: 100%;
      object-fit: contain;
      @media (max-width: 859px) {
        width: auto;
        max-width: 568px;
      }
      @media (max-width: 599px) {
        max-width: 300px;
      }
      // @media (max-width: $md3+px) {
      //   max-width: 500px;
      // }
      // @media (max-width: 575px) {
      //   max-width: 400px;
      // }
      // @media (max-width: $md4+px) {
      //   max-width: 300px;
      // }
    }
    &::after {
      content: '';
      display: block;
      position: absolute;
      left: 50%;
      transform: translateX(-50%);
      bottom: -25px;
      z-index: -1;
      padding: 20px 0 12px;
      height: 95px;
      width: 1000%;
      transition: all 0.2s ease 0s;
      @media (max-width: 859px) {
        height: 150px;
        width: 150%;
      }
      @media (max-width: 599px) {
        height: 90px;
        width: 200%;
      }
    }
  }
}
// .colors-wrapper {
//   position: fixed;
//   width: 100%;
//   height: 150px;
//   overflow: hidden;
//   max-width: 2560px;
//   top: 65%;
//   left: 50%;
//   transform: translateX(-50%);
//   z-index: 9;
//   @media (max-width: $md3+px) {
//     top: 50%;
//   }
//   @media (max-width: 580px) {
//     height: 150px;
//   }
// }

.colors {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 12px 75px 10px 10px;
  background-color: #fff;
  border-top-left-radius: 10px;
  border-bottom-left-radius: 10px;
  position: fixed;
  right: 0;
  top: 65%;
  z-index: 9;
  transform: translateX(93.2%);
  box-shadow: 0px 1px 3px 0px #646c77;
  transition: all 0.8s ease 0.2s;
  &__secondRow {
    @media (max-width: 580px) {
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 4px 5px 6px 10px;
      background-color: #fff;
      border-bottom-left-radius: 10px;
      box-shadow: 0 2px 2px 0 rgba(#646c77, 0.7);
      position: fixed;
      right: 0;
      // top: 48.2%;
      top: 340px;
      z-index: 9;
      transform: translateX(100%);
      transition: all 0.8s ease 0.2s;
      width: 293px;
      // width: 320px;
      // @media (max-width: $md4+px) {
      //   top: 54.6%;
      // }
      @media (max-width: 380px) {
        transition: all 0.8s ease 0.2s;
      }
      // @media (max-width: 359px) {
      //   top: 56.7%;
      // }
      &.showColors {
        transition: all 0.8s ease 0s;
        transform: translateX(0);
        @media (max-width: 380px) {
          transition: all 0.8s ease 0s;
        }
      }
    }
  }
  @media (max-width: $md1+px) {
    transform: translateX(93.8%);
    padding-right: 55px;
  }
  @media (max-width: $md2+px) {
    // padding-left: 0px;
    padding-top: 10px;
    padding-bottom: 8px;
    padding-right: 45px;
    // padding-top: 5px;
    // padding-bottom: 3px;
    transform: translateX(93.4%);
  }
  @media (max-width: 820px) {
    padding-top: 8px;
    padding-bottom: 6px;
    padding-right: 30px;
    transform: translateX(93.5%);
  }
  @media (max-width: 580px) {
    // top: 70%;
    transform: translateX(283px);
    width: 320px;
    padding-left: 5px;
    padding-right: 36px;
    top: 300px;
  }
  &__settings {
    position: relative;
    width: 58px;
    background-color: transparent;
    margin: 0 10px;
    @media (max-width: 1366px) {
      width: 50px;
      margin: 0 5px;
    }
    @media (max-width: $md2+px) {
      width: 40px;
    }
    @media (max-width: 820px) {
      width: 30px;
      margin-left: 0;
    }
    @media (max-width: 580px) {
      width: 30px;
      margin-left: 5px;
    }
    i {
      position: relative;
      display: block;
      background-image: url('../img/icons/rainbow_gear.svg');
      background-repeat: no-repeat;
      background-position: center center;
      background-size: contain;
      display: block;
      width: 46px;
      height: 49px;
      cursor: pointer;
      transition: all 0.8s ease 0s;
      transform: rotate(0);
      margin: 0 auto;
      @media (max-width: 1378px) {
        width: 40px;
        height: 40px;
      }
      @media (max-width: $md2+px) {
        width: 34px;
        height: 34px;
      }
      @media (max-width: 820px) {
        width: 22px;
        height: 22px;
      }
    }
  }

  &__btn {
    width: 55px;
    height: 55px;
    display: flex;
    align-items: center;
    justify-content: center;
    // border-radius: 10px;
    margin: 0 3.5px;
    transition: all 0.2s ease 0s;
    position: relative;
    span {
      position: absolute;
      top: -50px;
      padding: 4px 7px;
      background-color: #f1f2f5;
      display: block;
      border-radius: 5px;
      z-index: 5;
      font-family: 'Roboto';
      font-weight: 700;
      font-size: 14px;
      opacity: 0;
      visibility: hidden;
      transition: all 0.3s ease 0s;
      @media (max-width: 580px) {
        top: -28px;
      }
      @media (max-width: 580px) {
        display: none;
      }
      &::after {
        content: '';
        height: 10px;
        width: 10px;
        background: #f1f2f5;
        transform: rotate(45deg) translateX(-50%);
        position: absolute;
        bottom: -8px;
        left: 50%;
      }
    }
    &:hover span {
      opacity: 1;
      visibility: visible;
    }
    @media (max-width: $md1+px) {
      flex: 0 0 48px;
      width: 48px;
      height: 48px;
      svg {
        width: 48px;
        height: 48px;
      }
    }
    @media (max-width: $md2+px) {
      flex: 0 0 38px;
      width: 38px;
      height: 38px;
      svg {
        width: 38px;
        height: 38px;
      }
    }
    @media (max-width: 820px) {
      flex: 0 0 28px;
      width: 28px;
      height: 28px;
      margin: 0 2.5px;
      svg {
        width: 28px;
        height: 28px;
      }
    }
    &--active {
      transition: all 0.2s ease 0s;
      cursor: default;
      svg {
        width: 46px;
        height: 46px;
      }
      @media (max-width: $md1+px) {
        flex: 0 0 48px;
        width: 40px;
        height: 40px;
        svg {
          width: 40px;
          height: 40px;
        }
      }
      @media (max-width: $md2+px) {
        flex: 0 0 38px;
        width: 34px;
        height: 34px;
        svg {
          width: 34px;
          height: 34px;
        }
      }
      @media (max-width: 820px) {
        flex: 0 0 28px;
        width: 22px;
        height: 22px;
        svg {
          width: 22px;
          height: 22px;
        }
      }
    }
  }
  &.showColors {
    transition: all 0.8s ease 0s;
    transform: translateX(0);
    @media (max-width: 580px) {
      transform: translateX(0);
    }
  }
}
.button-group {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  flex: 0 0 50%;
  max-width: 50%;
  @media (max-width: 580px) {
    flex: 0 0 100%;
    max-width: 100%;
  }
}
.showColors {
  .colors__settings i {
    transform: rotate(-360deg);
  }
}

.svg-icon {
  display: flex;
  align-items: center;
  justify-content: center;
  fill: currentColor;
  position: relative;
  flex: 0 0 auto;
  width: 55px;
  height: 55px;
  transition: all 0.3s ease 0s;
  &:hover {
    width: 46px;
    height: 46px;
    @media (max-width: $md1+px) {
      width: 40px;
      height: 40px;
      svg {
        width: 40px;
        height: 40px;
      }
    }
    @media (max-width: $md2+px) {
      width: 34px;
      height: 34px;
      svg {
        width: 34px;
        height: 34px;
      }
    }
    @media (max-width: 820px) {
      width: 22px;
      height: 22px;
      svg {
        width: 22px;
        height: 22px;
      }
    }
  }
}

.gray-color {
  fill: #646c77;
}
.jeans-color {
  fill: #536f8a;
}
.gold-color {
  fill: #806834;
}
.grapefruit-color {
  fill: #dd1458;
}
.peper-color {
  fill: #d52b1e;
}
.leather-color {
  fill: #c57539;
}
.tangerin-color {
  fill: #ff7900;
}
.mustard-color {
  fill: #eea900;
}
.lime-color {
  fill: #b2bf14;
}
.grass-color {
  fill: #58a11a;
}
.mint-color {
  fill: #199967;
}
.laguna-color {
  fill: #018c9e;
}
.sky-color {
  fill: #038ccd;
}
.sea-color {
  fill: #3e50b4;
}
.lavender-color {
  fill: #6e39c5;
}
.clover-color {
  fill: #c5399b;
}

.blogPage-topheader {
  .menu__list-item:last-child {
    margin-top: 0;
  }
}
.blogPage-header {
  margin-top: 73px;
  padding: 42px 0 15px !important;
  height: 145px;
  min-height: 0;
  background-image: url('../img/blog-header.jpg') !important;
  background-repeat: no-repeat;
  background-size: auto;
  background-position: center center;
  // clip-path: polygon(0 0, 100% 0, 100% 81%, 0 100%);
  max-width: 2560px;
  width: 100%;
  margin-left: auto;
  margin-right: auto;
  @media (min-width: 1921px) {
    padding-bottom: 10px;
  }
  @media (max-width: 1378px) {
    height: 135px;
    margin-top: 58px;
  }
  @media (max-width: 1199px) {
    height: 125px;
    margin-top: 46px;
  }
  @media (max-width: $md2+px) {
    margin-top: 50px;
  }
  @media (max-width: $md3+px) {
    height: 115px;
  }
  @media (max-width: 601px) {
    margin-top: 50px !important;
  }
  @media (max-width: $md4+px) {
    height: 105px;
    margin-top: 30px !important;
  }
  @media (max-width: 401px) {
    margin-top: 30px;
  }
  &::before {
    background-position: center center;
  }
  ._container {
    justify-content: center;
  }
  .promo-header__title {
    text-transform: uppercase;
    font-family: 'YanoneKaffeesatz-Bold';
    letter-spacing: -2px;
    line-height: 130%;
    font-size: 72px;
    @media (max-width: $md1+px) {
      font-size: 66px;
    }
    @media (max-width: 1200px) {
      font-size: 60px;
    }
    @media (max-width: $md2+px) {
      font-size: 58px;
      text-align: center;
    }
    @media (max-width: $md3+px) {
      font-size: 48px;
      text-shadow: 3px 4px 0px rgba(31, 38, 46, 0.75);
    }
    @media (max-width: $md4+px) {
      letter-spacing: 0.95px;
      font-size: 38px;
      text-shadow: 3px 3px 0px rgba(31, 38, 46, 0.75);
    }
  }
  .htmlTags {
    text-transform: none;
  }
}

.header._scroll + .blogPage-header {
  @media (max-width: 1199px) {
    margin-top: 50px;
  }
  @media (max-width: $md2+px) {
    margin-top: 60px;
  }
  @media (max-width: $md4+px) {
    margin-top: 20px;
  }
}

// .booking {
//   &__text {
//     position: relative;
//     top: 7px;
//   }
//   &__calendar {
//     flex: 0 0 70%;
//     @media (max-width: $md1+px) {
//       flex: 0 0 93%;
//     }
//     @media (max-width: $md2+px) {
//       flex: 0 0 100%;
//     }
//   }
//   &__calendar-box {
//     height: 450px;
//     background-image: url('../img/top_01_gray_860.png');
//     background-repeat: no-repeat;
//     background-size: contain;
//     background-position: center bottom;
//     @media (max-width: 859px) {
//       background-image: url('../img/859maw/top_01_gray_600.png');
//       background-size: auto;
//       height: 800px;
//       transition: all 0.2s ease 0.5s;
//     }
//     @media (max-width: $md3+px) {
//       width: 700px;
//     }
//     @media (max-width: 599px) {
//       background-image: url('../img/599maw/top_01_gray_320.png');
//       background-size: 80%;
//       width: 400px;
//       transition: all 0.2s ease 0s;
//     }
//     @media (max-width: $md4+px) {
//       background-size: 75%;
//       width: 400px;
//     }
//   }
// }

.booking__calendar-box {
  position: absolute;
  bottom: -14px;
  opacity: 0;
  visibility: hidden;
  @media (max-width: 1199px) {
    width: 80%;
    left: 50%;
    transform: translateX(-50%);
  }
  @media (max-width: $md2+px) {
    width: 100%;
  }
  @media (max-width: 859px) {
    width: 70%;
    margin-left: -22px;
  }
  @media (max-width: $md3+px) {
    width: auto;
    margin-left: 0;
  }
  // &.gray {
  //   display: none;
  // }
  // &.jeans {
  //   display: none;
  // }
  // &.gold {
  //   display: none;
  // }
  // &.grapefruit {
  //   display: none;
  // }
  // &.peper {
  //   display: none;
  // }
  // &.leather {
  //   display: none;
  // }
  // &.tangerin {
  //   display: none;
  // }
  // &.mustard {
  //   display: none;
  // }
  // &.lime {
  //   display: none;
  // }
  // &.grass {
  //   display: none;
  // }
  // &.mint {
  //   display: none;
  // }
  // &.laguna {
  //   display: none;
  // }
  // &.sky {
  //   display: none;
  // }
  // &.sea {
  //   display: none;
  // }
  // &.lavender {
  //   display: none;
  // }
  // &.clover {
  //   display: none;
  // }
}
.promo-footer.gray-calendar {
  .slide-gray,
  .gray {
    opacity: 1;
    visibility: visible;
  }
  .booking__calendar:after {
    background-color: $gray;
  }
}
.promo-footer.jeans-calendar {
  .slide-jeans,
  .jeans {
    opacity: 1;
    visibility: visible;
  }
  .booking__calendar:after {
    background-color: $jeans;
  }
}
.promo-footer.gold-calendar {
  .slide-gold,
  .gold {
    opacity: 1;
    visibility: visible;
  }
  .booking__calendar:after {
    background-color: $gold;
  }
}
.promo-footer.grapefruit-calendar {
  .slide-grapefruit,
  .grapefruit {
    opacity: 1;
    visibility: visible;
  }
  .booking__calendar:after {
    background-color: $grapefruit;
  }
}
.promo-footer.peper-calendar {
  .slide-peper,
  .peper {
    opacity: 1;
    visibility: visible;
  }
  .booking__calendar:after {
    background-color: $peper;
  }
}
.promo-footer.leather-calendar {
  .slide-leather,
  .leather {
    opacity: 1;
    visibility: visible;
  }
  .booking__calendar:after {
    background-color: $leather;
  }
}
.promo-footer.tangerin-calendar {
  .slide-tangerin,
  .tangerin {
    opacity: 1;
    visibility: visible;
  }
  .booking__calendar:after {
    background-color: $tangerin;
  }
}
.promo-footer.mustard-calendar {
  .slide-mustard,
  .mustard {
    opacity: 1;
    visibility: visible;
  }
  .booking__calendar:after {
    background-color: $mustard;
  }
}
.promo-footer.lime-calendar {
  .slide-lime,
  .lime {
    opacity: 1;
    visibility: visible;
  }
  .booking__calendar:after {
    background-color: $lime;
  }
}
.promo-footer.grass-calendar {
  .slide-grass,
  .grass {
    opacity: 1;
    visibility: visible;
  }
  .booking__calendar:after {
    background-color: $grass;
  }
}
.promo-footer.mint-calendar {
  .slide-mint,
  .mint {
    opacity: 1;
    visibility: visible;
  }
  .booking__calendar:after {
    background-color: $mint;
  }
}
.promo-footer.laguna-calendar {
  .slide-laguna,
  .laguna {
    opacity: 1;
    visibility: visible;
  }
  .booking__calendar:after {
    background-color: $laguna;
  }
}
.promo-footer.sky-calendar {
  .slide-sky,
  .sky {
    opacity: 1;
    visibility: visible;
  }
  .booking__calendar:after {
    background-color: $sky;
  }
}
.promo-footer.sea-calendar {
  .slide-sea,
  .sea {
    opacity: 1;
    visibility: visible;
  }
  .booking__calendar:after {
    background-color: $sea;
  }
}
.promo-footer.lavender-calendar {
  .slide-lavender,
  .lavender {
    opacity: 1;
    visibility: visible;
  }
  .booking__calendar:after {
    background-color: $lavender;
  }
}
.promo-footer.clover-calendar {
  .slide-clover,
  .clover {
    opacity: 1;
    visibility: visible;
  }
  .booking__calendar:after {
    background-color: $clover;
  }
}
