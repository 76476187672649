.pagging {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  // .pagging__arrow
  &__arrow {
    width: 35px;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #ffffff;
    box-shadow: 0 0 0 1px #9ba7b2;
    //  border: 1px solid #9ba7b2;
    font-size: 18px;
    line-height: 0;
    font-weight: 400;
    height: 36px;
    transition: all 0.3s ease 0s;
    @media (max-width: $md2+px) {
      font-size: 17px;
    }
    @media (max-width: $md3+px) {
      font-size: 16px;
    }
    @media (max-width: $md4+px) {
      font-size: 15px;
    }
    &:first-of-type {
      border-top-left-radius: 5px;
      border-bottom-left-radius: 5px;
    }
    &:last-of-type {
      border-top-right-radius: 5px;
      border-bottom-right-radius: 5px;
    }
    &:hover {
      background-color: #f3f3f3;
      color: #d52b1e;
    }
  }
  // .pagging__list
  &__list {
    display: flex;
    li {
      background-color: #ffffff;
      box-shadow: 0 0 0 1px #9ba7b2;
      height: 36px;
      display: flex;
      justify-content: center;
      align-items: center;
      cursor: default;
    }
    a {
      padding: 18px 12px 16px;
      font-family: 'Roboto';
      font-size: 18px;
      font-weight: 400;
      line-height: 0;
      letter-spacing: 0.01em;
      color: #394a58;
      transition: all 0.3s ease 0s;
      @media (max-width: $md2+px) {
        font-size: 17px;
      }
      @media (max-width: $md3+px) {
        font-size: 16px;
      }
      @media (max-width: $md4+px) {
        font-size: 15px;
      }
      &:hover {
        box-shadow: 0 0 0 1px #9ba7b2;
        background-color: #f3f3f3;
        color: #d52b1e;
      }
    }
  }
  // .pagging__item
  &__item {
    width: 35px;
    &._active {
      box-shadow: 0 0 0 1px #394a58;
      background-color: #394a58;
      color: #fff;
      cursor: default;
      &:hover {
        //   border: 2px solid #394a58;
        background-color: #394a58;
        color: #fff;
        pointer-events: none;
        cursor: default;
      }
    }
  }
}

span.arrow-left {
  display: block;
  width: 8px;
  height: 8px;
  border-top: 2px solid #394a58;
  border-left: 2px solid #394a58;
  transform: rotate(-45deg);
  position: absolute;
  top: 14px;
  left: 17px;
  transition: all 0.3s ease 0s;
}

span.arrow-right {
  display: block;
  width: 8px;
  height: 8px;
  border-top: 2px solid #394a58;
  border-right: 2px solid #394a58;
  transform: rotate(45deg);
  position: absolute;
  top: 14px;
  left: 12px;
  transition: all 0.3s ease 0s;
}

.pagging__arrow:hover .arrow-left {
  border-top: 2px solid #d52b1e;
  border-left: 2px solid #d52b1e;
}
.pagging__arrow:hover .arrow-right {
  border-top: 2px solid #d52b1e;
  border-right: 2px solid #d52b1e;
}
