.search-btn {
  position: absolute;
  top: 50%;
  right: 10px;
  transform: translateY(10px);
}
.search {
  position: absolute;
  width: 20px;
  right: 5px;
  &__circle {
    position: absolute;
    top: -17px;
    width: 13px;
    height: 13px;
    border: 2px solid #2c3e50;
    border-radius: 100px;
  }
  &__rectangle {
    position: absolute;
    right: 4px;
    bottom: 3px;
    width: 6px;
    transform: rotate(45deg);
    border: 1px solid #2c3e50;
  }
}

.sidebar {
  &__header {
    padding: 12px 25px 10px;
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
    position: relative;
    overflow: hidden;
    &::before {
      background-image: url('../img/sidebar-header.jpg');
      background-repeat: no-repeat;
      background-size: auto;
      content: '';
      display: block;
      height: 100%;
      position: absolute;
      width: 100%;
      top: 0;
      left: 0;
      z-index: 0;
      @media (max-width: $md2+px) {
        background-size: cover;
      }
    }
  }
  &__title {
    color: #fff;
    text-transform: uppercase;
    font-weight: 700;
    font-size: 18px;
    line-height: 28px;
    position: relative;
    z-index: 1;
  }
  &__content-menu {
    background-color: #f1f2f5;
    padding: 13px 27px 45px 25px;
    border-bottom: 3px solid $peper;
    margin-bottom: 55px;
  }
  &__content-info {
    background-color: #f1f2f5;
    padding: 10px 30px 25px 30px;
    border-top: 3px solid #425262;
  }
  &__content-item {
    margin: 35px 0 55px;
  }
  &__content-title {
    font-size: 21px;
    line-height: 28px;
    color: #273646;
    font-weight: 400;
    @media (max-width: $md3+px) {
      font-size: 19px;
    }
    @media (max-width: $md4+px) {
      font-size: 17px;
    }
    span {
      font-weight: 700;
    }
  }
  &__content-text {
    padding-top: 13px;
    padding-right: 5px;
    font-size: 16px;
    line-height: 24px;
    font-weight: 400;
    color: #2c3e50;
    @media (max-width: $md3+px) {
      font-size: 15px;
    }
    @media (max-width: $md4+px) {
      font-size: 14px;
    }
    a {
      display: inline-block;
      font-family: 'Roboto';
      font-size: 16px;
      line-height: 24px;
      font-weight: 400;
      color: #0089c4;
      position: relative;
      @media (max-width: $md3+px) {
        font-size: 15px;
      }
      @media (max-width: $md4+px) {
        font-size: 14px;
      }
      &::after {
        content: '';
        display: block;
        max-width: 100%;
        width: 100%;
        height: 1px;
        background-color: #0089c4;
        position: absolute;
        left: 50%;
        transform: translateX(-50%);
        bottom: 3px;
        transition: all 0.3s ease 0s;
      }
      &:hover:after {
        width: 0;
        background-color: #fff;
      }
    }
  }
  &__content-quote {
    box-shadow: 0px 1px 5px 0px rgba(#000000, 0.5);
    padding: 42px 45px 28px 72px;
    background-color: #009b74;
    border-radius: 5px;
    background-image: linear-gradient(
      165deg,
      #2d5ac6 11%,
      #009b74 50%,
      #0fa982 88%
    );
    font-size: 19px;
    line-height: 26px;
    font-weight: 400;
    font-style: italic;
    color: #ffffff;
    position: relative;
    @media (max-width: $md3+px) {
      font-size: 17px;
    }
    @media (max-width: $md4+px) {
      padding: 22px 25px 18px 62px;
      font-size: 15px;
    }
    span {
      display: block;
      margin-top: 10px;
      text-align: right;
    }
    &::before {
      content: '"';
      font-family: 'Roboto';
      display: block;
      color: #fff;
      font-size: 110px;
      position: absolute;
      transform: rotate(180deg);
      top: -5px;
      left: 45px;
      @media (max-width: $md3+px) {
        font-size: 90px;
      }
      @media (max-width: $md4+px) {
        font-size: 70px;
      }
    }
  }
  &__content-tabs {
    margin-top: 82px;
    margin-bottom: 75px;
  }
  &__content-image {
    margin-top: 20px;
    margin-bottom: 10px;
    max-width: 349px;
    height: 200px;
    border-radius: 5px;
    overflow: hidden;
    img {
      width: 100%;
      object-fit: cover;
    }
    @media (max-width: $md2+px) {
      max-width: 100%;
      height: auto;
    }
  }
  &__content-contact {
    padding-top: 40px;
  }
}

.sidebar-list {
  padding-top: 15px;
}

.unordered-list {
  padding-top: 10px;
  padding-left: 19px;
  li {
    font-size: 16px;
    line-height: 28px;
    font-weight: 400;
    color: #2c3e50;
    padding-left: 19px;
    position: relative;
    @media (max-width: $md3+px) {
      font-size: 15px;
    }
    @media (max-width: $md4+px) {
      font-size: 14px;
      line-height: 22px;
    }
    &::before {
      content: '';
      width: 7px;
      height: 7px;
      display: inline-block;
      background-color: $peper;
      border-radius: 50%;
      margin-right: 10px;
      margin-bottom: 2px;
      position: absolute;
      top: 10px;
      left: 3px;
      @media (max-width: $md4+px) {
        top: 7px;
      }
      // position: absolute;
      // left: 0;
      // top: 50%;
      // transform: translateY(-50%);
    }
  }
}
.ordered-list {
  padding-top: 10px;
  padding-left: 30px;
  counter-reset: my-awesome-counter;
  li {
    font-size: 16px;
    line-height: 28px;
    font-weight: 400;
    color: #2c3e50;
    padding-left: 10px;
    position: relative;
    counter-increment: my-awesome-counter;
    @media (max-width: $md3+px) {
      font-size: 15px;
    }
    @media (max-width: $md4+px) {
      font-size: 14px;
      line-height: 22px;
    }
    &::marker {
      content: counter(my-awesome-counter) '';
      color: $peper;
      font-weight: 400;
      font-family: 'Arial';
      font-size: 15px;
    }
  }
}
.menu-sidebar {
  overflow: hidden;
  padding-bottom: 10px;
  &__list {
    position: relative;
    padding-left: 25px;
    padding-top: 12px;
    cursor: pointer;
    &:hover {
      .menu-sidebar__link {
        color: $peper;
      }
    }
    &::before {
      content: '';
      display: block;
      width: 12px;
      height: 12px;
      background-color: $peper;
      border-radius: 50%;
      position: absolute;
      top: 26px;
      left: 3px;
      opacity: 0;
      visibility: hidden;
      transition: all 0.2s ease 0s;
    }
    &::after {
      content: '';
      display: block;
      border-bottom: 2px solid #9ba7b2;
      position: absolute;
      width: 100%;
      top: 56px;
      left: 5px;
    }
    &--active {
      &::before {
        opacity: 1;
        visibility: visible;
      }
    }
  }
  &__link {
    color: #273646;
    text-transform: uppercase;
    font-family: 'RobotoCondensed-Regular';
    font-size: 15px;
    line-height: 18px;
    transition: all 0.3s ease 0s;
    &:hover {
      color: $peper;
    }
  }
  &__submenu {
    height: 0;
    opacity: 0;
    visibility: hidden;
    padding-top: 5px;
    margin-bottom: -5px;
    // height: 0;
    // opacity: 0;
    // visibility: hidden;
    transition: height 0.2s opacity 0.2s visibility 0.2s ease 0s;
    @media (max-width: $md4+px) {
      line-height: 120%;
    }
  }
  &__sublist {
    border-bottom: 1px solid #9ba7b2;
    position: relative;
    padding-top: 6px;
    padding-left: 23px;
    padding-bottom: 7px;
    &:hover {
      .menu-sidebar__sublink {
        color: $peper;
      }
    }
    &:first-child {
      padding-top: 10px;
      padding-bottom: 10px;
      &::before {
        top: 23px;
        @media (max-width: $md4+px) {
          top: 18px;
        }
      }
    }
    &::before {
      content: '';
      display: block;
      width: 12px;
      height: 12px;
      background-color: $peper;
      border-radius: 50%;
      position: absolute;
      top: 20px;
      left: 0;
      opacity: 0;
      visibility: hidden;
      transition: all 0.3s;
      @media (max-width: $md4+px) {
        top: 14px;
      }
    }
    &--active {
      .menu-sidebar__sublink {
        font-family: 'RobotoCondensed-Bold';
      }
      &::before {
        opacity: 1;
        visibility: visible;
      }
    }
  }
  &__sublink {
    // display: block;
    color: #273646;
    text-transform: uppercase;
    font-family: 'RobotoCondensed-Regular';
    font-size: 15px;
    transition: all 0.3s;
    &:hover {
      color: $peper;
    }
  }
}

.menu-sidebar__list--active.sidebar-submenu:before {
  content: '';
  display: block;
  width: 12px;
  height: 12px;
  background-color: transparent;
  border: 2px solid $peper;
  border-radius: 50%;
  position: absolute;
  top: 26px;
  left: 3px;
  opacity: 1;
  visibility: visible;
  transition: all 0.2s ease 0s;
}

.menu-sidebar__list--active > .menu-sidebar__submenu {
  height: auto;
  opacity: 1;
  visibility: visible;
  transition: height 0.3s opacity 0.3 visibility 0.3 ease 0s;
}

.tabs-sidebar {
  &__nav {
    ul {
      display: flex;
      // @media (max-width: 375px) {
      //   flex-direction: column;
      // }
    }
    li {
      @media (max-width: $md4+px) {
        flex: 0 0 33.33%;
        max-width: 33.33%;
      }
    }
    li:first-of-type .tabs-sidebar__item {
      @media (max-width: 375px) {
        border-top-left-radius: 5px;
        border-top-right-radius: 5px;
      }
    }
  }
  .active-tab {
    .tabs-sidebar__item {
      box-shadow: inset 0px 3px 0px 0px $peper;
      // border-top: 2px solid $peper;
      background-color: #fff;
      color: #425262;
      cursor: inherit;
    }
  }
  &__item {
    background-color: #425262;
    width: 106px;
    padding: 10px 15px 5px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 13px;
    line-height: 28px;
    font-weight: 700;
    color: #ffffff;
    text-transform: uppercase;
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
    cursor: pointer;
    transition: all 0.3s ease 0s;
    margin: 0 0.5px;
    position: relative;
    z-index: 1;
    span {
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }

    @media (max-width: $md4+px) {
      width: 99%;
      font-size: 12px;
      padding-left: 10px;
      padding-right: 10px;
    }
    // @media (max-width: 375px) {
    //   max-width: 60px;
    // }

    &:hover {
      background-color: $peper;
    }
  }
  &__inner {
    padding: 19px 15px 4px 15px;
    background-color: #fff;
    border-bottom-left-radius: 5px;
    border-bottom-right-radius: 5px;
    border-top-right-radius: 5px;
    position: relative;
    z-index: 3;
    @media (max-width: $md4+px) {
      padding-left: 0px;
      padding-right: 0px;
    }
  }
  &__inner-item {
    height: 0;
    opacity: 0;
    visibility: hidden;
    transition: height 0.3s opacity 0.2s visibility 0.2s;
    &--active {
      max-height: 352px;
      height: auto;
      opacity: 1;
      visibility: visible;
      transition: height 0.3s opacity 0.2s visibility 0.2s;
      @media (max-width: $md4+px) {
        max-height: 100%;
      }
    }
  }
  &__inner-box {
    display: flex;
    justify-content: space-between;
    padding-left: 10px;
    border-bottom: 1px dotted #9ba7b2;
    padding-top: 15px;
    padding-bottom: 15px;
    padding-right: 15px;
    &:last-of-type {
      border-bottom: 0;
    }
    @media (max-width: $md2+px) {
      justify-content: flex-start;
      max-width: 100%;
    }
    @media (max-width: $md3+px) {
      padding-top: 20px;
      padding-bottom: 20px;
    }
    @media (max-width: $md4+px) {
      padding-top: 20px;
      padding-bottom: 20px;
    }
  }
  &__inner-content {
    @media (max-width: $md2+px) {
      width: 100%;
    }
  }
  &__inner-img {
    margin-right: 20px;
    flex: 0 0 64px;
    height: 64px;
    border-radius: 5px;
    img {
      width: 100%;
      object-fit: cover;
    }
  }
  &__inner-link {
    display: block;
    font-size: 16px;
    line-height: 22px;
    color: #0089c4;
    @media (max-width: $md4+px) {
      font-size: 14px;
    }
  }
  &__inner-meta {
    display: block;
    font-size: 13px;
    line-height: 22px;
    font-weight: 400;
    color: #394a58;
    @media (max-width: $md4+px) {
      font-size: 12px;
    }
  }
}

.sidebar-contact {
  &__title {
    font-size: 21px;
    line-height: 28px;
    color: #273646;
    font-weight: 400;
    @media (max-width: $md3+px) {
      font-size: 18px;
    }
    @media (max-width: $md4+px) {
      font-size: 17px;
    }
    span {
      font-weight: 700;
    }
  }
  &__item {
    // display: block;
    font-size: 16px;
    line-height: 28px;
    font-weight: 400;
    color: #202224;
    padding-top: 17px;
    position: relative;
    // padding-left: 40px;
    display: flex;
    transition: all 0.3s ease 0s;
    &::before {
      content: '';
      background-repeat: no-repeat;
      display: inline-block;
      background-position: center center;
      background-size: contain;
      width: 25px;
      height: 25px;
      margin-right: 15px;
    }
    &:hover {
      color: #d52b1e;
    }
  }
  &__item-row {
    display: flex;
    justify-content: space-between;
    padding: 17px 0 0;
    justify-content: flex-start;
    &::before {
      display: none;
    }
    &:last-of-type {
      padding-bottom: 50px;
    }
    @media (max-width: $md4+px) {
      flex-direction: column;
    }
  }
  &__item-column {
    position: relative;
    display: flex;
    &:first-of-type {
      flex: 0 0 135px;
      // padding-left: 40px;
      @media (max-width: $md4+px) {
        flex: 0 0 auto;
      }
    }
    &:last-of-type {
      flex: 0 0 200px;
      text-align: left;
      padding-left: 10px;
      @media (max-width: $md4+px) {
        flex: 0 0 auto;
        padding-left: 40px;
      }
    }
  }
  &__email {
    color: #0089c4;
  }
}

.pointer {
  &::before {
    background-image: url('../img/icons/point.svg');
  }
}
.phone {
  &::before {
    background-image: url('../img/icons/phone.svg');
  }
}
.email {
  &::before {
    background-image: url('../img/icons/mail.svg');
  }
}

.clock {
  &::before {
    content: '';
    background-repeat: no-repeat;
    background-image: url('../img/icons/time_black.svg');
    display: inline-block;
    background-position: center center;
    background-size: contain;
    width: 25px;
    height: 25px;
    margin-right: 15px;
  }
  &__red {
    &::before {
      background-image: url('../img/icons/time_red.svg');
    }
  }
}
