.footer {
  background-color: #273646;
  padding: 118px 0 60px;
  max-width: 2560px;
  width: 100%;
  margin-left: auto;
  margin-right: auto;
  position: relative;
  z-index: 3;
  &__content {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
  }
  // .footer__column
  &__column {
    flex: 0 0 20.8%;
    padding-bottom: 50px;
    &:first-of-type {
      flex: 0 0 37.6%;
      @media (max-width: 1378px) {
        flex: 0 0 300px;
      }
      @media (max-width: 1199px) {
        flex: 0 0 262px;
      }
    }
  }
  &__menu-column {
    padding: 0 5px 50px 5px;
    @media (max-width: $md2+px) {
      flex: 0 0 50%;
    }
    @media (max-width: $md4+px) {
      flex: 0 0 100%;
    }
  }
  // .footer__logo
  &__logo {
    width: 362px;
    @media (max-width: 1378px) {
      max-width: 300px;
      img {
        width: 100%;
      }
    }
    @media (max-width: 475px) {
      max-width: 200px;
    }
  }
  // .footer__contacts
  &__contacts {
    padding-top: 10px;
    padding-left: 27px;
    margin-top: 12px;
  }
  // .footer__contact-label
  &__contact-label {
    font-size: 15px;
    line-height: 34px;
    font-weight: 400;
    color: #d5dde5;
    display: inline-block;
    min-width: 65px;
  }
  // .footer__contact-item
  &__contact-item {
    display: inline-block;
    font-size: 15px;
    line-height: 34px;
    font-weight: 400;
    color: #9ba7b2;
  }
  // .footer__social
  &__social {
    padding-top: 25px;
    padding-left: 27px;
    width: 170px;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  // .footer__social-item
  &__social-item {
    height: 20px;
    flex: 0 0 20px;
    display: block;
    font-size: 20px;
    color: #9ba7b2;
    transition: all 0.3s ease 0s;
    &:hover {
      color: #f4de4b;
    }
    &::before {
      content: '';
      height: 23px;
      width: auto;
      display: block;
      transition: all 0.3s ease 0s;
    }
  }
  &__menu-title {
    font-family: 'RobotoCondensed-Regular';
    font-size: 26px;
    line-height: 41px;
    font-weight: 400;
    color: #d5dde5;
    text-transform: uppercase;
    position: relative;
    padding-bottom: 10px;
    padding-left: 20px;
    @media (max-width: 1378px) {
      font-size: 22px;
    }
    &::before,
    &::after {
      content: '';
      width: 90%;
      height: 2px;
      display: block;
      position: absolute;
      left: 0;
    }
    &::before {
      background-color: #1f262e;
      bottom: 0;
    }
    &::after {
      background-color: #415161;
      bottom: -2px;
    }
    span {
      width: 12px;
      height: 12px;
      border-radius: 50%;
      background-color: #f4de4b;
      display: block;
      position: absolute;
      left: 0;
      top: 40%;
      transform: translateY(-50%);
      opacity: 0;
      visibility: hidden;
      transition: all 0.3s ease 0s;
    }
  }
  &__menu-list {
    padding-left: 20px;
    padding-top: 20px;
  }
  &__demo-list {
    padding-left: 0;
  }
  &__menu-link {
    font-size: 18px;
    line-height: 113%;
    font-weight: 400;
    color: #9ba7b2;
    transition: all 0.3s ease 0s;
    @media (max-width: 1378px) {
      font-size: 16px;
    }
    &:hover {
      color: #f4de4b;
    }
  }
}
.active-dot {
  opacity: 1 !important;
  visibility: visible !important;
}
.footer__demo-list {
  & > li {
    padding-left: 20px;
    &:last-child {
      padding-left: 0;
    }
  }
}
.footer__menu-list > li > a:hover + .footer__menu-title {
  color: red;
}
.demo-link {
  color: #18ad8f;
  border: 1px solid #18ad8f;
  text-transform: none;
  padding: 10px 10px 10px 18px;
  display: block;
  max-width: 235px;
  width: 100%;
  &:hover {
    background-color: #18ad8f;
    color: #fff;
  }
}

.offers-link {
  color: #ff7900;
  padding-left: 25px;
  position: relative;
  transition: all 0.3s ease 0s;
  &:hover {
    color: #f4de4b;
    &:before {
      background: url('../img/icons/thumbs-up-yellow.svg') center/contain
        no-repeat;
    }
  }
  &::before {
    content: '';
    width: 20px;
    height: 20px;
    background: url('../img/icons/thumbs-up-orange.svg') center/contain
      no-repeat;
    display: block;
    position: absolute;
    left: -5px;
    top: -2px;
    transition: all 0.3s ease 0s;
  }
}

.instagram {
  &::before {
    background: url('../img/icons/instagram.svg') center/contain no-repeat;
  }
  &:hover:before {
    background: url('../img/icons/instagram-yellow.svg') center/contain
      no-repeat;
  }
}
.youtube {
  &::before {
    background: url('../img/icons/youtube.svg') center/contain no-repeat;
  }
  &:hover:before {
    background: url('../img/icons/youtube-yellow.svg') center/contain no-repeat;
  }
}
.facebook {
  &::before {
    background: url('../img/icons//facebook.svg') center/contain no-repeat;
  }
  &:hover:before {
    background: url('../img/icons/facebook-yellow.svg') center/contain no-repeat;
  }
}
.whatsapp {
  &::before {
    background: url('../img/icons/whatsapp.svg') center/contain no-repeat;
  }
  &:hover:before {
    background: url('../img/icons/whatsapp-yellow.svg') center/contain no-repeat;
  }
}

//====================================================================================================
.copyright {
  background-image: url('../img/footer-bg.jpg');
  background-repeat: no-repeat;
  background-position: bottom center;
  width: 100%;
  height: 74px;
  max-width: 2560px;
  width: 100%;
  margin-left: auto;
  margin-right: auto;
  position: relative;
  // &::before {
  //   background-image: url('../img/footer-bg.jpg');
  //   background-repeat: no-repeat;
  //   background-position: bottom center;
  //   content: '';
  //   display: block;
  //   height: 100%;
  //   position: absolute;
  //   width: 100%;
  //   bottom: 0;
  //   z-index: 0;
  //   opacity: 0.4;
  // }
  @media (max-width: 700px) {
    background: url('../img/footer-bg.jpg') center/cover no-repeat;
    height: 100px;
  }
  @media (max-width: 417px) {
    // background-color: #bb261d;
    padding: 7px 0 5px;
    height: 140px;
  }
  &__row {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-top: 20px;
    position: relative;
    z-index: 3;
    @media (max-width: 700px) {
      flex-direction: column;
      padding-top: 10px;
    }
  }
  &__text {
    font-size: 14px;
    font-weight: 400;
    color: #ffffff;
  }
  &__list {
    display: flex;
    align-items: center;
    @media (max-width: 417px) {
      flex-direction: column;
    }
    li a {
      font-size: 14px;
      font-weight: 400;
      color: #ffffff;
      padding: 0 15px;
      transition: all 0.3s ease 0s;
      position: relative;
      &::after {
        content: '';
        width: 0;
        height: 1px;
        display: block;
        position: absolute;
        bottom: -5px;
        left: 50%;
        transform: translateX(-50%);
        transition: all 0.3s ease 0s;
      }
      &:hover {
        color: #f4de4b;
        transition: all 0.3s ease 0s;
        &:after {
          width: 88%;
          background-color: #f4de4b;
        }
      }
    }
    li:first-of-type a {
      border-right: 1px solid #fff;
      @media (max-width: 417px) {
        border: 0;
      }
    }
  }
}
//====================================================================================================
