.blogPage-topheader {
  margin-right: 0 !important;
}

.blogPage {
  padding-top: 22px !important;
  &__body {
    padding: 0;
    @media (max-width: $md2+px) {
      justify-content: flex-end;
    }
    @media (max-width: $md3+px) {
      padding: 0 !important;
    }
    @media (max-width: $md4+px) {
      padding: 0 15px !important;
    }
  }
  &__sidebar {
    margin-top: 24px;
  }
  &__content {
    padding-left: 45px;
    @media (max-width: $md2+px) {
      padding-left: 0;
      padding-bottom: 50px;
    }
    @media (max-width: $md3+px) {
      padding-left: 20px;
      padding-right: 20px;
    }
    @media (max-width: $md4+px) {
      padding-left: 0px;
      padding-right: 0px;
    }
  }
  &__content-title {
    padding-top: 20px;
  }
  &__content-text {
    padding-top: 20px;
  }
  &__content-image {
    margin-top: 45px;
    margin-bottom: 15px;
  }
  &__content-imgTitle {
    margin-top: 10px;
  }
  &__content-divider {
    margin-top: 55px;
    margin-bottom: 55px;
  }
  &__content-smallImg {
    margin-top: 30px;
    padding-right: 30px;
  }
  &__content-form {
    margin-top: 22px;
  }
  &__content-services {
    margin-top: 45px;
  }
  &__content-table {
    margin-bottom: 60px;
  }
  &__errorPage {
    padding-top: 300px;
    padding-bottom: 300px;
  }
}
.blog-image {
  text-align: center;
  max-width: 100%;
  border-radius: 5px;
  overflow: hidden;
  img {
    width: 100%;
    object-fit: cover;
  }
}
.blog-smallImg {
  float: left;
  @media (max-width: $md3+px) {
    float: none;
    padding-right: 0;
  }
  img {
    @media (max-width: $md3+px) {
      width: 100%;
      object-fit: contain;
    }
  }
}
.small-imgTitle {
  text-align: center;
  font-size: 15px;
  line-height: 28px;
  font-weight: 400;
  font-style: italic;
  color: #4b5159;
}
.imgTitle {
  font-size: 15px;
  line-height: 160%;
  font-weight: 400;
  font-style: italic;
  color: #394a58;
}
.breadcrumbs {
  font-family: 'RobotoCondensed-Regular';
  font-size: 15px;
  color: #9ba7b2;
  a {
    display: inline-block;
    font-family: 'RobotoCondensed-Regular';
    font-size: 15px;
    color: #9ba7b2;
    position: relative;
    padding-right: 20px;
    transition: all 0.3s ease 0s;
    &:hover {
      color: #d52b1e;
    }
    @media (max-width: $md4+px) {
      font-size: 14px;
    }
    &:first-of-type {
      text-decoration: underline;
    }
    &:nth-of-type(2),
    &:nth-of-type(3),
    &:nth-of-type(4),
    &:nth-of-type(5),
    &:nth-of-type(6) {
      &::before {
        content: '';
        background-image: url('../img/icons/angle-right-solid.svg');
        background-repeat: no-repeat;
        background-size: 105%;
        display: block;
        width: 7px;
        height: 12px;
        position: absolute;
        top: 10px;
        left: -14px;
        color: #9ba7b2;
      }
    }
  }
  span {
    display: inline-block;
    position: relative;
    &::before {
      content: '';
      background-image: url('../img/icons/angle-right-solid.svg');
      background-repeat: no-repeat;
      background-size: 105%;
      display: block;
      width: 7px;
      height: 12px;
      position: absolute;
      top: 10px;
      left: -14px;
      color: #9ba7b2;
    }
  }
}
.h2-blog-title {
  font-size: 38px;
  line-height: 120%;
  color: #273646;
  font-weight: 400;
  span {
    font-weight: 700;
  }
  @media (max-width: $md2+px) {
    font-size: 34px;
  }
  @media (max-width: $md3+px) {
    font-size: 30px;
  }
  @media (max-width: $md4+px) {
    font-size: 26px;
  }
}
.h3-blog-title {
  font-size: 26px;
  line-height: 120%;
  color: #273646;
  font-weight: 400;
  span {
    font-weight: 700;
  }
  @media (max-width: $md2+px) {
    font-size: 24px;
  }
  @media (max-width: $md3+px) {
    font-size: 22px;
  }
  @media (max-width: $md4+px) {
    font-size: 20px;
  }
}
.h4-blog-title {
  font-size: 20px;
  line-height: 110%;
  color: #273646;
  font-weight: 400;
  span {
    font-weight: 700;
  }
  @media (max-width: $md2+px) {
    font-size: 19px;
  }
  @media (max-width: $md3+px) {
    font-size: 18px;
  }
  @media (max-width: $md4+px) {
    font-size: 17px;
  }
}
.hotels {
  font-family: 'Roboto';
  font-size: 18px;
  line-height: 170%;
  font-weight: 400;
  color: #0089c4;
  position: relative;
  transition: all 0.3s ease 0s;
  @media (max-width: $md2+px) {
    font-size: 17px;
  }
  @media (max-width: $md3+px) {
    font-size: 16px;
  }
  @media (max-width: $md4+px) {
    font-size: 15px;
    text-decoration: underline;
  }
  &:hover {
    color: $peper;
  }
  &::after {
    content: '';
    display: block;
    max-width: 100%;
    width: 100%;
    height: 1px;
    background-color: #0089c4;
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    bottom: 3px;
    transition: all 0.3s ease 0s;
    @media (max-width: $md4+px) {
      width: 0;
    }
  }
  &:hover:after {
    width: 0;
    background-color: #fff;
  }
}
.green {
  color: #009b74;
  &::after {
    background-color: #009b74;
  }
}
.red {
  color: #af0b26;
  &::after {
    background-color: #af0b26;
  }
}
.violet {
  color: #80379b;
  &::after {
    background-color: #80379b;
  }
}
.orange {
  color: #ff7900;
  &::after {
    background-color: #ff7900;
  }
}
.blue {
  color: #1e6aa7;
  &::after {
    background-color: #1e6aa7;
  }
}
.p-blog-text {
  font-size: 18px;
  line-height: 160%;
  font-weight: 400;
  color: #394a58;
  @media (max-width: $md2+px) {
    font-size: 17px;
  }
  @media (max-width: $md3+px) {
    font-size: 16px;
  }
  @media (max-width: $md4+px) {
    font-size: 15px;
  }
  b {
    font-weight: 700;
    color: #2c3e50;
  }
  a {
    font-family: 'Roboto';
    font-size: 18px;
    line-height: 160%;
    font-weight: 400;
    color: #0089c4;
    position: relative;
    transition: all 0.3s ease 0s;
    @media (max-width: $md2+px) {
      font-size: 17px;
    }
    @media (max-width: $md3+px) {
      font-size: 16px;
    }
    @media (max-width: $md4+px) {
      font-size: 15px;
      text-decoration: underline;
    }
    &:hover {
      color: $peper;
    }
    &::after {
      content: '';
      display: block;
      max-width: 100%;
      width: 100%;
      height: 1px;
      background-color: #0089c4;
      position: absolute;
      left: 50%;
      transform: translateX(-50%);
      bottom: 1px;
      transition: all 0.3s ease 0s;
      @media (max-width: $md4+px) {
        width: 0;
      }
    }
    &:hover:after {
      width: 0;
      background-color: #fff;
    }
  }
  span {
    &:nth-of-type(2) {
      padding-top: 30px;
    }
  }
}
.mt-10 {
  margin-top: 10px;
}
.mt-20 {
  margin-top: 20px;
}
.mt-30 {
  margin-top: 20px;
}
.blog-unordered-list {
  padding-top: 10px;
  padding-left: 25px;
  li {
    font-size: 18px;
    line-height: 170%;
    font-weight: 400;
    color: #394a58;
    position: relative;
    padding-left: 15px;
    @media (max-width: $md2+px) {
      font-size: 17px;
    }
    @media (max-width: $md3+px) {
      font-size: 16px;
    }
    @media (max-width: $md4+px) {
      font-size: 15px;
      line-height: 23px;
    }
    &::before {
      content: '';
      width: 7px;
      height: 7px;
      display: inline-block;
      background-color: $peper;
      border-radius: 50%;
      margin-right: 10px;
      margin-bottom: 2px;
      position: absolute;
      top: 8px;
      left: -3px;
      // position: absolute;
      // left: 0;
      // top: 50%;
      // transform: translateY(-50%);
    }
  }
}
.blog-ordered-list {
  padding-top: 10px;
  padding-left: 30px;
  counter-reset: my-awesome-counter;
  li {
    font-size: 18px;
    line-height: 170%;
    font-weight: 400;
    color: #394a58;
    padding-left: 10px;
    position: relative;
    counter-increment: my-awesome-counter;
    @media (max-width: $md2+px) {
      font-size: 17px;
    }
    @media (max-width: $md3+px) {
      font-size: 16px;
    }
    @media (max-width: $md4+px) {
      font-size: 15px;
      line-height: 23px;
    }
    &::marker {
      content: counter(my-awesome-counter) '';
      color: $peper;
      font-weight: 400;
      font-family: 'Arial';
      font-size: 18px;
      @media (max-width: $md2+px) {
        font-size: 17px;
      }
      @media (max-width: $md3+px) {
        font-size: 16px;
      }
      @media (max-width: $md4+px) {
        font-size: 15px;
      }
    }
  }
}
.hr-divider {
  width: 100%;
  height: 1px;
  background-color: #b1b5bb;
}

.blog-form {
  & * {
    outline: none;
  }
  &__row {
    display: flex;
    flex-wrap: wrap;
  }
  &__inputField {
    flex: 0 0 33.33%;
    margin-bottom: 30px;
    @media (max-width: 1378px) {
      flex: 0 0 100%;
      max-width: 100%;
    }
  }
  &__label {
    font-size: 15px;
    line-height: 28px;
    font-weight: 700;
    color: #394a58;
    display: block;
  }

  &__bottomField {
    justify-content: space-between;
  }
  &__sendField {
    white-space: wrap;
    text-overflow: ellipsis;
    overflow: hidden;
    max-width: 500px;
    margin-bottom: 30px;
    &:last-of-type {
      margin-right: 10px;
      @media (max-width: $md4+px) {
        margin-right: 0;
      }
    }
  }
  &__warningText {
    font-size: 15px;
    line-height: 150%;
    font-weight: 400;
    font-style: italic;
    color: #646c77;
    span {
      color: $peper;
    }
  }
  &__sendBtn {
    display: block;
    margin-left: 27px;
  }
  &__buttons {
    display: flex;
    justify-content: flex-end;
    margin-bottom: 50px;
    @media (max-width: $md3+px) {
      display: block;
    }
    .btn {
      @media (max-width: $md3+px) {
        display: block;
        margin-left: auto;
      }
    }
  }
}
.g-recaptcha {
  width: 100%;
  div {
    max-width: 100%;
    width: 100% !important;
    height: auto;
  }
  iframe {
    max-width: 100%;
    width: 100%;
    height: auto;
  }
}

.base-input {
  max-width: 295px;
  width: 100%;
  background-color: #ffffff;
  border: 1px solid #9ba7b2;
  box-shadow: inset 0px 1px 1px 0px rgba(0, 0, 0, 0.14);
  border-radius: 5px;
  padding: 7px 18px;
  min-height: 46px;
  transition: all 0.3s ease 0s;
  font-family: 'RobotoCondensed-Bold';
  text-transform: none;
  font-size: 18px;
  line-height: 18px;
  color: #3b4957;
  transition: all 0.3s ease 0s;
  // &:required {
  //   border: 1px solid red;
  // }
  &:focus {
    box-shadow: inset 0px 0px 0px 2px #0089c4;
  }
  &._error {
    box-shadow: inset 0px 0px 0px 2px #d52b1e;
  }
  @media (max-width: 1378px) {
    max-width: 100%;
  }
  // &:required {
  //   background-color: #fffbfa;
  //   border: 2px solid #d52b1e;
  // }
  &__message {
    max-width: 99%;
    resize: vertical;
    height: 179px;
  }
  &__file-text {
    font-size: 15px;
    line-height: 28px;
    font-weight: 400;
    color: #646c77;
    padding-left: 10px;
  }
}

.select {
  &__title {
    max-width: 295px;
    width: 100%;
    background-color: #ffffff;
    border: 1px solid #9ba7b2 !important;
    box-shadow: inset 0px 1px 1px 0px rgba(0, 0, 0, 0.14);
    border-radius: 5px !important;
    padding: 7px 18px;
    min-height: 46px;
    transition: all 0.3s ease 0s;
    font-family: 'RobotoCondensed-Bold';
    font-size: 18px;
    line-height: 18px;
    color: #3b4957 !important;
    transition: all 0.3s ease 0s;
    @media (max-width: 1378px) {
      max-width: 100%;
    }
  }
  &__value {
    padding: 0 !important;
    font-family: 'RobotoCondensed-Bold';
    font-size: 18px !important;
    line-height: 18px;
    color: #3b4957 !important;
    text-transform: uppercase;
    position: relative;
    @media (max-width: $md2+px) {
      font-size: 17px !important;
    }
    @media (max-width: $md3+px) {
      font-size: 16px !important;
    }
    @media (max-width: $md4+px) {
      font-size: 15px !important;
    }
    &::before {
      opacity: 0 !important;
      visibility: hidden !important;
    }
    &::after {
      content: '';
      position: absolute;
      top: 15px;
      right: 0;
      transform: translate(0, -50%);
      border-top: 5px solid #273646;
      border-left: 5px solid transparent;
      border-right: 5px solid transparent;
      transition: all 0.1s;
    }
  }
  &__options {
    min-width: 50% !important;
    max-width: 295px;
    width: 100%;
    background-color: #ffffff;
    border-top: none !important;
    border-bottom: 1px solid #9ba7b2 !important;
    border-left: 1px solid #9ba7b2 !important;
    border-right: 1px solid #9ba7b2 !important;
    box-shadow: inset 0px 1px 1px 0px rgba(0, 0, 0, 0.14);
    border-bottom-left-radius: 5px !important;
    border-bottom-right-radius: 5px !important;
    padding: 7px 18px;
    min-height: 46px;
    transition: all 0.3s ease 0s;
    font-family: 'RobotoCondensed-Bold';
    font-size: 18px !important;
    line-height: 18px;
    color: #3b4957 !important;
    transition: all 0.3s ease 0s;
    top: 46px !important;
    position: absolute;
    left: 0;
    background-color: #fff;
    border-top: 0;
    border: 1px solid #d9d9d9;
    border-top: 0;
    text-transform: uppercase;
    @media (max-width: 1378px) {
      max-width: 100%;
    }
  }
  &__option {
    padding: 10px 15px 10px 18px !important;
  }
}

.select._error .select__title {
  box-shadow: inset 0px 0px 0px 2px #d52b1e !important;
}

.select._active .select__title {
  box-shadow: inset 0px 0px 0px 2px #0089c4;
  border-bottom-left-radius: 0 !important;
  border-bottom-right-radius: 0 !important;
}
#url {
  text-transform: none;
}
input[type='file'] {
  display: none;
}
.custom-file-upload {
  width: 87px;
  height: 22px;
  border: 1px solid #ccc;
  display: inline-block;
  padding: 2px 6px 4px;
  cursor: pointer;
  font-family: Arial;
  font-size: 14px;
  line-height: 16px;
  color: #3b4957;
  font-weight: 600;
  border-radius: 4px;
  text-align: center;
}

// .management-icon {
//   background-image: linear-gradient(0deg, #af0b26 0%, #c61e39 100%);
// }
// .analitycs-icon {
//   background-image: linear-gradient(0deg, #ff7900 0%, #fb983e 100%);
// }
// .services-icon {
//   background-image: linear-gradient(0deg, #199967 0%, #21b77c 100%);
//   i {
//     padding: 11px 15px;
//   }
// }
// .reports-icon {
//   background-image: linear-gradient(0deg, #6e39c5 0%, #945cf1 100%);
// }
// .funds-icon {
//   background-image: linear-gradient(0deg, #1e6aa7 0%, #2a7cbe 100%);
// }
// .settings-icon {
//   background-image: linear-gradient(0deg, #d52b1e 0%, #f5482f 100%);
// }
.service-items {
  max-width: 100%;
  width: 100%;
  &__row {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    @media (max-width: 1378px) {
      flex-direction: column;
    }
  }
  &__item {
    display: flex;
    flex: 0 0 45%;
    max-width: 45%;
    padding-bottom: 35px;
    overflow: hidden;
    @media (max-width: 1378px) {
      flex: 0 0 100%;
      max-width: 100%;
    }
    @media (max-width: $md3+px) {
      padding-left: 0;
      padding-right: 20px;
      width: 100%;
    }
  }
  &__box {
    &:first-child {
      flex: 0 0 60px;
    }
    &:last-child {
      // flex: 0 0 auto;
      // max-width: 370px;
      // width: 100%;
      padding-left: 10px;
    }
  }
  &__icon {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 48px;
    height: 48px;
    border-radius: 11px;
    color: #fff;
    position: relative;
    &::before {
      content: '';
      display: inline-block;
      background-repeat: no-repeat;
      background-position: center center;
      background-size: contain;
      width: 48px;
      height: 48px;
    }
    // i {
    //   display: flex;
    //   justify-content: center;
    //   align-items: center;
    //   width: 42px;
    //   height: 42px;
    //   border: 2px solid #fff;
    //   border-radius: 12px;
    //   font-size: 23px;
    // }
  }
  &__title {
    font-family: 'RobotoCondensed-Bold';
    font-size: 20px;
    line-height: 105%;
    color: #2c3e50;
    text-transform: uppercase;
    @media (max-width: $md2+px) {
      font-size: 19px;
    }
    @media (max-width: $md3+px) {
      font-size: 18px;
    }
    @media (max-width: $md4+px) {
      font-size: 17px;
    }
  }
  &__text {
    padding-top: 15px;
    font-size: 16px;
    line-height: 140%;
    font-weight: 400;
    color: #4e6677;
    word-wrap: wrap;
    @media (max-width: $md4+px) {
      font-size: 14px;
    }
  }
  &__link {
    display: inline-block;
    font-size: 16px;
    line-height: 24px;
    font-weight: 700;
    color: #3067bb;
    position: relative;
    @media (max-width: $md4+px) {
      font-size: 15px;
      text-decoration: underline;
    }
    &:after {
      content: '';
      width: 100%;
      background-color: #3067bb;
      height: 1px;
      display: block;
      position: absolute;
      bottom: 0;
      left: 50%;
      transform: translateX(-50%);
      transition: all 0.3s ease 0ms;
      @media (max-width: $md4+px) {
        width: 0;
      }
    }
    &:hover {
      color: #dc240a;
      &:after {
        width: 0;
        background-color: #fff;
      }
    }
  }
}

.table-price{

    .table__body{
      text-align: center;
      
      td{
        padding: 5px !important;
      }
    }

    ul{
      text-align: left;
      margin: 10px;

      >li{
        line-height: 2;
      }
    }

    &__service-list{

      a{
        text-decoration: underline;
        color: #0089c4;
        font-family: 'Roboto';
        &:hover{
          text-decoration: none;
          color: rgba(213,43,30,.85);
        }
      }
    }

    &__sub-section{
      margin-top: 0 !important;
      margin-left: 10px !important;
      font-size: 12px;

      li{
        line-height: 2;
        list-style: disc;
        margin-left: 15px;
      }
    }

}


.table {

  max-width: 100%;
  width: 100%;
  border-collapse: collapse;
  tr th,
  tr td {
    border: 1px solid #9ba7b2;
  }
  tr th:first-of-type,
  tr td:first-of-type {
    padding: 0 3px;
  }
  tr td {
    padding: 1px 5px 1px 15px;
    &:first-of-type,
    &:nth-of-type(3),
    &:last-of-type {
      text-align: center;
    }
  }
  tbody tr:nth-of-type(even) {
    background: #f1f2f5;
  }
  &__head {
    font-family: 'Roboto';
    font-size: 15px;
    font-weight: 700;
    color: #394a58;
    border-bottom: 2px solid #9ba7b2;
    padding: 12px 0;
  }
  &__body {
    font-family: 'Roboto';
    font-size: 15px;
    font-weight: 400;
    color: #646c77;
  }
}

.search-result {
  &__title {
    font-size: 19px;
    line-height: 28px;
    font-weight: 400;
    color: #4e6677;
    padding-bottom: 30px;
    padding-top: 10px;
    border-bottom: 1px solid #9ba7b2;
    @media (max-width: $md2+px) {
      font-size: 18px;
    }
    @media (max-width: $md3+px) {
      font-size: 17px;
    }
    @media (max-width: $md4+px) {
      font-size: 16px;
    }
  }
  &__result {
    padding-top: 20px;
    padding-bottom: 60px;
  }
  &__item {
    border-bottom: 1px dotted #9ba7b2;
    padding: 5px 0 15px;
  }
  &__link {
    font-family: 'Roboto';
    font-size: 19px;
    line-height: 28px;
    font-weight: 400;
    color: #0089c4;
    transition: all 0.3s ease 0s;
    @media (max-width: $md2+px) {
      font-size: 18px;
    }
    @media (max-width: $md3+px) {
      font-size: 17px;
    }
    @media (max-width: $md4+px) {
      font-size: 16px;
    }
    &:hover {
      color: #d52b1e;
    }
  }
  &__descr {
    font-size: 12px;
    line-height: 100%;
    font-weight: 700;
    color: #394a58;
  }
  &__resultTitle {
    @media (max-width: 360px) {
      display: block;
      padding-top: 2px;
    }
  }
  &__date {
    font-weight: 400;
    line-height: 20px;
    color: #aeb3ba;
    padding-left: 10px;
    position: relative;
    @media (max-width: 360px) {
      display: block;
      padding-top: 5px;
      padding-left: 0;
      &::before {
        display: none;
      }
    }

    &::before {
      content: '|';
      position: absolute;
      left: 3px;
    }
  }
  &__pagging {
    margin-top: 50px;
  }
}

.errorPage {
  &__titleBox {
    margin: 0 auto;
    text-align: center;
  }
  &__title {
    font-family: 'YanoneKaffeesatz-Bold';
    font-size: 160px;
    color: #394a58;
    text-align: center;
    position: relative;
    text-align: center;
    display: block;
    position: relative;
    @media (max-width: $md2+px) {
      font-size: 140px;
    }
    @media (max-width: $md3+px) {
      font-size: 120px;
    }
    @media (max-width: $md4+px) {
      font-size: 100px;
    }
    &:after {
      content: attr(data-title-text);
      color: #d52b1e;
      position: absolute;
      left: 50%;
      transform: translateX(-50%);
      bottom: 0;
      clip: rect(17px, 1000px, 80px, 0);
    }
  }
  &__text {
    padding-top: 60px;
    font-family: 'RobotoCondensed-Regular';
    font-size: 24px;
    line-height: 140%;
    color: #d52b1e;
    display: block;
    text-align: center;
    @media (max-width: $md2+px) {
      font-size: 22px;
    }
    @media (max-width: $md3+px) {
      font-size: 20px;
      padding-top: 50px;
    }
    @media (max-width: $md4+px) {
      font-size: 18px;
      padding-top: 40px;
    }
  }
}
